import React, { Component } from "react";
import SectionHead from "components/sectionHead";
import MyPets from "components/MyPets";
import PHRIndex from "components/PHR";
import { APP_IS_PETS_ENABLED } from "data/config/variables";
import { getUserId } from "utils";

export default class UserMecialRecords extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: getUserId(),
            pet: null
        };
    }

    render() {
        const { userId, pet } = this.state;
        return (
            <div>
                <div className="container py-5">
                    <SectionHead title="Medical Records" />
                    {APP_IS_PETS_ENABLED ? (
                        <div>
                            <div className="">
                                <MyPets
                                    onPetSelected={pet =>
                                        this.setState({ pet })
                                    }
                                    showAdd={false}
                                />
                            </div>
                            {pet && (
                                <div className="">
                                    <PHRIndex petId={pet.id} />
                                </div>
                            )}
                        </div>
                    ) : (
                        userId && (
                            <div className="">
                                <PHRIndex userId={userId} />
                            </div>
                        )
                    )}
                </div>
            </div>
        );
    }
}
