import React, { Component } from "react";

export default class PubPrivacy extends Component {
    render() {
        return (
            <div>
                <div>
                    <div className="page-header py-5">
                        <div className="container">
                            <h3 className="mb-5">Privacy Policy</h3>
                            <div>
                                <p>
                                    ZDOC Technologies(hereafter referred to as
                                    &ldquo;ZDOC&rdquo;), develops and operates
                                    an online healthcare platform for healthcare
                                    service providers to manage their services
                                    and engage with their patients, hereafter
                                    referred to as the &lsquo;ZDOC Platform
                                    Services&rsquo;. &ldquo;We&rdquo;,
                                    &ldquo;our&rdquo; and &ldquo;us&rdquo;
                                    currently refers to ZDOC in the terms
                                    defined below.
                                </p>
                                <p>
                                    This Privacy Policy is between ZDOC and its
                                    Customers using its Virtual Practice
                                    product. It also applies to the Authorized
                                    Users in such Virtual Practices, all user
                                    types hereafter referred to as
                                    &ldquo;you&rdquo; or collectively as
                                    &ldquo;users&rdquo;.
                                </p>
                                <p>
                                    Customer healthcare service providers having
                                    a Virtual Practice on the ZDOC Platform
                                    Service, can have their Team Users
                                    (employees and/or associates) and Patients
                                    Users log in to and use their Virtual
                                    Practice through appropriate interfaces
                                    using Authorized User accounts. All data
                                    created by users in a Virtual Practice, is
                                    owned by the Customer. This privacy policy
                                    covers all data generated collectively by
                                    these Customers and Authorized Users.
                                </p>
                                <p>
                                    Your privacy is very important to us. This
                                    Privacy Policy describes how we handle and
                                    secure the personal information and data
                                    that you provide to us through our website
                                    and ZDOC Platform Services. By using our
                                    website, platform apps and services, you
                                    agree to be bound by the terms of this
                                    policy. This policy is part of our&nbsp;
                                    <a
                                        href="https://www.ZDOC.in/terms"
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Terms of Use.
                                    </a>
                                </p>
                                <h4>Collected and Received Data</h4>
                                <p>
                                    ZDOC receives and collects the following
                                    type of data or information.
                                </p>
                                <h5>Customer Data</h5>
                                <p>
                                    Data in a Virtual Practice can be added or
                                    created by Authorized Users in the Virtual
                                    Practice, whether they be Team users
                                    (healthcare service providers,
                                    administrative users) or Patient users. This
                                    data belongs to the Customer.
                                </p>
                                <p>
                                    Personal information includes name, email
                                    address and/or phone number used to create
                                    Authorized User accounts. It also includes
                                    other personally identifiable information
                                    that users may choose to add to their user
                                    account profile.
                                </p>
                                <p>
                                    Personal health information that patient
                                    users and/or healthcare providers who are
                                    Authorized Users of the Virtual Practice
                                    enter into the Personal Health Record of a
                                    patient. This includes data that is entered
                                    manually and/or by syncing with a health
                                    monitoring device or from a third party
                                    source that users alone authorize. It also
                                    includes files that users choose to upload.
                                </p>
                                <p>
                                    Administrative information about the
                                    services provided to Patient Users in the
                                    Virtual Practice
                                </p>
                                <h5>Other Data</h5>
                                <p>
                                    Virtual Practice Account Info: Information
                                    that the Customer provides to ZDOC at the
                                    time of setting up their Virtual Practice
                                    account. This also includes information
                                    required for subscription and billing of the
                                    account. ZDOC does not store the
                                    Customer&rsquo;s credit card or other
                                    payment method information.
                                </p>
                                <p>
                                    Operations Info: In order to enhance your
                                    platform usage experience, ZDOC uses
                                    cookies, logs and analytics to enable
                                    certain functionality, improve services and
                                    monitor service usage. Information about
                                    browser, device, location, IP address,
                                    language settings, date and time of use may
                                    be captured in logs. These mechanisms are
                                    used to help diagnose issues and preferences
                                    which help us to evolve the services. Our
                                    authorized third-party partners also may
                                    receive and log such non-personally
                                    identifiable information related to your
                                    online service usage as part of supporting
                                    platform services.
                                </p>
                                <p>
                                    Third Party Services: The Customer may
                                    choose to configure and use certain Third
                                    Party Services within their Virtual
                                    Practice. This is optional and entirely upto
                                    the Customer. For instance the Customer may
                                    configure a third party payment gateway to
                                    accept payments through that gateway for
                                    services rendered to Patient users through
                                    the Virtual Practice. In this case the
                                    Customer is authorizing the ZDOC Platform
                                    services to receive certain configuration
                                    and operational information relevant to the
                                    integration with the third party service.
                                    Customers should check the terms of use,
                                    privacy policy and usage settings in these
                                    Third Party Services. ZDOC does not receive
                                    or store any passwords for such third-party
                                    services accounts.
                                </p>
                                <p>
                                    Additional Information: You may also provide
                                    other information to ZDOC through support
                                    channels.
                                </p>
                                <p>
                                    Note: With regard to the definition of data
                                    controller and data processor, as defined by
                                    Data Protection laws in the EU, the Customer
                                    is the data controller of Customer Data and
                                    ZDOC is the data processor. ZDOC is the data
                                    controller of Other Data, as defined above.
                                </p>
                                <h4>Use of Information</h4>
                                <h5>Customer Data</h5>
                                <p>
                                    The Customer is the owner and controller of
                                    the Customer Data, which is processed by
                                    ZDOC as per the configuration settings and
                                    instructions of the Customer. The Customer
                                    alone decides which Authorized Users to
                                    create and terminate; and what access such
                                    Authorized Users have to add, update,
                                    delete, export or share the data. The
                                    Customer defines the policies, practices and
                                    usage terms for Authorized Users of the
                                    Virtual Practice and it is the sole
                                    responsibility of the Customer to enforce
                                    that. A Customer can terminate Authorized
                                    Patient User accounts at any time and such
                                    Patients will no longer have further access
                                    to their personal health information in
                                    event of that happening. Whether or not to
                                    share that information with the Patient User
                                    before termination of the Patient User
                                    account is the prerogative of the Customer
                                    only.
                                </p>
                                <h5>Other Data</h5>
                                <p>
                                    Other information and data provided to ZDOC
                                    is used as follows:
                                </p>
                                <p>
                                    Payment Information: A Customer making a
                                    subscription payment for the Virtual
                                    Practice will be providing our secure third
                                    party payment providers with access to
                                    information on your payment method and
                                    billing address details at the time of your
                                    making online payments. ZDOC does not store
                                    your credit card details nor that of any
                                    other online payment methods you may choose
                                    to use.
                                </p>
                                <p>
                                    Contact Information: The services platform
                                    will send you automated system
                                    communications (email and other
                                    notifications), as per your notification
                                    settings, as part of essential functioning
                                    of services that you may avail. Your contact
                                    information may also be used for customer
                                    support services, should you choose to avail
                                    of them. In addition, we may use your
                                    contact information to provide you with
                                    information about, our products and
                                    services. If you decide at any time that you
                                    no longer wish to receive such information
                                    or communications from us, please follow the
                                    unsubscribe instructions provided in any of
                                    the communications. Your identifiable
                                    contact information is not shared with any
                                    third-party service.
                                </p>
                                <p>
                                    Anonymized data: ZDOC may analyze anonymized
                                    and aggregated collected through the
                                    services, for purposes of issue diagnostics,
                                    security assessment and monitoring,
                                    evaluating usage trends and requirement
                                    patterns and to measure the effectiveness of
                                    services and content. Such anonymized and
                                    aggregated information is not considered as
                                    personal information.
                                </p>
                                <h4>Disclosure of Information</h4>
                                <p>
                                    We may disclose any information to
                                    government or law enforcement officials if
                                    we believe doing so is required to comply
                                    with law enforcement and legal process; to
                                    prevent or stop any illegal, unethical, or
                                    legally actionable activity; to protect
                                    your, our or others rights and safety.
                                </p>
                                <p>
                                    Customers determine their own policies and
                                    practices for the sharing and disclosure of
                                    their own Customer Data and this is expected
                                    to be compliance with legal requirements.
                                    ZDOC will disclose Customer Data only as per
                                    the request and instructions of the
                                    Customer, whether as defined in terms of the
                                    service configuration that they apply in use
                                    of the Virtual Practice or by direct
                                    requests. Disclosure of Customer Data to
                                    Authorized Users of the Customer&rsquo;s
                                    Virtual Practice is determined by the
                                    Customer, while creating and configuring
                                    such Authorized User accounts. The Customer
                                    may also be able to request and view usage
                                    information of Authorized Users in the
                                    Customer&rsquo;s Virtual Practice. All ZDOC
                                    employees are bound by strict privacy
                                    policies and do not have access to Customer
                                    Data.
                                </p>
                                <p>
                                    Sub-Processors: ZDOC may utilize third party
                                    services to sub-process Other Information
                                    and support our business by providing
                                    hosting, storage, communications, webRTC and
                                    analytics services.
                                </p>
                                <p>
                                    Third Party Services: Customers opting to
                                    configure certain third party services with
                                    their Virtual Practice, should check the
                                    privacy policy and settings of such services
                                    and should understand what data may be
                                    shared between such authorized services and
                                    ZDOC, relevant to the integrated functioning
                                    expected.
                                </p>
                                <p>
                                    If ZDOC becomes involved in a merger,
                                    acquisition, or any form of sale of some or
                                    all of its assets, Other Information will be
                                    transferred to the new entity for the
                                    continued performance of service, subject to
                                    standard confidentiality agreements.
                                </p>
                                <h4>Data Retention</h4>
                                <p>
                                    ZDOC will retain Customer Data for as long
                                    as the Customer has an active Virtual
                                    Practice account on the ZDOC Platform
                                    Services. While operating an active Virtual
                                    Practice account, the Customer controls how
                                    this data is retained or deleted, using
                                    features and settings in the Virtual
                                    Practice. Deletion of Authorized User
                                    accounts in a Customer&rsquo;s Virtual
                                    Practice are solely the responsibility of
                                    the Customer, ZDOC does however reserve the
                                    right to deactivate or delete accounts of
                                    any user on the platform found to be in
                                    violation of our Terms of Use. On receiving
                                    a request to delete a Virtual Practice
                                    account, ZDOC will delete the Virtual
                                    Practice account and all associated data.
                                    The deletion of Customer Data may result in
                                    the deletion and/or anonymization of
                                    associated Other Data. ZDOC may retain such
                                    anonymized Other Data even after the
                                    Customer has deactivated their account if it
                                    is required to comply with legal
                                    obligations, for audits and for legitimate
                                    business needs.
                                </p>
                                <h4 id="cookiePolicy">Cookie Policy</h4>
                                <p>
                                    ZDOC uses cookies (both session-based and
                                    persistent cookies) and similar technologies
                                    like single-pixel gifs, on the domains
                                    operated by ZDOC for its ZDOC Platform
                                    Services and website. We also use third
                                    party cookies of Google Analytics on our
                                    website.
                                </p>
                                <p>
                                    We also use cookies in our website and
                                    Platform Services to save user preferences,
                                    preserve session settings, help
                                    auto-authentication of frequently used
                                    services if required by your settings, for
                                    certain marketing campaigns and to enable
                                    improved understanding of your feature and
                                    usage requirements and patterns. You may use
                                    browser settings to disable cookies when
                                    using our web services, however it may
                                    result in the features and services of the
                                    applications not functioning properly.
                                </p>
                                <p>
                                    ZDOC does not collect personal information
                                    about online activities of our Customers or
                                    visitors to our website across third-party
                                    websites or other online services.
                                    Do-no-track signals transmitted from web
                                    browsers, therefore do not apply to our
                                    Platform Services or website. It is
                                    important to note that most browsers allow
                                    you to disallow cookies from sites that you
                                    do not trust.
                                </p>
                                <h4>Transmission and Storage Security</h4>
                                <p>
                                    All data is stored only in our cloud
                                    infrastructure on the highly secure Amazon
                                    Web Service platform in the United States of
                                    America. We take all necessary measures in
                                    providing secure transmission of personal
                                    health information before it is transferred
                                    across the Internet from your personal
                                    computer or devices to our servers and in
                                    securing such personal information stored on
                                    our systems. However, you should be aware of
                                    possible risk involved in transmitting
                                    information over the Internet as no data
                                    transmission can be guaranteed to be 100%
                                    secure and of risk that others could find a
                                    way to thwart our security systems. As a
                                    result, while we strive to protect your
                                    personal information, we cannot ensure or
                                    warrant the security and privacy of personal
                                    information you transmit to us, and you do
                                    so at your own risk.
                                </p>
                                <h4>Restriction on the basis of age</h4>
                                <p>
                                    All users of the ZDOC Platform Services are
                                    expected to be above 18 years of age.
                                    Customers are to ensure that only parents or
                                    authorized guardians of minors are allowed
                                    to operate Authorized Patient User accounts
                                    on their behalf. Any Authorized User
                                    providing, storing or submitting information
                                    on behalf of a child/person under
                                    guardianship assumes full responsibility
                                    over the submission, use, and transmission
                                    of such information.
                                </p>
                                <p>
                                    If you believe that any Authorized User in a
                                    Virtual Practice is younger than the
                                    permitted age, immediately contact the
                                    Customer operating that Virtual Practice. If
                                    notified directly of such a violation of our
                                    Terms of Use, we will promptly delete the
                                    concerned user account and data. .
                                </p>
                                <h4>Your Rights</h4>
                                <p>
                                    Users located in the EU, have certain
                                    statutory rights with regard to their
                                    personal data as defined by the General Data
                                    Protection Regulation. You have the right to
                                    seek to request access to your personal
                                    data, delete or update it. The Platform
                                    Service features and settings support this
                                    to some extent. If you cannot use the
                                    service features to do this, please contact
                                    our support desk for help. In the case of
                                    Authorized Users of a Customer Virtual
                                    Practice, it is important to directly
                                    contact the Customer for such support
                                    including objection to processing of your
                                    data, as the Customer is the data controller
                                    of Customer Data. Based on its legitimate
                                    interests and compliance requirements, ZDOC
                                    may process Other Data that includes user
                                    data for legitimate purposes.
                                </p>
                                <h4>Links to Other Websites</h4>
                                <p>
                                    Our website may contain links to other
                                    websites. The fact that we link to a website
                                    is not an endorsement, authorization or
                                    representation of our affiliation with that
                                    third party. We do not exercise control over
                                    third party websites. These other websites
                                    may place their own cookies or other files
                                    on your computer, collect data or solicit
                                    personally identifiable information from
                                    you. Other sites follow different rules
                                    regarding the use or disclosure of the
                                    personally identifiable information you
                                    submit to them. We encourage you to read the
                                    privacy policies or statements of the other
                                    websites you visit.
                                </p>
                                <h4>Changes to this Policy</h4>
                                <p>
                                    Please note that this Privacy Policy may
                                    change from time to time as we continue to
                                    offer evolve our services. In the event of
                                    any changes in our Privacy Policy, the
                                    updated version will be posted on the ZDOC
                                    website and take effect from the moment of
                                    posting.
                                </p>
                                <h4>Contact Us</h4>
                                <p>
                                    If you have any questions or concerns about
                                    this Privacy Policy, please email us
                                    at&nbsp;
                                    <a href="mailto:hello@zdoc.in">
                                        hello@zdoc.in
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                        {/*//container*/}
                    </div>
                </div>
            </div>
        );
    }
}
