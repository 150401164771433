import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

import { getVendorSiteContent } from "page/network";
import { getSubDomain, isSMDevice } from "utils";
import isEmpty from "utils/isEmpty";

export default class SubGallery extends Component {
	constructor(props) {
		super(props);
		const subDomain = getSubDomain();
		this.state = {
			subDomain,
			pics: [],
			header: "Header Section",
			body: "Body",
		};
		if (subDomain) {
			getVendorSiteContent(subDomain.id)
				.then((r) => {
					const [sd] = r.data.websites;
					if (sd) {
						const {
							header_section: header,
							body_section: body,
							pics,
						} = sd;

						this.setState({ header, body, pics });
					}
				})
				.catch((err) => console.log(err));
		}
	}

	render() {
		const { subDomain, pics, body } = this.state;
		const images = pics.map((p) => {
			return {
				original: p,
				thumbnail: p,
			};
		});
		return (
			<div>
				<section className="py-4">
					<div className="container py-lg-4">
						<h2 className="page-title pt-2 mb-5 text-center font-weight-bold">
							Gallery
						</h2>
						{!isEmpty(pics) ? (
							<div className="pb-4 ">
								<ImageGallery items={images} autoPlay={true} />
							</div>
						) : (
							<div className="text-center">
								<h4 class="w-100 text-muted ">
									Images are not available
								</h4>
							</div>
						)}
					</div>
					{/*//container*/}
				</section>
			</div>
		);
	}
}
