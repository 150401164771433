import React, { Component } from "react";
import PropTypes from "prop-types";
import Signup from "./signup";
import LoginCmp from "./login";
import ForgotPW from "./forgotpw";
import ChangePW from "./changepw";

import { loginUser } from "utils/LoginChecker";
import { login } from "./network";
import { A4P_ROLE_USER, A4P_ROLE_VENDOR } from "data/config/variables";
import { gaEvent } from "utils/GAUtil";

export const AuthModes = {
    login: 1,
    signup: 2,
    forgotPW: 3,
    changePW: 4,
};

class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMode: this.props.mode || AuthModes.login,
            role: this.props.role,
        };

        this.onLoginClick = this.onLoginClick.bind(this);
        this.onSignupClick = this.onSignupClick.bind(this);
        this.onForgotPWClick = this.onForgotPWClick.bind(this);

        this.onLoginComplete = this.onLoginComplete.bind(this);
        this.onSignupComplete = this.onSignupComplete.bind(this);
    }

    onLoginClick() {
        this.setState({ currentMode: AuthModes.login });
    }

    onSignupClick() {
        this.setState({ currentMode: AuthModes.signup });
    }

    onForgotPWClick() {
        this.setState({ currentMode: AuthModes.forgotPW });
    }

    onSignupComplete(data) {
        if(this.state.role === A4P_ROLE_VENDOR) {
            gaEvent({
                category: "Vendor-Onboarding",
                action: "Signup-Complete"
            });
        }

        login(data)
            .then((r) => {
                if (r) this.onLoginComplete(r);
            })
            .catch((err) => console.log(err));
    }

    onLoginComplete(data) {
        const { autoLogin, onComplete, redirectUrl } = this.props;
        if (autoLogin && data) loginUser(data.token, redirectUrl);
        if (onComplete) onComplete(data);
    }

    render() {
        const renderComponent = (mode) => {
            switch (mode) {
                case AuthModes.login:
                    return (
                        <LoginCmp
                            onLoginComplete={this.onLoginComplete}
                            onSignupClick={this.onSignupClick}
                            onForgotPWClick={this.onForgotPWClick}
                            {...this.props}
                        />
                    );
                case AuthModes.signup:
                    return (
                        <Signup
                            onLoginClick={this.onLoginClick}
                            onSignupComplete={this.onSignupComplete}
                            {...this.props}
                        />
                    );
                case AuthModes.forgotPW:
                    return (
                        <ForgotPW
                            onLoginClick={this.onLoginClick}
                            {...this.props}
                        />
                    );
                case AuthModes.changePW:
                    return (
                        <ChangePW
                            onLoginClick={this.onLoginClick}
                            {...this.props}
                        />
                    );
            }
        };
        return renderComponent(this.state.currentMode);
    }
}

Auth.defaultProps = {
    role: A4P_ROLE_USER,
    redirectUrl: "/app",
    mode: AuthModes.login,
    isEmailRequired: false,
    showCaptha: true,
    showCoupon: true,
    showLoginLink: true,
    showSignUpLink: true,
    showSocialLinks: false,
    autoLogin: true,
    vendorSideSignup: false,
    showLoginLinkInChangePW: true,
};

Auth.propTypes = {
    showCaptha: PropTypes.bool,
    showCoupon: PropTypes.bool,
};

export default Auth;
