import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import isEmpty from "utils/isEmpty";
import Button from "components/Form/Button";
import { searchVendors } from "../network";

export default class MCSearchVendors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 3,
            endOfList: false,
            loading: false,
            searchStr: "",
            vendors: [],
        };

        this.fetchVendors = this.fetchVendors.bind(this);
    }

    // Retreive appointments
    fetchVendors() {
        const { vendors, searchStr, limit, offset } = this.state;
        this.setState({ loading: true });
        searchVendors({ searchStr, offset, limit })
            .then((r) => {
                const li = r.data.vendor_listings;
                if (!isEmpty(li))
                    this.setState({
                        loading: false,
                        vendors: vendors.concat(li),
                        offset: offset + limit,
                        endOfList: li.length < limit,
                    });
                else this.setState({ loading: false, endOfList: true });
            })
            .catch((err) => console.log(err));
    }

    render() {
        const { loading, endOfList, vendors } = this.state;
        
        return (
            <div>
                <div>
                    <button
                        className="btn btn-outline-secondary mb-2 px-4 btn-sm"
                        onClick={(e) => this.props.onGoBack()}
                    >
                        <i className="fa fa-long-arrow-left"></i>
                    </button>

                    <input
                        type="text"
                        name="mobile"
                        className="form-control border-none border-bottom mb-3"
                        placeholder="Search by Clinin/Doctor name"
                        onChange={(e) => {
                            const v = e.target.value.trim();
                            if (v.length > 1)
                                this.setState(
                                    {
                                        searchStr: v,
                                        vendors: [],
                                        offset: 0,
                                        endOfList: false,
                                    },
                                    this.fetchVendors
                                );
                        }}
                    />
                </div>

                <div>
                    <div className="ml-0 row">
                        <div className="">
                            {!loading && isEmpty(vendors) ? (
                                <p className="my-2 text-muted">
                                    No Records Found
                                </p>
                            ) : (
                                <div>
                                    <div className="col row pr-0">
                                        {vendors.map((v, k) => (
                                            <div
                                                key={k}
                                                className="col-12 shadow-sm mb-3 py-2"
                                                onClick={(e) =>
                                                    confirmAlert({
                                                        message: `Are you sure you want to add this clinic to your saved list?`,
                                                        buttons: [
                                                            {
                                                                label: "Yes",
                                                                onClick: () => {
                                                                    this.props.onVendorSelect(
                                                                        v
                                                                    );
                                                                },
                                                            },
                                                            {
                                                                label: "No",
                                                            },
                                                        ],
                                                    })
                                                }
                                            >
                                                <h6>
                                                    {v.vendor.websites[0].name}
                                                </h6>
                                                <div className="text-muted mb-2">
                                                    {v.title}
                                                </div>
                                                <div>{v.line1}</div>
                                            </div>
                                        ))}
                                    </div>
                                    {!endOfList && (
                                        <div className="text-center mt-4">
                                            <Button
                                                btnCls="btn-outline-secondary"
                                                loading={loading}
                                                onClick={this.fetchVendors}
                                                value="load more"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
