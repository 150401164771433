import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth, { AuthModes } from "components/Auth";
import { A4P_ROLE_VENDOR } from "data/config/variables";
import { SiteLogo } from "components/logo";

export default class PubClinicSignUp extends Component {
    render() {
        return (
            <div className="theme-bg-primary">
                <div className="signup-section auth-section">
                    <div className="row m-0">
                        <div className="auth-col-main bg-white col-12 col-lg-8 order-2 text-center p-5 h-100 align-self-stretch">
                            <h1 className="site-logo mb-3">
                                <SiteLogo />
                            </h1>
                            <div className="auth-wrapper mx-auto">
                                <h2 className="auth-heading mb-2">
                                    Create your free Clinic Account
                                </h2>
                                <div className="auth-intro mb-4">
                                    No credit card required for signing up.
                                </div>
                                <div className="legal-note mb-5 text-light text-center">
                                    If you are a patient,
                                    <Link
                                        to="/signup"
                                        className="ml-1 text-light"
                                    >
                                        please click here.
                                    </Link>
                                </div>
                                <div className="auth-form-container text-left mx-auto">
                                    <Auth
                                        isEmailRequired={true}
                                        mode={AuthModes.signup}
                                        redirectUrl="/clinic/onboarding"
                                        role={A4P_ROLE_VENDOR}
                                        showLoginLink={false}
                                    />
                                </div>
                                {/*//auth-form-container*/}
                                <div className="option-container my-4 pt-3">
                                    <div className="lead-text">
                                        Already have an account?{" "}
                                        <Link to="/clinic/login">Log in</Link>
                                    </div>
                                </div>
                                {/*//option-container*/}
                            </div>
                            {/*//auth-wrapper*/}
                        </div>
                        {/*//col-auth*/}
                        <div className="auth-col-promo col-lg-4 d-none d-lg-block order-1 theme-bg-primary h-100 align-self-stretch">
                            <div className="auth-col-promo-inner h-100 position-relative p-0">
                                <div className="auth-quote-box mt-5 mb-3 px-3 py-4 rounded text-center shadow-lg bg-white mx-auto">
                                    <h5 className="auth-quote-title">
                                        An absolute time-saver!
                                    </h5>
                                    <div>
                                        Sometimes I don’t have to travel to my
                                        clinic as my schedule is completely
                                        booked with just tele consultations. I
                                        am glad I opted for this as this has
                                        made telemedicine an absolute delight.
                                    </div>
                                </div>
                                <div className="auth-doc-figure-holder mt-5"></div>
                            </div>
                        </div>
                        {/*//col-promo*/}
                    </div>
                    {/*//row*/}
                </div>
                {/*//signup-section*/}
            </div>
        );
    }
}
