import React, { Component } from "react";
import moment from "moment-timezone";
// Import React Table
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./rt.css";
import { gqlSend } from "utils/AxiosUtil";
import { getVendorId } from "utils";

const requestData = (pageSize, page, sorted, filtered) => {
  const vendorId = getVendorId();
  const fLi = filtered.length? filtered.map(e => `${e.id}: {_ilike: "%${e.value}%"}`): [];
  const where = `{saved_vendors: {_contains: [${vendorId}]}, ${fLi.join(", ")}}`;

  const q = {
    query: `{
        users(
            limit: ${pageSize}, 
            offset: ${page * pageSize},
            order_by: {id: desc},
            where: ${where}
        ) {
            id, created_at, first_name, last_name, phone, email
        }
        users_aggregate (where: ${where}) {
          aggregate {
            totalCount: count
          }
        }
    }`
  };
  return gqlSend(q);
};

class UsersList extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      pages: null,
      loading: true
    };
    this.fetchData = this.fetchData.bind(this);
  }

  myFilter = ({ filter, onChange }) => {
    return (
      <input
        onKeyPress={event => {
          if (event.keyCode === 13 || event.which === 13) {
            onChange(event.target.value);
          }
        }}
      />
    );
  };

  fetchData(state, instance) {
    this.setState({ loading: true });

    requestData(state.pageSize, state.page, state.sorted, state.filtered)
      .then(r => {
        this.setState({
          data: r.data.users,
          pages: Math.ceil(r.data.users_aggregate.aggregate.count/ state.pageSize),
          loading: false
        });
      })
      .catch(r => console.log(r));
  }

  render() {
    const { data, pages, loading } = this.state;
    return (
      <div>
        <div className=""> 
          <ReactTable
            columns={[
              {
                Header: "ID",
                accessor: "id",
                width: 70,
                filterable: false
              },
              {
                Header: "First Name",
                accessor: "first_name",
                Filter: this.myFilter
              },
              {
                Header: "Last Name",
                accessor: "last_name",
                Filter: this.myFilter
              },
              {
                Header: "Phone",
                accessor: "phone",
                Filter: this.myFilter
              },
              {
                Header: "Email",
                accessor: "email",
                Filter: this.myFilter
              },
              {
                Header: "Created",
                accessor: "created_at",
                filterable: false,
                Cell: row => (<span>{moment(row.original.created_at).format("DD/MMM/YYYY")}</span>)
              }
            ]}
            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
            data={data}
            pages={pages} // Display the total number of pages
            loading={loading} // Display the loading overlay when we need it
            onFetchData={this.fetchData} // Request new data when things change
            filterable 
            sortable ={false}
            showPageJump={false}
            defaultPageSize={10}
            getTrProps={(state, rowInfo) => {
              return {
                onClick: (e) => {
                  if(this.props.onSelectRow) this.props.onSelectRow(rowInfo.original)
                },
                style: {
                  cursor: 'pointer'
                },
              };
            }}
            className="-highlight"
          />
        </div>
      </div>
    );
  }
}

export default UsersList;
