import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";
import BookAppointment from "components/BookAppointment";
import { getSubDomain } from "utils";

export default function SubDomainFooter() {
	const subDomain = getSubDomain();
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	return (
		<div>
			<div>
				<section className="container">
					<div
						className="row pt-5 pb-4"
						style={{ fontSize: "1.1em" }}
					>
						<div className="col-md-3 col-sm-12">
							<div>
								<div className="mb-5">
									<h5 className="mb-4">Location</h5>
									{/* /.cta-four__title */}

									<p
										className=""
										style={{
											lineHeight: "2em",
											maxWidth: 230,
										}}
									>
										35, DVG Road, Gandhi Bazaar,
										Basavanagudi, Bengaluru, Karnataka
										560004
									</p>
									<div>
										<a
											href="https://www.google.com/maps/dir//Happy+Clinics+-+Multispeciality/@12.9376866,77.5680141,15.54z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae15569b63cd47:0x4e7e8f17d981f1f2!2m2!1d77.5712632!2d12.942685"
											target="_blank"
										>
											<span className="font-weight-bold">
												<i className="mr-2 fad fa-directions"></i>
												Get Directions
											</span>
										</a>
									</div>

									{/* /.cta-four__text */}
								</div>
								{/* /.cta-four__content */}
							</div>
							{/* /.cta-four__single */}
						</div>
						{/* /.col-lg-3 */}
						<div className="col-md-6 col-sm-12">
							<div className="cta-four__single">
								{/* /.cta-four__icon */}
								<div className="px-md-2 mr-md-3">
									<h5 className="mb-4">Timings</h5>
									<div className="d-flex justify-content-between">
										<label className>Monday:</label>
										<span className>
											10:30 - 12:30 PM | 5:00 - 7:00 PM
										</span>
									</div>
									<div className="d-flex justify-content-between">
										<label className>Tuesday:</label>
										<span className>
											10:30 - 12:30 PM | 5:00 - 7:00 PM
										</span>
									</div>
									<div className="d-flex justify-content-between">
										<label className>Wednesday:</label>
										<span className>
											10:30 - 12:30 PM | 5:00 - 7:00 PM
										</span>
									</div>
									<div className="d-flex justify-content-between">
										<label className>Thursday:</label>
										<span className>
											10:30 - 12:30 PM | 5:00 - 7:00 PM
										</span>
									</div>
									<div className="d-flex justify-content-between">
										<label className>Friday:</label>
										<span className>
											10:30 - 12:30 PM | 5:00 - 7:00 PM
										</span>
									</div>
									<div className="d-flex justify-content-between">
										<label className>Saturday:</label>
										<span className>
											10:30 - 12:30 PM | 5:00 - 7:00 PM
										</span>
									</div>
									<div className="d-flex justify-content-between">
										<label className>Sunday:</label>
										<span className>Closed</span>
									</div>
									<p />
									{/* /.cta-four__text */}
								</div>
								{/* /.cta-four__content */}
							</div>
							{/* /.cta-four__single */}
						</div>
						{/* /.col-lg-3 */}
						<div className="col-md-3 col-sm-12">
							<div>
								<div>
									<h5 className="mb-4">Get in touch</h5>
									<p className="mb-4">
										<a href="#">
											<button
												type="submit"
												className="btn btn-primary theme-btn font-weight-bold"
												onClick={toggle}
											>
												Book Appointment
											</button>
										</a>
									</p>
									<p className="mb-4">
										<a href="tel:+919980866101">
											<button
												type="submit"
												className="btn btn-primary theme-btn theme-btn-ghost font-weight-bold"
											>
												Call Now: 998.086.6101
											</button>
										</a>
									</p>
								</div>
								{/* /.cta-four__content */}
							</div>
							{/* /.cta-four__single */}
						</div>
						{/* /.col-lg-3 */}
					</div>
					{/* /.row */}
					{/* /.container */}
				</section>
				<div>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.82161983553!2d77.5712632!3d12.942685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4e7e8f17d981f1f2!2sHappy%20Clinics%20-%20Multispeciality!5e0!3m2!1sen!2sin!4v1603373206356!5m2!1sen!2sin"
						allowFullScreen
						style={{
							width: "100%",
							border: "none",
							height: "448px",
							display: "block",
						}}
					/>
				</div>
				<footer style={{ backgroundColor: "#161F2D" }}>
					<div className="container ">
						<div
							className="footer-col py-5"
							style={{ fontSize: "1.15em", lineHeight: "1.6em" }}
						>
							<div className="w-100 text-center">
								<div>
									<ul className="footer-widget__links-list mb-3">
										<li className="footer-widget__links-list-item iniLi">
											{" "}
											<Link to="/privacy">
												Privacy
											</Link>{" "}
										</li>
										{/* /.footer-widget__links-list-item */}
										<li className="footer-widget__links-list-item iniLi">
											{" "}
											<Link to="/terms">
												Terms and Conditions
											</Link>{" "}
										</li>
										{/* /.footer-widget__links-list-item */}
										<li className="footer-widget__links-list-item iniLi">
											{" "}
											<Link to="/contact">
												Contact Us
											</Link>{" "}
										</li>
										{/* /.footer-widget__links-list-item */}
									</ul>
								</div>
								<div>
									<div classname="fn org">
										{" "}
										Happy Clinics, Basavanagudi, Bengaluru
									</div>
									Phone (appointments):{" "}
									<span>998.086.6101</span>
									<div classname="adr">
										Address: 35, DVG Road, Gandhi Bazaar,
										Basavanagudi, Bengaluru, Karnataka
										560004
									</div>
								</div>
								<div className="mt-4 d-flex justify-content-center align-items-center">
									<h2 className="text-white pr-3">
										4.97
										<sup>
											<span>/</span>5
										</sup>
									</h2>
									<div className="border-left pl-3  ">
										<div>
											<div className="text-warning">
												<i className="fad fa-star"></i>
												<i className="fad fa-star"></i>
												<i className="fad fa-star"></i>
												<i className="fad fa-star"></i>
												<i className="fad fa-star"></i>
											</div>
											<h6 className="text-light">
												(38 reviews)
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* /.footer-col */}
					</div>
					{/* /.container */}
					<div className="bottom-footer">
						<div className="container">
							<hr className="bottom-footer__line" />
							<div className="bottom-footer__wrap">
								<p className="bottom-footer__text">
									© Zdoc 2020. All right reserved.
								</p>
								{/* /.bottom-footer__text */}
								<p className="bottom-footer__text">
									<a target="_blank" href="https://zdoc.in">
										powered by Zdoc
									</a>
								</p>
								{/* /.bottom-footer__text */}
							</div>
							{/* /.bottom-footer__wrap */}
						</div>
						{/* /.container */}
					</div>
					{/* /.bottom-footer */}

					<Modal isOpen={modal} toggle={toggle}>
						<ModalHeader toggle={toggle}>
							Book An Appointment
						</ModalHeader>
						<ModalBody>
							<BookAppointment vendor_id={subDomain.vendor_id} />
						</ModalBody>
						<ModalFooter>
							<Button color="secondary" onClick={toggle}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
				</footer>
			</div>
		</div>
	);
}
