import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SubHome from "./sub/home";
import PubHome from "./pub/home";
import PubAbout from "./pub/about";
import PubFeatures from "./pub/features";
import PubContact from "./pub/contact";
import PubPricing from "./pub/pricing";
import PubLogin from "./pub/login";
import PubSignUp from "./pub/signup";
import PubClinicLogin from "./pub/clinicLogin";
import PubClinicSignUp from "./pub/clinicSignup";
import VendorAppointments from "page/vendor/my-appointments";
import { A4P_ROLE_VENDOR, A4P_ROLE_USER } from "data/config/variables";
import { getUser, getSubDomain } from "utils";
import VendorListings from "page/vendor/listing";
import VendorCustomers from "page/vendor/customers";
import SettingsIndex from "page/vendor/settings";
import { logoutUser } from "utils/LoginChecker";
import UserAppointments from "page/user/my-appointments";
import UserMecialRecords from "page/user/medical-records";
import UserMyProfile from "page/user/my-profile";
import NotFound from "page/pub/404";
import VendorOnboarding from "./vendor/clinicOnboarding";
import PubPrivacy from "./pub/privacy";
import PubTerms from "./pub/terms";
import SubFAQ from "./sub/faqs";
import PubFAQ from "./pub/faqs";
import SubContact from "./sub/contact";
import SubAbout from "./sub/about";
import MissedCallBooking from "./booking";
import VendorHelp from "./vendor/help";
import CampaignsIndex from "./campaigns";
import LandingHome from "./campaigns/landing";
import SubHomeSnippet from "./sub/snippet";
import PrescriptionTemplatesIndex from "./vendor/customers/list/details/prescriptions/templates";
import PrescriptionsIndex from "./vendor/customers/list/details/prescriptions";
import PrescriptionsPub from "./prescriptions";
import VendorReports from "./vendor/reports";
import SubGallery from "./sub/gallery";

export default class PubRoute extends Component {
    constructor(params) {
        super(params);

        this.subRoutes = [
            { p: "/", c: SubHome },            
            { p: "/snip", c: SubHomeSnippet },
            { p: "/faq", c: SubFAQ },
            { p: "/privacy", c: PubPrivacy },
            { p: "/terms", c: PubTerms },
            { p: "/about", c: SubAbout },
            { p: "/gallery", c: SubGallery },
            { p: "/contact", c: SubContact },
            { p: "/signup", c: PubSignUp },
            { p: "/login", c: PubLogin },
            { p: "/clinic/login", c: PubClinicLogin },
        ];

        this.pubRoutes = [
            { p: "/", c: PubHome },
            { p: "/Z*", c: MissedCallBooking },
            { p: "/1*", c: CampaignsIndex },
            { p: "/P*", c: PrescriptionsPub },
            { p: "/landing", c: LandingHome },
            { p: "/about", c: PubAbout },
            { p: "/features", c: PubFeatures },
            { p: "/pricing", c: PubPricing },
            { p: "/contact", c: PubContact },
            { p: "/privacy", c: PubPrivacy },
            { p: "/faq", c: PubFAQ },
            { p: "/terms", c: PubTerms },
            { p: "/signup", c: PubSignUp },
            { p: "/login", c: PubLogin },
            { p: "/clinic/signup", c: PubClinicSignUp },
            { p: "/clinic/login", c: PubClinicLogin },
        ];
    }
    
    render() {
        const user = getUser();
        const subDomain = getSubDomain();

        const pubRoutes = subDomain ? this.subRoutes : this.pubRoutes;

        return (
            <Switch>
                {/* Public Routes */}
                {pubRoutes.map((r, k) => (
                    <Route key={k} path={r.p} exact sensitive component={r.c} />
                ))}

                {/* Private Routes */}
                {user.role === A4P_ROLE_USER && (
                    <>
                        <Route
                            path="/app"
                            exact
                            component={VendorAppointments}
                        />
                        <Route
                            path="/app/prescriptions"
                            exact
                            component={PrescriptionsIndex}
                        />  
                        <Route
                            path="/app/my-appointments"
                            exact
                            component={UserAppointments}
                        />

                        <Route
                            path="/app/medical-records"
                            exact
                            component={UserMecialRecords}
                        />

                        <Route
                            path="/app/my-profile"
                            exact
                            component={UserMyProfile}
                        />
                    </>
                )}

                {/* Vendor Routes */}
                {user.role === A4P_ROLE_VENDOR && (
                    <>
                        <Route
                            path="/app"
                            exact
                            component={VendorAppointments}
                        />
                        <Route
                            path="/app/appointments"
                            exact
                            component={VendorAppointments}
                        />
                        <Route
                            path="/app/listings"
                            exact
                            component={VendorListings}
                        />      
                        <Route
                            path="/app/reports"
                            exact
                            component={VendorReports}
                        />                 
                        <Route
                            path="/app/customers*"
                            exact
                            component={VendorCustomers}
                        />  
                        <Route
                            path="/app/settings*"
                            exact
                            component={SettingsIndex}
                        /> 
                        <Route
                            path="/app/prescription-templates"
                            exact
                            component={PrescriptionTemplatesIndex}
                        />
                        <Route
                            path="/app/help"
                            exact
                            component={VendorHelp}
                        />                        
                        <Route
                            path="/clinic/onboarding"
                            exact
                            component={VendorOnboarding}
                        />
                        <Route
                            path="/app/my-profile"
                            exact
                            component={UserMyProfile}
                        />
                    </>
                )}

                <Route exact path="/logout" render={() => logoutUser()} />

                <Route component={NotFound} />
            </Switch>
        );
    }
}
