// Hasura GraphQL server response parser 

import { logoutUser } from "./LoginChecker";

export const GQL_ERROR_DUPLICATE = "GQL_ERROR_DUPLICATE"; // unique key constraint voilated

export const GQL_ERROR_NULL = "GQL_ERROR_NULL"; // non null constraint voilated

export const GQL_ERROR_INVALID_JSON = "GQL_ERROR_INVALID_JSON"; // invalid json sent

export const GQL_ERROR_UNAUTHORIZED = "GQL_ERROR_UNAUTHORIZED"; // 401 un authorized

export const GQL_ERROR_UNKNOWN = "GQL_ERROR_UNKNOWN"; // for 500 internal server error

// parses the axios error object and return the graphql error code
export default function parseGQLError(errors) {
  //console.log(errors)
  if (errors) {    
    let errorMessage = errors[0].message;    
    if (errorMessage.includes("Uniqueness violation")) return GQL_ERROR_DUPLICATE;
    else if (errorMessage.includes("Not-NULL violation")) return GQL_ERROR_NULL;
    else if (errorMessage.includes("invalid json")) return GQL_ERROR_INVALID_JSON;    
    else if (errorMessage.includes("Unauthorized")) return GQL_ERROR_UNAUTHORIZED;    
    else if (errorMessage.includes("JWSInvalidSignature")) logoutUser();    
  }
  return GQL_ERROR_UNKNOWN;
}
