import React, { Component } from "react";
import Button from "components/Form/Button";
import {
    A4P_APPOINTMENT_STATUSES,
    A4P_ROLE_VENDOR,
    APP_CONSULTATION_TYPE_NOW_PHONE,
} from "data/config/variables";
import moment from "moment-timezone";
import PHRIndex from "../PHR";
import SectionHead from "../sectionHead";
import BookAppointment, { getBookingLink } from "components/BookAppointment";
import {
    getVendorId,
    getSubDomain,
    getUserRole,
    getConsultationName,
    getConsultationIcon,
} from "utils";
import { RenderStatus } from ".";
import { confirmAlert } from "react-confirm-alert";
import { cancelAppointment, updateAppointmentStatus } from "./network";
import config from "data/config";
import { APP_CONSULTATION_TYPE_CLINIC } from "data/config/variables";
import SubSectionHead from "components/SubSectionHead";
import PrescriptionsIndex from "page/vendor/customers/list/details/prescriptions";

export default class AppointmentDetails extends Component {
    constructor(props) {
        super(props);
        this.menuItems = {
            details: 0,
            video: 1,
            phr: 2,
            prescription: 3,
        };

        this.state = {
            user: this.props.user,
            selMenu: this.menuItems.details,
            subDomain: getSubDomain(),
        };
    }

    render() {
        const { menuItems } = this;
        const { user, selMenu, subDomain } = this.state;
        const { booking, onGoBack, isNew, timezone } = this.props;
        const { details, status } = booking || {};
        const { pet } = details || {};
        const slot = booking ? moment.tz(booking.slot, timezone) : null;
        const vendor_id = subDomain ? subDomain.vendor_id : getVendorId();
        const isVendor = getUserRole() === A4P_ROLE_VENDOR;

        let menu = [],
            consultationLink = null,
            listingDetails = [];

        if (details) {
            menu = [
                {
                    id: menuItems.details,
                    title: "Details",
                    icon: "fa-info-circle",
                },
            ];

            // quick consult, tele, video
            if (details && details.type !== APP_CONSULTATION_TYPE_CLINIC) {
                consultationLink = getBookingLink({
                    userId: details.user.id,
                    bookingId: booking.id,
                    isVendor,
                });

                menu.push({
                    id: menuItems.video,
                    title: getConsultationName(details.type),
                    icon: getConsultationIcon(details.type),
                });

                /* menu.push({
                    id: menuItems.phr,
                    title: "Medical Records",
                    icon: "fa-book-medical",
                }); */
            }

            menu.push({
                id: menuItems.prescription,
                title: "Prescriptions",
                icon: "fa-pills",
            });

            listingDetails = [
                {
                    title: "Customer Name",
                    value: `${details.user.first_name} ${details.user.last_name}`,
                },
                { title: "Contact Number", value: details.user.phone },
                { title: "Pet Name", value: pet ? pet.name : null },
                { title: "Listing", value: details.details.title },
                { title: "", value: details.details.line1 },
                { title: "address", value: details.details.address },
                { title: "Booking ID", value: `${booking.id}` },
                {
                    title: "Booking Date",
                    value: moment
                        .tz(booking.created_at, details.listingTZ)
                        .format("Do dddd, MMMM hh:mm A"),
                },
                { title: "Booking Information", value: details.info && details.info.summary },
                {
                    title: "Consultation Type",
                    value: getConsultationName(details.type),
                },
                {
                    title: "Consultation Link",
                    value: consultationLink,
                    type: "link",
                },
                {
                    title: "Consultation Date",
                    value: moment
                        .tz(details.slot, details.listingTZ)
                        .format("Do dddd, MMMM hh:mm A"),
                },
                //{ title: "User Timezone", value: details.userTZ },
                //{ title: "Listing Timezone", value: details.userTZ },
                {
                    title: "Status",
                    value: RenderStatus(slot, timezone, status, details.type),
                },
            ];
        }

        return (
            <div className={`${user ? "" : "container py-5"}`}>
                {user ? (
                    <SubSectionHead
                        title={`${
                            isNew ? "New Appointment" : "Appointment Details"
                        }`}
                        onGoBack={(e) => onGoBack(false)}
                    />
                ) : (
                    <SectionHead
                        title={`${
                            isNew ? "New Appointment" : "Appointment Details"
                        }`}
                        onGoBack={(e) => onGoBack(false)}
                    />
                )}

                {isNew ? (
                    <BookAppointment vendor_id={vendor_id} user={user} />
                ) : (
                    <div>
                        {/* Menu */}
                        <div className="p-2 border theme-bg-primary">
                            <ul className="nav nav-pills ">
                                {menu.map((item, i) => (
                                    <li key={i} className="nav-item">
                                        <a
                                            href="#!"
                                            className={`nav-link px-5 ${
                                                selMenu === item.id
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    selMenu: item.id,
                                                });
                                            }}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Details */}
                        <div className="border p-2 mt-2 ">
                            <div className="d-flex px-3 flex-column">
                                <div
                                    className="pb-4 "
                                    style={{
                                        display:
                                            selMenu === menuItems.details
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    {listingDetails.map((d, k) => {
                                        return (
                                            d.value && (
                                                <div
                                                    key={k}
                                                    className="mt-4 d-flex align-items-center"
                                                >
                                                    <div className="w-25 text-muted">
                                                        {d.title}:
                                                    </div>
                                                    <div className="lead text-muted">
                                                        {d.type === "link" ? (
                                                            <a
                                                                href={d.value}
                                                                target="_blank"
                                                            >
                                                                {d.value}
                                                            </a>
                                                        ) : (
                                                            d.value
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        );
                                    })}
                                    {status ===
                                        A4P_APPOINTMENT_STATUSES.IN_PROGRESS && (
                                        <div className="mt-5">
                                            {isVendor &&
                                                details.type ===
                                                    APP_CONSULTATION_TYPE_NOW_PHONE && (
                                                    <Button
                                                        btnCls="btn-success px-4 mr-3"
                                                        iconCls="fa-check"
                                                        value="Mark as Completed"
                                                        onClick={(e) =>
                                                            confirmAlert({
                                                                message: `Are you sure you want to mark this appointment as completed?`,
                                                                buttons: [
                                                                    {
                                                                        label:
                                                                            "Yes",
                                                                        onClick: () => {
                                                                            updateAppointmentStatus(
                                                                                booking.id,
                                                                                A4P_APPOINTMENT_STATUSES.COMPLETE
                                                                            )
                                                                                .then(
                                                                                    (
                                                                                        r
                                                                                    ) =>
                                                                                        onGoBack(
                                                                                            true
                                                                                        )
                                                                                )
                                                                                .catch(
                                                                                    (
                                                                                        err
                                                                                    ) =>
                                                                                        console.log(
                                                                                            err
                                                                                        )
                                                                                );
                                                                        },
                                                                    },
                                                                    {
                                                                        label:
                                                                            "No",
                                                                    },
                                                                ],
                                                            })
                                                        }
                                                    />
                                                )}

                                            <Button
                                                btnCls="btn-danger px-4"
                                                iconCls="fa-ban"
                                                value="Cancel Appointment"
                                                onClick={(e) =>
                                                    confirmAlert({
                                                        message: `Are you sure you want to cancel this appointment?`,
                                                        buttons: [
                                                            {
                                                                label: "Yes",
                                                                onClick: () => {
                                                                    updateAppointmentStatus(
                                                                        booking.id,
                                                                        A4P_APPOINTMENT_STATUSES.CANCELED
                                                                    )
                                                                        .then(
                                                                            (
                                                                                r
                                                                            ) =>
                                                                                onGoBack(
                                                                                    true
                                                                                )
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                err
                                                                            ) =>
                                                                                console.log(
                                                                                    err
                                                                                )
                                                                        );
                                                                },
                                                            },
                                                            {
                                                                label: "No",
                                                            },
                                                        ],
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                                </div>

                                <div
                                    style={{
                                        display:
                                            selMenu === menuItems.video
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <iframe
                                        allow="geolocation; microphone; camera"
                                        src={consultationLink}
                                        scrolling="auto"
                                        frameBorder="0"
                                        className="FullHeight w-100"
                                        style={{ height: "95vh" }}
                                    />
                                </div>

                                <div
                                    style={{
                                        display:
                                            selMenu === menuItems.phr
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <PHRIndex
                                        isViewMode={true}
                                        userId={details.user.id}
                                        petId={pet ? pet.id : null}
                                    />
                                </div>

                                
                                <div
                                    style={{
                                        display:
                                            selMenu === menuItems.prescription
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <PrescriptionsIndex user={details.user} booking={booking} />
                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
