import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";

import SimpleReactValidator from "simple-react-validator";
import { loginUser } from "utils/LoginChecker";
import FormElement from "components/Form/FormElement";
import { A4P_ROLE_USER } from "data/config/variables";
import parseGQLError, { GQL_ERROR_DUPLICATE } from "utils/HasuraParser";
import { signup, fetchCoupon } from "./network";
import * as qs from "query-string";
import { detect } from "detect-browser";
import MobileVerify from "components/Auth/MobileVerify";
import { getSubDomain, getVendorId } from "utils";
import isEmpty from "utils/isEmpty";
import { getUserInfo } from "page/booking/network";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCaptchaSubmit = this.onCaptchaSubmit.bind(this);
        this.fetchCoupon = this.fetchCoupon.bind(this);

        this.validator = new SimpleReactValidator({
            messages: {
                // using size as a placeholder to show the custom error msg
                size: "Mobile number must be verified",
            },
        });

        this.initialState = {
            role: this.props.role,
            first_name: "",
            last_name: "",
            password: "",
            email: "",
            phone: this.props.vendorSideSignup ? null : "000", // prefilled to just show the error msg

            showPromoForm: false, // to show promo code form
            promoCode: null,
            showPromoStatus: false,
            isPromoCodeValid: false,
            promoDescription: null,
            planId: null,

            isCaptchaValid: false,
            submitBtnTxt: "Submit",
            serverErrors: false, // for displaying errors from server
            serverErrorMessage: "Sorry! Something went wrong", // error message frm server
            thankYou: false, // determines if form got submitted successfully
        };
        this.state = { ...this.initialState };
    }

    componentDidMount() {
        const coupon = qs.parse(this.props.location.search).cc;
        if (coupon) {
            this.setState({ showPromoForm: true, promoCode: coupon }, () =>
                this.fetchCoupon()
            );
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onFormSubmit(e) {
        e.preventDefault();
        //this.setState({ thankYou: true }); return;

        if (this.validator.allValid()) {
            const { vendorSideSignup, onComplete } = this.props;
            const { phone } = this.state;
            this.setState({
                submitBtnTxt: "Please Wait...",
                serverErrors: false,
                serverErrorMessage: "",
            });

            // get utm parameters
            const utm_source = localStorage.getItem("utm_source");
            const utm_medium = localStorage.getItem("utm_medium");
            const utm_campaign = localStorage.getItem("utm_campaign");
            const utm_term = localStorage.getItem("utm_term");
            const utm_content = localStorage.getItem("utm_content");

            // get browser info
            const browser = detect();
            const subDomain = getSubDomain();
            const vendorId = getVendorId();
            const source = {
                medium: "Web",
                browser: browser.name,
                version: browser.version,
                os: browser.os,
                vendor_id: subDomain
                    ? subDomain.vendor_id
                    : isEmpty(vendorId)
                    ? ""
                    : vendorId,
                utm_source, utm_medium, utm_campaign, utm_term, utm_content
            };

            signup({ ...this.state, source })
                .then((r) => {
                    if (r.id && vendorSideSignup) {
                        getUserInfo(r.id, phone).then((r) => {
                            if (onComplete) onComplete({ token: r.token });
                            else loginUser(r.token);
                        });
                    } else this.props.onSignupComplete(this.state);
                })
                .catch((err) => {
                    err =
                        typeof err === "object"
                            ? parseGQLError(err.response.data.data.errors)
                            : err;
                    this.setState({
                        submitBtnTxt: "Submit",
                        serverErrors: true,
                        serverErrorMessage:
                            err === GQL_ERROR_DUPLICATE
                                ? "This account already exists in our System."
                                : "Sorry! Something went wrong. Please try again later.",
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
            console.log(this.validator.getErrorMessages());
        }
    }

    // validate the entered coupon
    fetchCoupon() {
        const { promoCode } = this.state;
        this.setState({ planId: null, showPromoStatus: false });
        if (promoCode) {
            fetchCoupon(promoCode)
                .then((r) => {
                    console.log(r);
                    const [plan] = r.data.plans;
                    if (plan)
                        this.setState({
                            planId: plan.id,
                            showPromoStatus: true,
                            isPromoCodeValid: true,
                            promoDescription: plan.description,
                        });
                    else
                        this.setState({
                            showPromoStatus: true,
                            isPromoCodeValid: false,
                            promoDescription: null,
                        });
                })
                .catch((err) => console.log(err));
        }
    }

    onCaptchaSubmit(value) {
        this.setState({ isCaptchaValid: true });
    }

    render() {
        const warningBlock = () => (
            <div className="alert alert-danger">
                <strong className="mr-2">Error!</strong>
                {this.state.serverErrorMessage}
            </div>
        );

        const { showLoginLink, isEmailRequired, vendorSideSignup } = this.props;
        const { phone } = this.state;
        this.validator.purgeFields();

        return (
            <div>
                <div>
                    {this.state.serverErrors && warningBlock()}
                    <form
                        id="vsignup"
                        method="post"
                        onSubmit={this.onFormSubmit}
                    >
                        <div className="row">
                            <FormElement
                                className="col-sm-12 col-md-6"
                                name="first_name"
                                placeHolder="First Name"
                                state={this.state}
                                onChange={this.onChange}
                                validator={this.validator}
                                validation="required|alpha"
                            />

                            <FormElement
                                name="last_name"
                                className="col-sm-12 col-md-6"
                                placeHolder="Last Name"
                                state={this.state}
                                onChange={this.onChange}
                                validator={this.validator}
                                validation="required|alpha"
                            />

                            {isEmailRequired && (
                                <FormElement
                                    name="email"
                                    className="col-sm-12"
                                    placeHolder="Email"
                                    state={this.state}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required|email"
                                />
                            )}

                            {vendorSideSignup ? (
                                <FormElement
                                    name="phone"
                                    className="col-sm-12"
                                    placeHolder="Mobile Number"
                                    value={phone}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required|numeric|size:10"
                                />
                            ) : (
                                <>
                                    <div className="col-sm-12 mb-2">
                                        <MobileVerify
                                            onVerify={(phone) =>
                                                this.setState({ phone })
                                            }
                                        />
                                        {phone.length < 10 &&
                                            this.validator.message(
                                                "Mobile",
                                                phone,
                                                "size:10"
                                            )}
                                    </div>

                                    <FormElement
                                        name="password"
                                        type="password"
                                        className="col-sm-12"
                                        placeHolder="Profile Password"
                                        state={this.state}
                                        onChange={this.onChange}
                                        validator={this.validator}
                                        validation="required|min:8"
                                    />
                                </>
                            )}
                        </div>

                        <div className="legal-note mb-3 text-light text-center">
                            By signing up, you agree to our{" "}
                            <Link to="/terms" className="text-light">
                                terms of service
                            </Link>{" "}
                            and{" "}
                            <Link to="/privacy" className="text-light">
                                privacy policy
                            </Link>
                            .
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary theme-btn mx-auto"
                            >
                                {this.state.submitBtnTxt}
                            </button>
                        </div>
                    </form>
                </div>
                {showLoginLink && (
                    <div className="option-container my-4 pt-3 text-center">
                        <div className="lead-text">
                            Already have an account?
                            <a
                                className="ml-2"
                                href="#!"
                                onClick={() => this.props.onLoginClick()}
                            >
                                Log in
                            </a>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Signup.defaultProps = {};

Signup.propTypes = {
    onLoginClick: PropTypes.func.isRequired,
    onSignupComplete: PropTypes.func.isRequired,
    role: PropTypes.string,
};

export default withRouter(Signup);
