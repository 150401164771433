import React, { Component } from "react";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-datepicker/dist/react-datepicker.css";
import "./table.css";
import FormElement from "../Form/FormElement";
import { TIMEZONES } from "components/Roster/constants";
import BookAppointmentService from "./service";
import isEmpty from "utils/isEmpty";
import Button from "../Form/Button";
import { APP_CONSULTATION_TYPE_NOW_PHONE } from "data/config/variables";
import { isSMDevice } from "utils";

export default class BookAppointmentSlot extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedService: false,
			selectedDate: null,
			selectedTime: null,
			timezone: moment.tz.guess(),
			date: new Date(),
			selectedTime: null,
		};
	}

	getTimeSlots(listing, bookedSlots) {
		const { selectedService, selectedDate, timezone: userTZ } = this.state;

		if (selectedService && selectedDate) {
			let allSlots = [];
			const selDt = moment(selectedDate).format("YYYY-MM-DD"); // to remove Timezone
			const { timezone: listingTZ } = listing;
			const { schedule, duration } = selectedService;

			// generate listing time slots for next2 days (to account for timezone)
			const selDt_listingTZ = moment.tz(selDt, userTZ).tz(listingTZ);

			[...Array(2)].forEach((_, i) => {
				const currD = selDt_listingTZ.add(i, "d").format("ddd");

				// filter schedule for rows containing the given day
				const filteredSchedule = schedule.filter((s) =>
					s.days.includes(currD)
				);

				// for each row calc slots
				filteredSchedule.forEach((row) => {
					const st = moment.tz(
						`${selDt_listingTZ.format("YYYY-MM-DD")} ${row.from}`,
						listingTZ
					);
					const et = moment.tz(
						`${selDt_listingTZ.format("YYYY-MM-DD")} ${row.to}`,
						listingTZ
					);
					while (st.isBefore(et)) {
						allSlots.push(st.format());
						st.add(duration, "minute");
					}
				});
			});

			// reset to current date
			const selDt_userTZ = moment.tz(selDt, userTZ);

			// filter applicable slots and exclude already booked slots
			const currTime = moment().tz(userTZ).add(1, "hour"); // one hour buffer
			const filteredSlots = allSlots.filter(
				(ts) =>
					selDt_userTZ.isSame(moment.tz(ts, userTZ), "day") &&
					moment.tz(ts, userTZ).isAfter(currTime) && // after current time
					!bookedSlots.includes(ts)
			);
			//console.log(allSlots, bookedSlots, filteredSlots);
			return filteredSlots;
		}
	}

	render() {
		const {
			selectedTime,
			timezone,
			selectedService,
			selectedDate,
		} = this.state;
		const { listing, bookedSlots } = this.props;

		const slots = this.getTimeSlots(listing, bookedSlots);
		const { type } = selectedService || {};

		return (
			<div>
				<div className="">
					<BookAppointmentService
						listing={listing}
						onSelect={(sel) =>
							this.setState({
								selectedService: sel,
								selectedDate: null,
								selectedTime: null,
							})
						}
					/>
					{type && type !== APP_CONSULTATION_TYPE_NOW_PHONE && (
						<div>
							<div className="mt-2">
								<div>
									<div className="row ">
										<div
											className="col-12 pb-3"
											style={{ minWidth: "fit-content" }}
										>
											<p className="pb-2 h6 text-muted">
												Select a Date:
											</p>
											<div>
												{selectedDate ? (
													<h5
														onClick={(e) =>
															this.setState({
																selectedDate: null,
															})
														}
														style={{
															cursor: "pointer",
														}}
													>
														
														<i className="text-muted far fa-calendar mr-2"></i>
														{moment(
															selectedDate
														).format(
															"Do dddd, MMMM"
														)}
													</h5>
												) : (
													<DatePicker
														selected={selectedDate}
														dateFormat="dd/MM/yyyy"
														inline
														onChange={(date) =>
															this.setState({
																selectedDate: date,
															})
														}
														minDate={new Date()}
														maxDate={new Date().setDate(
															new Date().getDate() +
																15
														)}
														className="form-control"
													/>
												)}
											</div>
										</div>
										<div className="col-12">
											{selectedDate && (
												<div
													className={`d-flex flex-wrap justify-content-start`}
												>
													{isEmpty(slots) ? (
														<p className="alert alert-danger">
															No slots available
															for this date
														</p>
													) : (
														<>
															<p className="pb-3 w-100 pb-2 h6 text-muted">
																Select a Slot:
															</p>
															{slots.map(
																(t, k) => (
																	<div
																		key={k}
																		className={`shadow-sm rounded py-2 px-3 mb-3 mr-3 ${
																			selectedTime ===
																			t
																				? "bg-primary text-white"
																				: "bg-white text-primary"
																		}`}
																		onClick={(
																			e
																		) => {
																			confirmAlert(
																				{
																					message: `Confirm ${moment(
																						selectedDate
																					).format(
																						"Do dddd, MMMM"
																					)} at ${moment
																						.tz(
																							t,
																							timezone
																						)
																						.format(
																							"hh:mm A"
																						)}?`,
																					buttons: [
																						{
																							label:
																								"Yes",
																							onClick: () =>
																								this.props.onSelect(
																									{
																										timezone: timezone,
																										time: t,
																										service: selectedService,
																									}
																								),
																						},
																						{
																							label:
																								"No",
																							onClick: () =>
																								console.log(
																									"escaped"
																								),
																						},
																					],
																				}
																			);
																			this.setState(
																				{
																					selectedTime: t,
																				}
																			);
																		}}
																		style={{
																			cursor:
																				"pointer",
																		}}
																	>
																		<div className="text-center ">
																			{moment
																				.tz(
																					t,
																					timezone
																				)
																				.format(
																					"hh:mm A"
																				)}
																		</div>
																	</div>
																)
															)}
														</>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{type === APP_CONSULTATION_TYPE_NOW_PHONE && (
						<div>
							<p
								className="text-light"
								style={{ fontSize: "0.9em" }}
							>
								Telephonic consultation for follow-up/quick
								queries but without an appointment. Doctor is
								expected to call you back at the earliest
								possible time.
							</p>
							<Button
								btnCls="btn-outline-primary mb-3"
								value="Continue"
								onClick={(e) =>
									this.props.onSelect({
										timezone: timezone,
										time: selectedTime,
										service: selectedService,
									})
								}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}
