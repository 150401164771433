import React, { Component } from "react";
import SectionHead from "components/sectionHead";
import { getPrescriptionTemplates, deleteTemplate } from "./network";
import NoRecords from "components/noRecords";
import ManagePT from "./details";
import PTListingItem from "./listItem";
import isEmpty from "utils/isEmpty";
import { confirmAlert } from "react-confirm-alert";

export default class PrescriptionManageTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            items: [],
            error: false,
            showDetails: false,
        };
        this.onSelect = this.onSelect.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onGoBack = this.onGoBack.bind(this);
    }

    onSelect(data) {
        let item = this.state.items.find((item) => item.id === data.id);
        this.setState({ selectedItem: item, showDetails: true });
    }

    onDelete(item) {
        confirmAlert({
            message: `Are you sure you want to delete this item?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        deleteTemplate(item.id)
                            .then((r) => {
                                const { items } = this.state;
                                const filteredItems = items.filter(
                                    (i) => i.id !== item.id
                                );
                                this.setState({ items: filteredItems });
                            })
                            .catch((err) => console.log(err));
                    },
                },
                {
                    label: "No",
                    onClick: () => console.log("escaped"),
                },
            ],
        });
    }

    onUpdate(item) {
        const { items } = this.state;
        const { id } = item;

        let updated = false;
        items.forEach((e, k) => {
            if (e.id === id) {
                items[k] = { ...item };
                updated = true;
                return true;
            }
        });

        if (!updated) items.push(item);

        this.setState({ items });
    }

    onGoBack() {
        this.setState({ selectedItem: null, showDetails: false });
    }

    componentDidMount() {
        const { items } = this.state;

        getPrescriptionTemplates()
            .then((r) => {
                const li = r.data.prescription_templates;
                if (!isEmpty(li))
                    this.setState({
                        items: [...items, ...li],
                    });
            })
            .catch((err) => {
                this.setState({ error: true });
                console.log(err);
            });
    }

    render() {
        const { items, selectedItem, error, showDetails } = this.state;

        return (
            <div className="container py-5">
                {showDetails ? (
                    <ManagePT
                        onGoBack={this.onGoBack}
                        item={selectedItem}
                        onUpdate={this.onUpdate}
                    />
                ) : (
                    <div className="">
                        <SectionHead
                            title="Prescription Templates"
                            createTxt="New Template"
                            onCreateClick={(e) =>
                                this.setState({ showDetails: true })
                            }
                        />

                        {isEmpty(items) ? (
                            <NoRecords />
                        ) : (
                            <div>
                                {error ? (
                                    <div className="container text-center py-5">
                                        Sorry something went wrong. Please try
                                        again later
                                    </div>
                                ) : (
                                    <div>
                                        <div className="d-none mb-3">
                                            <input
                                                type="search"
                                                className="form-control border-light"
                                                placeholder="Search here..."
                                            />
                                        </div>
                                        <div className="row">
                                            {items &&
                                                items.map((item, k) => (
                                                    <div
                                                        key={k}
                                                        className="col-6"
                                                    >
                                                        <PTListingItem
                                                            item={item}
                                                            onSelect={
                                                                this.onSelect
                                                            }
                                                            onDelete={
                                                                this.onDelete
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
