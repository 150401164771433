import React, { Component } from "react";
import BookAppointmentSlot from "./slot";
import BookAppointmentPayment from "./payment";
import BookAppointmentConfirmation from "./confirmation";
import BookAppointmentListing from "./listing";
import BookAppointmentInfo from "./information";
import Button from "../Form/Button";
import { getConsultationName } from "utils";
import isEmpty from "utils/isEmpty";
import moment from "moment-timezone";
import { fetchBookedSlots } from "./network";
import config from "data/config";

export default class BookAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            vendor_id: this.props.vendor_id,
            error: false,
            currStage: this.STAGES.listing,
            listing: null,
            isSingleListing: false,
            bookedSlots: [],
            slot: null,
            skipInfo: this.props.skipInfo || false,
            info: {},
        };
        this.fetchBookedSlots = this.fetchBookedSlots.bind(this);
    }

    STAGES = { listing: 1, slot: 2, info: 3, payment: 4, confirmation: 5 };

    fetchBookedSlots() {
        const { listing } = this.state;
        fetchBookedSlots(listing.id)
            .then((r) => {
                const li = r.data.bookings;
                if (!isEmpty(li)) {
                    const fLi = li.map((l) => l.details.slot);
                    this.setState({ bookedSlots: fLi });
                }
            })
            .catch((err) => console.log(err));
    }

    getSummary = () => {
        const { listing, slot, info, skipInfo, isSingleListing } = this.state;
        let summary = [];

        if (listing && !isSingleListing) {
            summary.push({
                title: "Practice",
                value: listing.title,
                editEnabled: !isSingleListing,
                state: {
                    currStage: this.STAGES.listing,
                    listing: null,
                    slot: null,
                    info: null,
                },
            });
        }

        if (slot) {
            const _st = slot.time
                ? `, at: ${moment
                      .tz(slot.time, slot.timezone)
                      .format("Do dddd, MMMM hh:mm A")}`
                : "";

            summary.push({
                title: "Type",
                editEnabled: true,
                value: `${getConsultationName(slot.service.type)}${_st}`,
                state: { currStage: this.STAGES.slot, slot: null, info: null },
            });
        }

        if (!skipInfo && !isEmpty(info)) {
            summary.push({
                title: "Information",
                value: info.summary,
                editEnabled: true,
                value: `${info.pet ? `Pet: ${info.pet.name},` : ""} Summary: ${
                    info.summary
                }`,
                state: { currStage: this.STAGES.info },
            });
        }

        return summary;
    };

    render() {
        const {
            user,
            listing,
            slot,
            bookedSlots,
            info,
            currStage,
            error,
            vendor_id,
            bookingId,
            skipInfo,
        } = this.state;
        let summary = this.getSummary();

        return (
            <div>
                {error ? (
                    <div className="col my-5 text-center text-muted h2">
                        No listing found!
                    </div>
                ) : (
                    <div>
                        {!isEmpty(summary) && (
                            <div>
                                <div className="mb-3">
                                    <p className="h6 text-muted">Summary:</p>
                                </div>
                                <div className="px-3 pt-3 border rounded">
                                    {currStage !== this.STAGES.confirmation &&
                                        summary.map((it, k) => (
                                            <div key={k} className="mb-3">
                                                <small className="text-muted">
                                                    {it.title}:
                                                </small>
                                                <div
                                                    className="text-wrap"
                                                    title="Edit this"
                                                    style={{
                                                        cursor: "pointer",
                                                        lineHeight: "2em",
                                                    }}
                                                    onClick={(e) =>
                                                        it.editEnabled
                                                            ? this.setState({
                                                                  ...it.state,
                                                              })
                                                            : false
                                                    }
                                                >
                                                    <span className="h5">
                                                        {it.value}{" "}
                                                    </span>
                                                    {it.editEnabled && (
                                                        <i
                                                            title="edit"
                                                            className="ml-2 fad fa-pencil text-primary"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}

                        {currStage === this.STAGES.listing && (
                            <BookAppointmentListing
                                listing={listing}
                                vendor_id={vendor_id}
                                onSelect={(
                                    listing,
                                    isSingleListing = false
                                ) => {
                                    if (listing)
                                        this.setState(
                                            {
                                                listing,
                                                currStage: currStage + 1,
                                                isSingleListing,
                                            },
                                            this.fetchBookedSlots
                                        );
                                    else this.setState({ error: true });
                                }}
                            />
                        )}

                        {currStage === this.STAGES.slot && (
                            <BookAppointmentSlot
                                slot={slot}
                                listing={listing}
                                bookedSlots={bookedSlots}
                                onSelect={(slot) =>
                                    this.setState({
                                        slot,
                                        currStage: skipInfo
                                            ? currStage + 2
                                            : currStage + 1,
                                    })
                                }
                            />
                        )}

                        {currStage === this.STAGES.info && (
                            <BookAppointmentInfo
                                info={info}
                                user={user}
                                onComplete={(info) =>
                                    this.setState({
                                        info,
                                        currStage: currStage + 1,
                                    })
                                }
                            />
                        )}

                        {currStage === this.STAGES.payment && (
                            <BookAppointmentPayment
                                listing={listing}
                                slot={slot}
                                info={info}
                                onComplete={(bookingId) =>
                                    this.setState({
                                        currStage: currStage + 1,
                                        bookingId,
                                    })
                                }
                            />
                        )}

                        {currStage === this.STAGES.confirmation && (
                            <BookAppointmentConfirmation
                                slot={slot}
                                info={info}
                                bookingId={bookingId}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export const getBookingLink = ({ userId, bookingId, isVendor = false }) => {
    return `${config.SITE_URL}?z=${userId}${isVendor ? "C" : "U"}${bookingId}`;
};

// return userId, vendorId, bookingId
export const getBookingFromLink = (url) => {
    if (!url) return {};
    const isVendor = url.includes("C");
    const [userId, bookingId] = url.replace("C", "U").split("U");
    return { userId, bookingId, isVendor };
};
