import ReactGA from "react-ga";
import config from "data/config";
import { getUserId, getVendorId } from "utils";
import { gqlSend } from "./AxiosUtil";

const env = "production"; // "production"
// initialize GA
export const initGA = () => {
    if (config.ENV === env) {
        ReactGA.initialize(config.GOOGLE_ANALYTICS_ID);
        const userId = getUserId();
        if (userId) {
            ReactGA.set({
                userId,
                vendorId: getVendorId(),
            });
        }
    }
};

// record pageview
export const gaPageView = (location) => {
    if (config.ENV === env) {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    }
};

// record events
export const gaEvent = (event) => {
    if (config.ENV === env) {
        ReactGA.event(event);
    }
};

// events in localdb
export const insertEvent = event => {
    //if (config.ENV === env) {
        let {category, action, label, value, details, note, userId, vendorId} = event;

        details = details || {};
        userId = userId || getUserId() || null;
        vendorId = vendorId || getVendorId() || null;

        const q = {
            query: `
                mutation($details: jsonb) {
                    insert_events(objects: {action: "${action}", category: "${category}", value: "${value}", 
                        details: $details, label: "${label}", note: "${note}", vendor_id: ${vendorId}, user_id: ${userId}}) {
                        affected_rows
                    }
            }`,
            variables: {
                details
            }
        };
        
        return gqlSend(q).catch(err=>console.log(err));
    //}
}