import React, { Component } from "react";
import DrugsEditorRow from "./drugrow";

export default class DrugsEditorIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drugs: this.props.drugs || [{}],
        };
    }

    componentWillReceiveProps(props) {
        if (this.props.drugs) this.setState({ drugs: props.drugs });
    }

    render() {
        const { drugs } = this.state;

        return (
            <div>
                {drugs.map((r, k) => (
                    <div key={k} className="d-flex align-items-around">
                        <div className="flex-grow-1">
                            <DrugsEditorRow
                                data={r}
                                onUpdate={(row) => {
                                    const rows = [...drugs];
                                    rows[k] = { ...row };
                                    this.setState({ drugs: [...rows] });
                                    this.props.onUpdate(rows);
                                }}
                            />
                        </div>
                        <div className="mt-2">
                            <a
                                className="h4 text-danger ml-3"
                                href="#!"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const rows = [...drugs];
                                    rows.splice(k, 1);
                                    this.setState({ drugs: [...rows] });
                                    this.props.onUpdate(rows);
                                }}
                            >
                                <i className="fa fa-minus-circle"></i>
                            </a>
                        </div>
                    </div>
                ))}
                <div className="mt-3">
                    <button
                        className="btn btn-secondary px-4"
                        onClick={(e) => {
                            const rows = [...drugs];
                            rows.push({});
                            this.setState({ drugs: [...rows] });
                        }}
                    >
                        <i className="fa fa-plus mr-2"></i>
                        Add Drug
                    </button>
                </div>
            </div>
        );
    }
}
