import React, { Component } from "react";
import moment from "moment";

export default class PrescriptionListingItem extends Component {
    render() {
        const { item } = this.props;
        const { prescription: data, vendor: doctor, user } = item.details;
        const slot = moment(item.created_at);

        return (
            <div>
                <div
                    className="shadow-sm border mb-3 cursor-pointer"
                    onClick={(e) => this.props.onSelect(item)}
                >
                    <div className="d-flex align-items-center">
                        <div className="align-items-center mr-4 border-right">
                            <div
                                className="text-center"
                                style={{ minWidth: "120px" }}
                            >
                                <div className="flex-grow border-top mb-2">
                                    <div className="h1 text-muted mb-0">
                                        {slot.format("DD")}
                                    </div>
                                    <div className=" text-muted">
                                        {slot.format("dddd")}
                                    </div>
                                </div>
                                <div
                                    className="py-2 text-white"
                                    style={{
                                        fontSize: "0.9em",
                                        background: "#47B0DF",
                                    }}
                                >
                                    <strong>{slot.format("MMMM")}</strong>
                                </div>
                            </div>
                        </div>

                        <div className="flex-grow-1 mr-3">
                            <h5>
                                {data.name}
                                <small className="ml-1 text-muted">
                                    {data.age}
                                </small>
                            </h5>
                            <div className="text-muted">
                                <div className="d-flex flex-wrap justify-content-between ">
                                    <div className="d-flex ">
                                        <div>
                                            <strong>Contact:</strong>
                                        </div>
                                        <div className="ml-2">{user.phone}</div>
                                    </div>
                                    <div className="d-flex ">
                                        <div>
                                            <strong>Doctor Name:</strong>
                                        </div>
                                        <div className="ml-2">{`${doctor.first_name} ${doctor.last_name}`}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <strong>Date:</strong>
                                        </div>
                                        <div className="ml-2">
                                            {moment(item.created_at).format(
                                                "DD-MM-YYYY"
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex ">
                                        <div>
                                            <strong>ID#</strong>
                                        </div>
                                        <div className="ml-2">{item.id}</div>
                                    </div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div>
                                        <a
                                            className="pr-2"
                                            href={`/P${item.hash}`}
                                            target="_blank"
                                        >
                                            <strong>
                                                <i className="fad fa-external-link"></i>
                                            </strong>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
