import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { getUser, getSubDomain } from "utils";
import isEmpty from "utils/isEmpty";
import SubDomainFooter from "./sub";

class Footer extends Component {
	shouldShowFooter() {
		// dont show footer if the user is logged in
		if (!isEmpty(getUser())) return false;

		let pt = this.props.location.pathname;

		// skip rendering footer for certain keywords
		const exclKeywords = [
			"login",
			"signup",
			"landing",
			"snip",
			"Z",
			"1",
			"P",
		];

		for (let ki of exclKeywords) {
			let regex = new RegExp(`${ki}.*`);
			if (Boolean(pt.match(regex))) return false;
		}

		return true;
	}

	componentDidMount() {
		const subDomain = getSubDomain();

		// dont render chatbot for subdomains
		if (this.shouldShowFooter() && isEmpty(subDomain)) {
			const script = document.createElement("script");
			script.id = "hs-script-loader";
			script.src = "//js.hs-scripts.com/7928716.js";
			script.async = true;
			document.body.appendChild(script);
		}
	}

	render() {
		const subDomain = getSubDomain();

		return (
			<div>
				{this.shouldShowFooter() && (
					<footer className="footer theme-bg-primary">
						{subDomain ? (
							<SubDomainFooter />
						) : (
							<div className="container pt-5">
								<div className="row mb-5">
									<div className="footer-col col-6 col-lg-3">
										<h4 className="col-heading">
											About ZDOC
										</h4>
										<ul className="list-unstyled">
											<li>
												<Link to="/features">
													Features
												</Link>
											</li>
											<li>
												<Link to="/pricing">
													Pricing
												</Link>
											</li>
											<li>
												<Link to="/contact">
													Contact
												</Link>
											</li>
											<li>
												<Link to="/jobs">Jobs</Link>
											</li>
										</ul>
									</div>
									{/*//footer-col*/}
									<div className="footer-col col-6 col-lg-3">
										<h4 className="col-heading">
											Resources
										</h4>
										<ul className="list-unstyled">
											<li>
												<Link to="/faq">FAQs</Link>
											</li>
											<li>
												<a href="#!">Blog</a>
											</li>
											<li>
												<a href="https://zdoc.helpsite.com/">
													Help
												</a>
											</li>
										</ul>
									</div>
									{/*//footer-col*/}
									<div className="footer-col col-6 col-lg-3">
										<h4 className="col-heading">Legal</h4>
										<ul className="list-unstyled">
											<li>
												<Link to="/privacy">
													Privacy
												</Link>
											</li>
											<li>
												<Link to="/terms">
													Terms of Services
												</Link>
											</li>
										</ul>
									</div>
									{/*//footer-col*/}
									<div className="footer-col col-6 col-lg-3">
										<h4 className="col-heading">
											Stay Ahead
										</h4>
										<ul className="social-list list-unstyled mb-0">
											<li className="list-inline-item">
												<a href="#!">
													<i className="fab fa-instagram fa-fw" />
												</a>
											</li>
											<li className="list-inline-item">
												<a href="#!">
													<i className="fab fa-twitter fa-fw" />
												</a>
											</li>
											<li className="list-inline-item">
												<a href="#!">
													<i className="fab fa-facebook-f fa-fw" />
												</a>
											</li>
											<li className="list-inline-item">
												<a href="#!">
													<i className="fab fa-youtube fa-fw" />
												</a>
											</li>
										</ul>
										{/*//social-list*/}
										<div className="mb-2">
											ZDOC
											<br />
											Forum Mall, 4th Floor, No 22, <br />
											Salarpuria Towers-I, Hosur Road,{" "}
											<br />
											Koramangala, Bengaluru, 560095
										</div>
										<div>
											<a href="mailto:hello@zdoc.in">
												hello@zdoc.in
											</a>
										</div>
									</div>
									{/*//footer-col*/}
								</div>
								{/*//row*/}
								{/*//container*/}
								<div className="container">
									<hr />
								</div>
								<div className="download-area py-4">
									<div className="container text-center">
										<h3 className="mb-3">Mobile App</h3>
										<div className="section-intro mb-4 single-col-max-width mx-auto">
											Manage appointments, medical
											records, prescriptions,
											notifications and more. Coming
											soon...
										</div>
										<ul className="app-stores list-unstyled list-inline mx-auto  d-inline-block">
											<li className="list-inline-item mr-3">
												<a href="#!">
													<img
														className="ios"
														src="/assets/images/appstore-apple.svg"
														alt="app-store"
													/>
												</a>
											</li>
											<li className="list-inline-item">
												<a href="#!">
													<img
														className="android"
														src="/assets/images/appstore-android.svg"
														alt="google play"
													/>
												</a>
											</li>
										</ul>
									</div>
									{/*//container*/}
								</div>
								{/*//download-area*/}
								<div className="footer-bottom text-center pb-4">
									<small className="copyright">
										Copyright ©{" 2020"}
										<a href="https://zdoc.in">
											{" "}
											Zdoc Technologies
										</a>
									</small>
								</div>
							</div>
						)}
					</footer>
				)}
			</div>
		);
	}
}

export default withRouter(Footer);
