import React, { Component } from "react";
import { getDomainName } from "utils";
import config from "data/config";
import FormElement from "components/Form/FormElement";

export default class ConfigureSubDomain extends Component {
    render() {
        const { validator, subDomain: sub_domain } = this.props;

        const siteUrl = getDomainName(config.SITE_URL, true);

        return (
            <div>
                <div className="row">
                    <label htmlFor="" className="col-10 text-muted">
                        Zdoc URL:
                    </label>
                    <div className="col-10 input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text theme-bg-primary">
                                https://
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="your_clinic_name"
                            value={sub_domain || ""}
                            onChange={(e) =>
                                this.props.onChange(e.target.value.trim())
                            }
                        />
                        <div className="input-group-append">
                            <span className="input-group-text theme-bg-primary">{`.${siteUrl}`}</span>
                        </div>
                    </div>
                </div>
                {validator.message(
                    "zdoc url",
                    sub_domain,
                    "required|alpha_num|min:4"
                )}

                <div className="theme-bg-primary px-3 py-2 text-muted col-11">
                    {!sub_domain ? (
                        <div className="legal-note mb-1">
                            {`Note: Your Zdoc website will appear at this URL.`}
                        </div>
                    ) : (
                        <div>
                            <div className=" mb-1">
                                Your dedicated Zdoc website URL:{" "}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://${sub_domain}.${siteUrl}/`}
                                >{`https://${sub_domain}.${siteUrl}/`}</a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
