import React, { Component } from "react";
import { SiteLogo } from "components/logo";
import ConfigureSubDomain from "page/vendor/settings/subDomain";
import SimpleReactValidator from "simple-react-validator";
import ManageLiConsultation from "page/vendor/listing/details/consultation";
import FormElement from "components/Form/FormElement";
import Button from "components/Form/Button";
import { getUser } from "utils";
import { setWebsiteDetails, createPractice } from "./network";
import { Alert } from "reactstrap";
import { GQL_ERROR_DUPLICATE } from "utils/HasuraParser";
import * as qs from "query-string";
import { gaEvent } from "utils/GAUtil";

class VendorOnboarding extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validator = new SimpleReactValidator({});

        this.state = {
            name: "",
            sub_domain: null,
            updating: false,
            success: false,
            error: null,
        };
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onFormSubmit() {
        if (this.validator.allValid()) {
            const {
                name,
                sub_domain,
                timezone = null,
                consultations = null,
            } = this.state;
            const u = getUser();

            const header = `<h2><strong>Welcome to ${name}</strong></h2><p>&nbsp;</p><p>At our institution, we practice patient-centred care and believe in treating patients like guests. This coupled with advanced technology, medicine, modern infrastructure, internationally certified consultant network and a host of other qualities make us the preferred destination for our patients.</p>`;

            this.setState({ error: null });

            setWebsiteDetails(name, header, sub_domain)
                .then((r) => {
                    console.log(r);
                    createPractice({
                        name,
                        drName: `Dr. ${u.first_name} ${u.last_name}`,
                        timezone,
                        consultations,
                    })
                        .then((r) => {
                            gaEvent({
                                category: "Vendor-Onboarding",
                                action: "Onboarding-Complete"
                            });
                            this.props.history.push("/clinic/onboarding?d=1")
                            //this.props.history.push("/app")
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => {
                    this.setState({
                        error:
                            err === GQL_ERROR_DUPLICATE
                                ? "This subdomain is already taken."
                                : "Sorry! Something went wrong. Please try again later.",
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
        return false;
    }

    render() {
        const isFromDashboard = qs.parse(this.props.location.search).d;
        const { name, sub_domain, updating, success, error } = this.state;
        this.validator.purgeFields();
        console.log(getUser());

        return (
            <div>
                {isFromDashboard ? (
                    <div className="p-5 mt-5 container text-center">
                        <p className="lead text-muted">
                            Your details are under verification. We will get in
                            touch with you soon for the activation.
                        </p>
                        <p className=" text-muted">
                            Please write to us at hello@zdoc.in if you have any
                            other queries.
                        </p>
                    </div>
                ) : (
                    <div className="theme-bg-primary">
                        <div className="signup-section auth-section">
                            <div className="row m-0">
                                <div className="bg-white col-12 text-center p-5">
                                    <h1 className="site-logo mb-3">
                                        <SiteLogo />
                                    </h1>
                                    <div className="w-75 mx-auto">
                                        <h2 className="auth-heading pt-2 pb-5">
                                            Just a couple of things, and you are
                                            all set!
                                        </h2>
                                        <div className="auth-form-container text-left mx-auto">
                                            <FormElement
                                                className="pb-3"
                                                label="Clinic Name"
                                                placeHolder="ex: Happy Clincs"
                                                name="name"
                                                value={name}
                                                onChange={this.onChange}
                                                validator={this.validator}
                                                validation="required"
                                            />

                                            <ConfigureSubDomain
                                                validator={this.validator}
                                                subDomain={sub_domain}
                                                onChange={(sub_domain) =>
                                                    this.setState({
                                                        sub_domain,
                                                    })
                                                }
                                            />
                                            <div className="legal-note mb-5 text-light ">
                                                You can configure a custom
                                                domain later.
                                            </div>

                                            <h5>Add Consultations offered</h5>
                                            <div>
                                                <ManageLiConsultation
                                                    validator={this.validator}
                                                    onUpdate={(val) =>
                                                        this.setState(val)
                                                    }
                                                />
                                            </div>
                                        </div>

                                        {error && (
                                            <Alert
                                                className="mt-4"
                                                color="danger"
                                            >
                                                {error}
                                            </Alert>
                                        )}

                                        <div className="my-5 pt-3">
                                            <Button
                                                btnCls="btn-outline-primary px-5 theme-btn"
                                                value="Continue"
                                                onClick={this.onFormSubmit}
                                                loading={updating}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default VendorOnboarding;
