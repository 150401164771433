import React, { Component } from "react";

export default class PubTerms extends Component {
    render() {
        return (
            <div>
                <div className="page-header py-5">
                    <div className="container">
                        <h3 className="mb-5">Terms of Use</h3>
                        <div>
                            <p>
                                ZDOC Technologies (hereafter referred to as
                                &ldquo;ZDOC&rdquo;), develops and operates an
                                online healthcare platform for healthcare
                                service providers to manage their services and
                                engage with their patients, hereafter referred
                                to as the &lsquo;ZDOC Platform Services&rsquo;.
                                &ldquo;We&rdquo;, &ldquo;our&rdquo; and
                                &ldquo;us&rdquo; currently refers to ZDOC in the
                                terms defined below. These Terms are between
                                ZDOC and its Customers, hereafter referred to as
                                &ldquo;you&rdquo; or collectively as
                                &ldquo;Customers&rdquo;.
                            </p>
                            <p>
                                The ZDOC Platform Services supports the goal of
                                continuous care for health by providing
                                healthcare service providers who are our
                                Customers, with a Virtual Practice (involving
                                associated mobile and web applications and
                                hereafter collectively referred to as the
                                &lsquo;Virtual Practice&rsquo;) that enables
                                them to be securely accessible to their
                                patients, through web and mobile services
                                enabling online consultations and remote patient
                                monitoring. The Virtual Practice applications
                                are intended only as tools, which help
                                healthcare providers and their patients to
                                collaborate in the healthcare of the patients,
                                using communication, scheduling, administrative
                                and data managing capabilities. The Virtual
                                Practice also enables online presence management
                                for better patient engagement.
                            </p>
                            <p>
                                If you do not agree to these Terms of Use and/or
                                are not eligible or authorized to agree to these
                                Terms of Use, then you may not register for and
                                setup a Virtual Practice on the ZDOC platform
                                and are not authorized to use any of the
                                applications or services supported by
                                ZDOC&rsquo;s ZDOC Platform.
                            </p>
                            <h4 id="customer-id">Customers</h4>
                            <p>
                                Healthcare service providers can set up a
                                Virtual Practice on the ZDOC platform (hereafter
                                referred to as &lsquo;Customers&rsquo;). If a
                                Virtual Practice is being set up by an
                                individual healthcare service provider who is
                                not formally affiliated with an organization,
                                the Customer is the individual creating the
                                Virtual Practice. If the Virtual Practice is
                                being set up for a healthcare services
                                organization by an authorized representative,
                                the organization is the Customer.
                            </p>
                            <h5 id="authUser-id">Authorized Users</h5>
                            <p>
                                Customers can create authorized user accounts
                                for their team members who may be employees
                                and/or associates (hereafter referred to as
                                &lsquo;Team Users&rsquo;), to log in to and use
                                their Virtual Practice through appropriate
                                interfaces. The Primary User Account of a
                                Customer&rsquo;s Virtual Practice, created when
                                the Virtual Practice is registered and setup
                                also counts as a Team User account. All Team
                                Users are directly bound by our Authorized User
                                Terms.
                            </p>
                            <p>
                                Customers can also create authorized user
                                accounts for their patients and/or healthcare
                                service clients (hereafter referred to as
                                &lsquo;Patient Users&rsquo;) to log in to and
                                use their Virtual Practice through appropriate
                                interfaces. Some Customers may choose to
                                authorize self-registration of Patient Users in
                                their Virtual Practice. All Patient Users are
                                directly bound by our Authorized User Terms.
                            </p>
                            <p>
                                Registering for and setting up a Virtual
                                Practice (and all its integral apps) requires
                                full acceptance of these Terms of Use and all
                                associated policies. By registering, you agree
                                that:
                            </p>
                            <p>
                                You are a licensed healthcare provider and/or
                                authorized representative of a licensed
                                healthcare organization with the necessary
                                healthcare practice licence and legal approvals
                                to provide healthcare services to patients that
                                you will provide services to through your
                                Virtual Practice.
                            </p>
                            <p>
                                You shall not register and use ZDOC&rsquo;s
                                Virtual Practice product (including associated
                                web and mobile apps) and any other of the ZDOC
                                Platform Services for any purpose other than
                                providing services to authorized Patients Users
                                seeking your services.
                            </p>
                            <p>
                                You take complete and sole responsibility in
                                assigning authorized Team User accounts only to
                                licensed healthcare providers and qualified
                                healthcare support professionals in roles
                                suitable to their area of responsibility; and
                                you take complete responsibility in training
                                your authorized Team Users in the correct usage
                                of the application and its features to provide
                                services to patients of your Virtual Practice.
                            </p>
                            <p>
                                You are not impersonating any other person and
                                are using your actual identity.
                            </p>
                            <p>
                                The information provided during the registration
                                process is true, accurate, current and complete.
                                You will be required to provide your full name,
                                email address, mobile phone number, location,
                                business name and address.
                            </p>
                            <p>
                                You are required to periodically review and
                                update your registration data to ensure that it
                                is always current and correct.
                            </p>
                            <p>
                                The user account created when registering for a
                                Virtual Practice is considered as the Virtual
                                Practice Primary Account and you are authorized
                                to use it.
                            </p>
                            <p>
                                You are responsible for ensuring that all
                                Authorized Users in your Virtual Practice are
                                aware of and bound by these Terms of Use.
                            </p>
                            <p>
                                Usage of the ZDOC Platform Services by Customers
                                and their authorized Team Users and Patient
                                Users are subject to acceptance of these Terms
                                of Use. These Terms of Use are deemed to include
                                the&nbsp;
                                <a
                                    href="https://zdoc.in/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                , Authorized User Terms, Acceptable Usage Policy
                                and all conditions, policies and operating
                                procedures that are referred to herein or which
                                may otherwise be published by ZDOC from time to
                                time, on this website and in any of the ZDOC
                                platform applications.
                            </p>
                            <h4>Customer Data</h4>
                            <p>
                                Authorized Users created by a Customer in their
                                Virtual Practice may submit information and
                                content to the Continuous Platform Services,
                                such as PHI (patient health information),
                                healthcare services related data, communications
                                and usage data, all of which qualifies as
                                &lsquo;Customer Data&rsquo; and is owned by the
                                Customer.
                            </p>
                            <p>
                                By using your Virtual Practice and associated
                                applications, your Patient Users understand that
                                the healthcare providers in the Virtual Practice
                                have access to view and update their healthcare
                                data in the course of providing them with
                                healthcare services. As a healthcare services
                                provider you authorize the access of patient
                                health data by your registered patients, such
                                that they may view and update their own health
                                data, except for specific areas that you choose
                                not to make visible/accessible to them.
                                Ownership of and responsibility for healthcare
                                data of patients in a Virtual Practice rests
                                with the Customer.
                            </p>
                            <p>
                                As owner and controller of the Customer Data,
                                the Customer alone may provide us with
                                instructions on what to do with this data. All
                                Customer Data is covered by the ZDOC
                                platform&rsquo;s&nbsp;
                                <a
                                    href="https://zdoc.in/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    &nbsp;Privacy Policy
                                </a>
                                .
                            </p>
                            <p>
                                By using the ZDOC platform you agree to storage
                                of your data in secure cloud infrastructure
                                located anywhere in the world. ZDOC currently
                                uses Amazon Web Services cloud infrastructure
                                based in the United States of America.
                            </p>
                            <h4 id="authorisedTerms">Authorized User Terms</h4>
                            <p>
                                Patient Users and Team Users of a
                                Customer&rsquo;s Virtual Practice cannot
                                directly register as users with the ZDOC
                                platform but are created as authorized users of
                                a Customer&rsquo;s Virtual Practice. The
                                Customer is directly registered with the ZDOC
                                platform and is bound by the Terms of Use,
                                including these Authorized Terms of Use.
                            </p>
                            <p>
                                These Authorized User Terms are between ZDOC and
                                all Authorized Users in Virtual Practices of
                                Customers, hereafter referred to as
                                &ldquo;you&rdquo;, &lsquo;&rsquo;they&rdquo; or
                                collectively as &ldquo;Authorized Users&rdquo;.
                            </p>
                            <p>
                                The Customer is responsible for ensuring that
                                all Authorized Users in their Virtual Practice
                                are aware of and bound by these Authorized Terms
                                of Use.
                            </p>
                            <p>
                                Authorized Users are aware that the Customer
                                owning the Virtual Practice in which they are
                                users has the right to remove and/or restrict
                                their access to the system.
                            </p>
                            <p>
                                The Customer has complete control over the
                                settings and configuration of the Virtual
                                Practice and bears responsibility for how it is
                                used.
                            </p>
                            <p>
                                Customer will inform Authorized Users (both Team
                                Users and Patient Users) of all Customer terms
                                and practices that are relevant to their use of
                                the Virtual Practice and of any settings that
                                may impact the processing of Customer Data.
                            </p>
                            <p>
                                All engagement between Team Users and Patient
                                Users conducted through the Customer&rsquo;s
                                Virtual Practice are also governed by the
                                Patient Terms of that Virtual Practice, which
                                authorized Patient Users are required to consent
                                to.
                            </p>
                            <p>
                                The Customer is also responsible for ensuring
                                that processing of Customer Data in the
                                Customer&rsquo;s Virtual Practice is done as per
                                necessary regulation and law. This also applies
                                to the deletion and export of Customer Data.
                            </p>
                            <p>
                                The Customer is solely responsible for deciding
                                which Team User accounts can be added to their
                                Virtual Practice based on eligibility criteria
                                defined by the Customer and to define the scope
                                of healthcare and/or support services that they
                                are allowed to provide to Patient Users through
                                the Virtual Practice; and also to define their
                                information access privileges.
                            </p>
                            <p>
                                All Authorized Users must of legal age necessary
                                to operate your user account in the role
                                intended by the Customer when authorizing you to
                                use their Virtual Practice. If you are a Team
                                User, you must also be of legal working age and
                                possess the necessary licence and qualifications
                                for your role.
                            </p>
                            <p>
                                ZDOC reserves the right to deny or revoke access
                                to anyone to a registered Virtual Practice, or
                                to any part of its ZDOC platform thereof, at any
                                time in its sole discretion, if there is
                                perceived to be a violation of any terms of use.
                            </p>
                            <p>
                                ZDOC does not provide any healthcare services
                                for Patient Users of any Customer, nor is it
                                liable in any way for healthcare services
                                provided by Customers through their Virtual
                                Practice to their authorized Patients Users.
                            </p>
                            <p>
                                All Customers and Authorized Users are bound by
                                the Acceptable Usage Policy defined in this
                                document.
                            </p>
                            <h4 id="acceptablePolicy">
                                Acceptable Usage Policy
                            </h4>
                            <p>
                                This Acceptable Use Policy sets out a list of
                                acceptable and unacceptable conduct for our ZDOC
                                Platform Services by Customers and Authorized
                                Users in the Virtual Practices of our Customers.
                            </p>
                            <p>
                                Any violation of the policy could result in the
                                suspension of your access to the Platform
                                Services and if found to be deliberate, repeated
                                and possibly harmful to other users of the
                                platform will terminatio of your access to the
                                ZDOC Platform Services. This policy may change
                                as the platform evolves, so please proactively
                                look up updates to the policy.
                            </p>
                            <p>
                                Your username and password are unique to you,
                                and you agree not to disclose or share your
                                username and password to or with any third
                                party. You are responsible for keeping your
                                password confidential and for notifying us
                                immediately if your password has been hacked or
                                stolen.
                            </p>
                            <p>
                                You also agree that you will be solely
                                responsible for any activities conducted on or
                                through the ZDOC Platform Services with your
                                account regardless of whether or not you are the
                                individual who undertakes such activities. This
                                includes any unauthorized access and/or use of
                                your account or your computer. You hereby
                                release and hold harmless ZDOC from any and all
                                claims and causes of action arising out of or
                                resulting from a third party&rsquo;s
                                unauthorized use of your account.
                            </p>
                            <p>
                                Your use and access of the ZDOC platform, its
                                apps and services, is entirely at your own
                                initiative and risk. You are solely responsible
                                for compliance with local laws in your
                                respective country with regard to the use of
                                such services. ZDOC does not claim that the ZDOC
                                Platform Services are fully compliant for use
                                within all the countries and territories from
                                where it may be accessed.
                            </p>
                            <p>
                                From time to time, some of our users do provide
                                us with feedback on recommended changes and/or
                                corrections to our platform and apps. Such
                                recommendations are sometimes accepted if they
                                fit in with our overall product roadmap. If you
                                provide such feedback, you warrant that it not
                                include any person or entity&rsquo;s proprietary
                                information. You also fully agree that ZDOC is
                                not obliged to use or act on this feedback and
                                even if does, it has full, royalty-free,
                                perpetual, irrevocable, transferable and global
                                license to use this feedback.
                            </p>
                            <p>
                                ZDOC is constantly updating the ZDOC platform
                                and apps in order to provide the best possible
                                experience for its users. All users acknowledge
                                and agree that the form and nature of the
                                services provided by the platform may change
                                from time to time, features may be added,
                                improved or changed without prior notice as per
                                the demands of changing technology, domain and
                                the best interests of the users.
                            </p>
                            <p>
                                You shall not use the ZDOC platform and its apps
                                and services for any purposes other than those
                                intended by ZDOC and as determined by ZDOC at
                                its sole discretion.
                            </p>
                            <p>
                                You shall not attempt to circumvent any security
                                measure put in place for the ZDOC platform users
                                or attempt to gain unauthorized access to any
                                services, user accounts, or computer systems or
                                networks owned and/or controlled by ZDOC,
                                through hacking, password mining or any other
                                means.
                            </p>
                            <p>
                                You shall not upload or submit any data or
                                information containing viruses or any computer
                                code, corrupt files or programs engineered or
                                intended to disrupt or destroy the functionality
                                of any software, hardware, telecommunications,
                                networks, servers or other equipment.
                            </p>
                            <p>
                                You shall not attempt to access, access or
                                obtain information regarding any account other
                                than your own, by any means whatsoever. You may
                                not use the ZDOC platform and its apps and
                                services in any manner that could damage,
                                disable, overload, or impair our servers or
                                networks, or interfere with any other
                                party&rsquo;s use and enjoyment of the site and
                                services.
                            </p>
                            <p>
                                You may not use any robot, spider, scraper or
                                other automated means to access the ZDOC
                                Platform Services or website for any purpose
                                without our written permission. Violation of
                                this policy may result in termination of your
                                access to the site, deactivation or deletion of
                                your registration and all related information
                                and files you have stored on the site.
                            </p>
                            <p>
                                If you are a competitor service, you shall not
                                use this service as a user to study and copy
                                features and services provided by ZDOC&rsquo;s
                                ZDOC platform, apps and services.
                            </p>
                            <p>
                                In order to protect the integrity of its ZDOC
                                website and Platform Services, ZDOC reserves the
                                right at any time in its sole discretion to
                                block users from certain IP addresses.
                            </p>
                            <p>
                                You are responsible for the information you
                                voluntarily post and agree to defend (at your
                                sole expense), indemnify and hold ZDOC harmless
                                from any damages, losses, costs, or expenses
                                which ZDOC may incur as a result of information
                                you post.
                            </p>
                            <p>
                                You are solely responsible for exporting and/or
                                requesting the export of all your Customer Data
                                before expiry or cancellation of your Virtual
                                Practice account, should you choose to
                                discontinue using it and/or should your account
                                be terminated.
                            </p>
                            <p>
                                We may offer certain features to you at no
                                charge or a special reduced charge, for beta
                                trial use. Your use of beta features is subject
                                to any additional terms that we specify when
                                enabling them for you. Use of such features is
                                permitted only during the specified duration
                                and/or when the feature is terminated. Such
                                features are not guaranteed to be released and
                                may on occasion be terminated. Such features are
                                generally still under development and/or not
                                completely integrated into the platform and
                                therefore may contain more issues or errors than
                                is generally noted in the product. Should you
                                choose not to subscribe to the plans under which
                                the Beta Features will be eventually supported,
                                or if we choose to terminate such Beta Features,
                                you will lose access to content created as a
                                result of using such Beta Features. We disclaim
                                all obligations and liabilities with respect to
                                such features. The information about such Beta
                                features and their capabilities are limited to
                                customers to whom they are made specially
                                available and this is covered by the
                                confidentiality clause in our Terms of Use.
                            </p>
                            <h4>Customer Responsibilities</h4>
                            <p>
                                In addition to the above general terms of
                                acceptable use, the following responsibilities
                                apply to all Customers and their Team Users.
                            </p>
                            <p>
                                The Customer is responsible for all the settings
                                and configuration applied in the Virtual
                                Practice, including management of user accounts,
                                configuration of services and provision of those
                                services and management of Customer Data, which
                                is impacted by such configuration.
                            </p>
                            <p>
                                You are completely responsible for the Customer
                                Data in your Virtual Practice including ensuring
                                the usage practices by you and your Team Users
                                in maintaining the privacy and confidentiality
                                of your patients&rsquo; healthcare data in your
                                Virtual Practice.
                            </p>
                            <p>
                                You will comply with healthcare and data
                                regulation rules applicable to healthcare
                                providers and organizations in your country of
                                business and locations of service. You are
                                solely responsible for choosing to use the
                                Virtual Practice and Platform Services after
                                ascertaining that it meets the necessary
                                regulatory compliance applicable to your
                                operations.
                            </p>
                            <p>
                                You will not publish or distribute in any form,
                                any patient healthcare information without their
                                informed consent. ZDOC is not liable for your
                                handling of your own data and nor for any
                                outcomes as a result of that.
                            </p>
                            <p>
                                The Customer will be responsible for all the
                                activities in your Virtual Practice account and
                                in the authorized Team User accounts of your
                                employees and/or associates and Patient Users.
                            </p>
                            <p>
                                The ZDOC Platform serves only as a technology
                                enabler for the online healthcare services that
                                you choose to provide. Any disputes between you
                                and the Patient Users of your Virtual Practice
                                will be dealt with by you directly. ZDOC has no
                                obligation or liability to monitor services
                                within Virtual Practices.
                            </p>
                            <p>
                                ZDOC takes no responsibility for healthcare
                                and/or other information that may be published
                                by healthcare providers in their own Virtual
                                Practice. Healthcare providers are completely
                                liable to ensure that such content is medically
                                correct, original and that it causes no intended
                                or unintended harm to its readers. ZDOC retains
                                the right to review and delete any published
                                content related to any provider, if such content
                                is brought to ZDOC&rsquo;s notice. ZDOC takes no
                                responsibility in having to review content
                                published in Virtual Practices.
                            </p>
                            <p>
                                The Customer is solely responsible for the
                                setup, payment, maintenance and security of
                                their own app store accounts with Google Play
                                and Apple iTunes, should they opt to publish
                                their own custom mobile apps for their Virtual
                                Practice. ZDOC bears no responsibility for any
                                change in app store policies that may impact the
                                publishing of the Customer&rsquo;s custom mobile
                                application/s.
                            </p>
                            <p>
                                You unconditionally agree to receive certain
                                essential automated SMS, mobile and email
                                notifications relevant to system events from the
                                ZDOC Platform Services apps to the mobile number
                                and email address associated with your user
                                account, irrespective of whether you have
                                subscribed to any Do-Not-Disturb (DND) services.
                            </p>
                            <p>
                                All Patient Users of a Virtual Practice of a
                                Customer are to be made aware of the following
                                by the Customer and Team Users and through
                                Patient Terms defined in the Virtual Practice:
                            </p>
                            <p>
                                Patient Users of a Virtual Practice shall not
                                use any service or information in their
                                healthcare provider&rsquo;s Virtual Practice for
                                any purposes other than managing their personal
                                health information or that of their immediate
                                family members (with due authorization) and/or
                                to engage with healthcare providers in the
                                Virtual Practice.
                            </p>
                            <p>
                                ZDOC does not provide medical services of any
                                kind and as a technology provider only enables
                                engagement with you their healthcare provider,
                                through the Virtual Practice, which you the
                                Customer have opted to use.
                            </p>
                            <p>
                                ZDOC does not recommend or endorse any
                                healthcare providers or services using the
                                Virtual Practice.
                            </p>
                            <p>
                                Patient Users&rsquo; access or use of their
                                healthcare provider&rsquo;s Virtual Practice
                                and/or any apps and services of the ZDOC
                                platform does not create in any way, a
                                relationship that may be confidential or
                                privileged or of any kind that would obligate
                                ZDOC, to fulfil any duties towards meeting their
                                health needs.
                            </p>
                            <p>
                                Patient users are expected to avail of immediate
                                professional medical attention in medical
                                emergencies or critical care situations and not
                                attempt to use the Virtual Practice and/or other
                                apps and services of the ContinousCare platform
                                in such situations.
                            </p>
                            <p>
                                The accuracy, integrity and completeness of the
                                personal health information that your Patient
                                Users enter or allow to be entered on their
                                behalf is solely their responsibility, and ZDOC
                                assumes no liability for it. ZDOC is also not
                                liable for any use or misuse of their health
                                data by you their healthcare provider.
                            </p>
                            <p>
                                ZDOC shall not be responsible for any
                                undesirable outcomes in their engagement with
                                you their healthcare provider.
                            </p>
                            <p>
                                ZDOC shall not be responsible for any health,
                                fitness or nutritional information that is
                                published by you their healthcare through your
                                Virtual Practice.
                            </p>
                            <p>
                                All such data in the Virtual Practice of their
                                healthcare provider is owned by the Customer who
                                is solely responsible for defining how that data
                                is handled and/or made accessible to Patient
                                Users in the Virtual Practice.
                            </p>
                            <p>
                                Patient users in a Virtual Practice should also
                                expect to receive essential system notifications
                                related to healthcare service events.
                                Notification preference settings will be
                                provided to allow turning off of
                                non-essential/informative-only notifications.
                            </p>
                            <h4>Subscriptions &amp; Payments</h4>
                            <p>
                                All use of a Virtual Practice by a Customer and
                                their Authorized Users are based on subscription
                                to a relevant Subscription Plan for the ZDOC
                                Platform Services. A Subscription Plan defines
                                the scope and level of usage of the Platform
                                Services by the Customer and their Authorized
                                Users .The ZDOC Platform Services also provides
                                add-on services to the Virtual Practice, that
                                the Customer can optionally purchase and use.
                            </p>
                            <p>
                                When you register for a free trial of the
                                Virtual Practice, the service will be available
                                to you free of charge until the end of the free
                                trial period or on termination of your trial for
                                any reason at our discretion. Unless you
                                purchase a subscription within expiry of your
                                trial, your Virtual Practice account will be
                                deactivated.
                            </p>
                            <p>
                                The following platform services are currently
                                provided free of charge but ZDOC reserves the
                                right to charging for them at cost based on
                                actual usage, at any point of its choosing.
                            </p>
                            <p>SMS and Mobile Notifications</p>
                            <p>Data Storage Charges (free up to 1GB)</p>
                            <p>Video Streaming Services</p>
                            <p>
                                Note: SMS and Video Streaming Services costs
                                will be charged based on actual usage.
                            </p>
                            <p>
                                Subscriptions and other payments are be managed
                                and paid for online through the Virtual Practice
                                Subscription interface by the Customer, who can
                                choose to subscribe on a monthly or annual plan.
                                In some cases subscriptions are purchased by
                                Customers through other subscription order
                                mechanisms. Though all optional add-ons to the
                                Virtual Practice require an active Virtual
                                Practice subscription to function, the
                                subscription period of add-on itself, is
                                independent of the subscription period of the
                                associated Virtual Practice, and depends on when
                                the add-on was purchased. Any unexpected change
                                in app store policies affecting the ability to
                                publish custom mobile apps in the
                                Customer&rsquo;s account will not result in a
                                refund in the subscription charge associated
                                with the custom mobile app.
                            </p>
                            <p>
                                All subscriptions automatically renew at the end
                                of the billing cycle for the upcoming
                                subscription periods equal to the preceding
                                subscription period; and the per Team User
                                pricing during any automatic renewal term will
                                be based on the number of active Team User
                                accounts at the point of renewal. All
                                subscription payments are paid forward for the
                                upcoming subscription term. Failure to pay the
                                subscription fees on time will result in your
                                Virtual Practice account being deactivated.
                                Deactivated accounts can be reactivated by
                                making the necessary subscription payment.
                            </p>
                            <p>
                                ZDOC reserves the right to change plans and
                                pricing at any time. The changes will be
                                notified either by email to all existing
                                subscribers and also on the website. If on a
                                paid plan, you can upgrade/downgrade your
                                account at any time. You can upgrade by paying
                                the incremental cost. If your account is
                                upgraded during a billing cycle the increased
                                charges will be calculated on pro-rata basis.
                                Downgrading of account can cause reduction in
                                features or loss of access to content in your
                                account and will not result in refunds. ZDOC
                                will not be responsible for operational
                                limitations due to downgrading of an account
                                subscription.
                            </p>
                            <p>
                                All information in your billing account should
                                be current and accurate. All billing related
                                mails will be sent to the email address that you
                                have provided. All charges collected for a
                                billing cycle are non-refundable, irrespective
                                of account cancellation, termination or
                                downgrade. Late payments will result in
                                suspension of service until payments are made.
                            </p>
                            <p>
                                Deactivated and/or cancelled Virtual Practice
                                accounts are maintained for a maximum of 30 days
                                before the account is deleted. Deletion of a
                                Virtual Practice account will include permanent
                                deletion of all Customer Data and configurations
                                in the account. We will make Customer Data
                                available to you for export or download, upon
                                request made by you within this 30 day period.
                                We have no obligation to maintain Customer Data
                                after this 30 day period. ZDOC is not
                                responsible for any loss or other consequences
                                caused by deactivation or deletion of a Virtual
                                Practice account.
                            </p>
                            <h4>Privacy Policy</h4>
                            <p>
                                All users of ZDOC&rsquo;s ZDOC Platform Services
                                are also bound by the
                                <a
                                    href="https://www.ZDOC.in/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    &nbsp;Privacy Policy
                                </a>
                                .
                            </p>
                            <h4>Third Party Services</h4>
                            <p>
                                ZDOC is not responsible for the accuracy and
                                reliability of health data ready from health
                                sensors and devices that the ZDOC platform and
                                apps may interface with. This includes direct
                                device interfaces and also account interfaces of
                                third-party services that you as the user might
                                authorize the platform apps to interface with.
                                Such interfaces are not indicative of any
                                endorsement or recommendation by ZDOC of those
                                devices or services.
                            </p>
                            <p>
                                ZDOC is also not responsible for the functioning
                                and reliability of any third-party services that
                                you may choose to interface with now or in the
                                future (from among authorized third-party
                                interfaces) to further the functionality of your
                                Virtual Practice. Terms of use applicable to
                                those services must be complied with, in
                                addition to ZDOC&rsquo;s own Terms of Use.
                            </p>
                            <p>
                                ZDOC&rsquo;s provisioning of a custom mobile
                                application for a Customer is solely limited to
                                the development, maintenance and submission to
                                the app store accounts (Google Play and/or Apple
                                iTunes) of the Customer. ZDOC is in no way
                                responsible for the setup, payment, maintenance
                                and security of the Customer&rsquo;s own app
                                store accounts beyond using it to submit
                                application builds for review. The Customer is
                                solely responsible for providing necessary
                                organization verification inputs and documents
                                to Apple and/or Google Pay as may be required by
                                them. ZDOC is also not liable for delays in app
                                publishing caused by the app store review
                                process. ZDOC&rsquo;s responsibilities during
                                the review process are specifically limited to
                                responding to any technical clarifications or
                                technical defects that may be raised during the
                                review process. ZDOC is also not responsible for
                                the app store listing policies of Apple iTunes
                                and Google Play.
                            </p>
                            <p>
                                ZDOC&rsquo;s ZDOC website and Platform Services
                                may include links to other websites, possibly in
                                content within the apps. Some of these sites may
                                contain materials that are objectionable,
                                unlawful, or inaccurate. These links are
                                provided for your convenience only. We do not
                                endorse these sites or the products and services
                                they provide. You acknowledge and agree that we
                                are not responsible or liable for the content or
                                accuracy of these sites external to ZDOC.
                            </p>
                            <p>
                                For customers using own email domains for
                                sending of email notifications from their
                                Virtual Practice, despite all relevant
                                configuration of DNS, SPF, DKIM and DMarc
                                settings, it can in rare cases be expected to
                                observe some delay or potentially even initial
                                delivery issues, due to the verification
                                policies and algorithms of certain email
                                services providers. This generally resolves
                                after a certain level of usage, after which the
                                emails are safely considered as non-spam. Beyond
                                ensuring correct sending of email notifications,
                                ZDOC does not bear responsibility for the last
                                point delivery of email notifications having no
                                control over this external environment factor.
                            </p>
                            <p>
                                Delivery of SMS messages depend on last mile
                                carrier networks (in the recipients location)
                                and there may be instances where messages
                                verified as delivered by SMS gateway services
                                may be reported to not have been received by the
                                end user recipient. ZDOC is responsible for
                                ensuring the correct sending of notifications
                                and supports multiple notification channels.
                                However ZDOC does not responsibility for the
                                last point delivery of SMS notifications having
                                no control over that factor. Customers are
                                required to opt to use/not use SMS notification
                                channels in their Virtual Practice according to
                                the needs of their users and the delivery
                                performance observed for their user base.
                            </p>
                            <p>
                                ZDOC is not responsible for non-accessibility of
                                the Customer&rsquo;s Virtual Practice caused by
                                wrong DNS settings made by the Customer.
                            </p>
                            <h4>Updates</h4>
                            <p>
                                ZDOC may change these Terms of Use (and any
                                associated Policies) at any time, at its sole
                                discretion, in whole or part. In the event of
                                any material or substantial change in the terms
                                and conditions ZDOC may notify you by posting an
                                announcement of the changes on its website,
                                through its applications or by email. You are
                                expected to periodically check these Terms of
                                Use and associated policies for changes. By
                                continuing to use the ZDOC Platform Services and
                                it&rsquo;s apps after any change to these Terms
                                of Use, you acknowledge and accept the changes.
                                The Terms of Use as they apply to you as a user,
                                may not otherwise by amended.
                            </p>
                            <h4>
                                Intellectual Property, Copyright and Trademarks
                            </h4>
                            <p>
                                ZDOC&rsquo;s ZDOC platform services,
                                applications and website, all content and
                                information, visual designs and branding created
                                by and for ZDOC, and all intellectual property
                                rights embodied therein, are the property of
                                ZDOC and are protected by trademarks,
                                copyrights, patents, proprietory rights and IP
                                laws.
                            </p>
                            <p>
                                ZDOC, ZDOC and the Virtual Practice are the
                                trademarks of ZDOC. ZDOC does not grant and
                                rights to copy, use, modify, reproduce, adapt,
                                distribute or create derived works of any part
                                of its ZDOC platform, apps, content, website and
                                design. Any reproduction of the contents, in
                                whole or in part, regardless of the procedure or
                                the medium used, shall require the express prior
                                written authorisation of ZDOC.
                            </p>
                            <p>
                                Attempting to use or access the ZDOC platform,
                                apps, services and website for any purposes
                                other than intended as per these Terms of Use is
                                prohibited. ZDOC reserves the right at any time
                                in its sole discretion to block and terminate
                                users violating these terms, in addition to
                                taking legal action where necessary.
                            </p>
                            <h4>Limited Warranty</h4>
                            <p>
                                To the extent allowed by applicable law, implied
                                warranties on the Software, ZDOC software and
                                services are provided without any warranty of
                                any kind, and ZDOC hereby disclaims all express
                                or implied warranties, including without
                                limitation warranties of merchantability,
                                fitness for a particular purpose, quality,
                                performance, accuracy, reliability, loss or
                                corruption of data, business interruption or any
                                other commercial damages or losses, arising out
                                of or related to the software. ZDOC makes no
                                warranty that the services will be available and
                                accessible uninterrupted or error-free or
                                otherwise meet your expectations. This
                                disclaimer of warranty constitutes an essential
                                part of this agreement.
                            </p>
                            <p>
                                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                                LAW, THE ABOVE WARRANTY IS EXCLUSIVE AND IN LIEU
                                OF ALL OTHER WARRANTIES, WHETHER EXPRESS OR
                                IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
                                MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                PURPOSE, AND NONINFRINGEMENT OF INTELLECTUAL
                                PROPERTY RIGHTS.
                            </p>
                            <h4>Limitation of Liability</h4>
                            <p>
                                THE USERS EXPRESSLY UNDERSTAND AND AGREE THAT
                                ZDOC AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS,
                                EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL
                                NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
                                INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
                                DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES
                                FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR
                                OTHER INTANGIBLE LOSSES (EVEN IF ZDOC HAS BEEN
                                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
                                RESULTING FROM: (i) THE USE OR THE INABILITY TO
                                USE THE SERVICE; (ii) UNAUTHORIZED ACCESS TO OR
                                ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iii)
                                STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE
                                SERVICE; OR (iv) ANY CHANGES WHICH ZDOC MAY MAKE
                                TO THE SERVICES (v) YOUR FAILURE TO KEEP YOUR
                                PASSWORD OR ACCOUNT DETAILS SECURE AND
                                CONFIDENTIAL;(vi) ANY OTHER MATTER RELATING TO
                                THE SERVICE.
                            </p>
                            <p>
                                THE LIMITATIONS ON ZDOC&rsquo;S LIABILITY TO THE
                                USER SHALL APPLY WHETHER OR NOT ZDOC HAS BEEN
                                ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE
                                POSSIBILITY OF ANY SUCH LOSSES ARISING.
                            </p>
                            <h4>Indemnification and Release</h4>
                            <p>
                                YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS
                                ZDOC, AND ITS OFFICERS, MANAGERS, MEMBERS,
                                DIRECTORS, EMPLOYEES, SUCCESSORS, ASSIGNS,
                                SUBSIDIARIES, AFFILIATES, SERVICE PROFESSIONALS,
                                SUPPLIERS, AND AGENTS, FROM AND AGAINST ANY AND
                                ALL CLAIMS, DAMAGES, OBLIGATIONS, LOSSES,
                                LIABILITIES, COSTS OR DEBT, AND EXPENSES
                                (INCLUDING BUT NOT LIMITED TO ATTORNEYS&rsquo;
                                FEES) ARISING FROM YOUR USE OF, ACCESS TO, AND
                                PARTICIPATION IN SERVICES PROVIDED BY THE
                                WEBSITE; YOUR VIOLATION OF ANY PROVISION OF THE
                                TERMS OF USE, INCLUDING THE PRIVACY POLICY; YOUR
                                VIOLATION OF ANY THIRD-PARTY RIGHT, INCLUDING
                                WITHOUT LIMITATION ANY COPYRIGHT, PROPERTY,
                                PROPRIETARY, INTELLECTUAL PROPERTY; OR ANY CLAIM
                                THAT YOUR SUBMITTED CONTENT HAS CAUSED DAMAGE TO
                                A THIRD PARTY. THIS DEFENSE AND INDEMNIFICATION
                                OBLIGATION WILL SURVIVE THE TERMS OF SERVICE AND
                                YOUR TERMINATION OF YOUR ACCOUNT.
                            </p>
                            <h4>Termination</h4>
                            <p>
                                These Terms of Use take effect from the time you
                                start using ZDOC&rsquo;s ZDOC Platform Services
                                in any capacity, until it is terminated by you
                                and/or ZDOC as provided below.
                            </p>
                            <p>
                                All your active free or paid subscriptions to
                                the Virtual Practice and/or any of the ZDOC
                                platform services expires due a cancelled
                                subscription and/or expiry of a subscription
                                that was not in auto-renewal mode.
                            </p>
                            <p>
                                ZDOC terminates your Virtual Practice and access
                                to the ZDOC platform services due to breach of
                                these Terms of Use by you and/or any Authorized
                                Users of your Virtual Practice and associated
                                Platform Services. Such termination may be with
                                immediate effect.
                            </p>
                            <p>
                                ZDOC reserves the right to modify and/or
                                discontinue its services at any time, for any
                                reason including but not limited to violation of
                                this agreement. In cases where the cause is not
                                due to violation of this agreement (Terms of
                                Use), at least thirty (30) days prior notice
                                will be provided.
                            </p>
                            <p>
                                On termination of your account, you will no
                                longer be able to use your Virtual Practice
                                account or any Platform Services of the
                                platform. The same will apply to Authorized
                                Users in your Virtual Practice. Any obligations
                                you may have prior to effective date of
                                termination must be met. Any termination does
                                not relieve the Customer of the obligation to
                                pay any fees payable to us for the period prior
                                to the effective date of termination.
                            </p>
                            <p>
                                In the case of terminations with cause, there
                                will be no refund of subscription payments, for
                                the period between date of termination and date
                                of expiry of the current subscription. ZDOC also
                                retains the right to pursue any action to remedy
                                breach of these Terms of Use.
                            </p>
                            <p>
                                During the active term or subscription of a
                                Virtual Practice, the Customer will be permitted
                                to export Customer Data from the Platform
                                Services; After termination or expiration of
                                Virtual Practice subscriptions, we will have no
                                obligation to maintain or provide any Customer
                                Data; unless legally required to maintain the
                                data for other reasons, we may delete all
                                Customer Data in our systems.
                            </p>
                            <h4>Assignment</h4>
                            <p>
                                Neither party may assign or delegate any of its
                                rights or obligations hereunder, whether by
                                operation of law or otherwise, without the prior
                                written consent of the other party (not to be
                                unreasonably withheld). Notwithstanding the
                                foregoing, either party may assign the Agreement
                                in its entirety (including all order forms),
                                without consent of the other party, to a
                                corporate affiliate or in connection with a
                                merger, acquisition, corporate reorganization,
                                or sale of all or substantially all of its
                                assets. Customer will keep its billing and
                                contact information current at all times by
                                notifying ZDOC of any changes. Any purported
                                assignment in violation of this section is void.
                                A party&rsquo;s sole remedy for any purported
                                assignment by the other party in breach of this
                                section will be, at the non-assigning
                                party&rsquo;s election, termination of the
                                Contract upon written notice to the assigning
                                party. Subject to the foregoing, this Agreement
                                will be binding upon, and inure to the benefit
                                of, the successors, representatives, and
                                permitted assigns of the parties.
                            </p>
                            <h4>Jurisdiction and Governing Law</h4>
                            <p>
                                These terms shall be governed by and construed
                                in accordance with the laws of India without
                                reference to conflict of laws principles, and
                                disputes arising in relation hereto shall be
                                subject to the exclusive jurisdiction of the
                                courts at Bangalore, India. These Terms of Use
                                and associated policies shall be admissable in
                                all legal proceedings. Use of the ZDOC platform
                                and its services is not authorized in any
                                territory of jurisdiction that does not give
                                effect to all provisions of these Terms of Use,
                                including this section.
                            </p>
                            <h4>Release for Force Majeure</h4>
                            <p>
                                ZDOC and its officers, directors, employees,
                                agents, content providers, customers and
                                suppliers shall be absolved from any claim of
                                damages resulting from any cause(s) over which
                                ZDOC or they do not have direct control,
                                including, but not limited to, failure of
                                electronic or mechanical equipment or
                                communication lines, telephone or other
                                interconnect problems, computer viruses or other
                                damaging code or data, unauthorized access,
                                theft, operator errors, severe weather,
                                earthquakes, natural disasters, strikes or other
                                labour problems, wars, or governmental
                                restrictions.
                            </p>
                            <h4>General Provisions</h4>
                            <p>
                                This Agreement contains the entire agreement and
                                understanding of the parties with respect to the
                                subject matter thereof, and supersedes all prior
                                written and oral understandings of the parties
                                with respect to the subject matter thereof. You
                                may not assign or sub-license any of the rights
                                and obligations under this Agreement without
                                prior written consent of ZDOC. Any notices in
                                this regard need to be delivered in written
                                format and acknowledged as received. ZDOC may
                                subcontract its responsibilities under this
                                Agreement, without your consent to a third party
                                considered by ZDOC in good faith to be of equal
                                standing and integrity provided that material
                                provisions of this Agreement shall be reflected
                                in any agreement entered into between ZDOC and
                                such third party. No partnership, joint venture,
                                agency or employment relationship is created as
                                a result of these Terms of Use, and neither
                                party has any authority of any kind to bind the
                                other in any respect. If, for any reason any
                                provision of this Agreement is held invalid or
                                otherwise unenforceable, such invalidity or
                                unenforceability shall not affect the remainder
                                of this Agreement, and this Agreement shall
                                continue in full force and effect to the fullest
                                extent allowed by law. The parties knowingly and
                                expressly consent to the foregoing terms and
                                conditions.
                            </p>
                        </div>
                    </div>
                    {/*//container*/}
                </div>
            </div>
        );
    }
}
