import React, { Component } from "react";
import { getUser, getUserRole, getSubDomain } from "utils";
import {
    A4P_VENDOR_ID,
    A4P_PAYMENT_TYPES,
    A4P_ROLE_VENDOR,
    APP_CURRENCY_SYMBOL,
    APP_CONVINIENCE_FEE,
} from "data/config/variables";
import moment from "moment-timezone";
import { processPayment } from "./network";
import RazorPayCheckout from "components/RazorPay";

export default class BookAppointmentPayment extends Component {
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);

        const {user} = this.props.info || {};
        this.state = {
            user: user || getUser(),
            pet: null,
            processing: false,
            subDomain: getSubDomain() || {},
            error: null,
            selectedDate: null,
            chargePayment: true,
        };
    }

    onFormSubmit(paymentId = null) {
        let { chargePayment, subDomain, user, pet } = this.state;
        const { listing, slot, info } = this.props;
        const { title, line1, line2, address, avatar } = listing;
        const { type, duration, price } = slot.service;

        const { first_name, last_name, pic, phone, email, id } = user;
        const totalPrice = parseInt(price) + APP_CONVINIENCE_FEE;

        const data = {
            paymentType: A4P_PAYMENT_TYPES.APPOINTMENTS,
            user_id: user.id,
            vendor_id: listing.vendor_id,
            vendor_listing_id: listing.id,
            paymentId,
            slot: slot.time,
            type,
            userTZ: slot.timezone,
            listingTZ: listing.timezone,
            price: chargePayment ? totalPrice : 0,
            taxes: 0,
            user: { first_name, last_name, pic, phone, email, id },
            pet,
            subDomain,
            details: {
                title,
                line1,
                line2,
                address,
                avatar,
                duration,
                price,
            },
            info,
            payment: {
                paymentId,
                price,
                internetFee: APP_CONVINIENCE_FEE,
                totalPrice,
            },
        };

        console.log(data);
        this.setState({ processing: true });
        processPayment(data)
            .then((r) => {
                console.log(r);
                if(r.status) {
                    this.setState({ processing: false });
                    this.props.onComplete(r.id);
                }
                else this.setState({ processing: false, error: "Something went wrong!" });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {        
        const { subDomain, error, chargePayment, user } = this.state;
        const { first_name, last_name, phone, email } = user;
        const { slot } = this.props;
        const { price } = slot.service;

        const totalPrice = parseInt(price) + APP_CONVINIENCE_FEE;
        const userRole = getUserRole();

        return (
            <div>
                <div className="my-3">
                    <p className="h6 text-muted">Payment:</p>
                </div>

                <div className="p-3 border rounded">
                    {error && <div className="alert alert-danger">{error}</div>}
                    {userRole === A4P_ROLE_VENDOR && (
                        <div className="my-2">
                            <div className="form-group custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="chargePayment"
                                    checked={chargePayment}
                                    onChange={(e) =>
                                        this.setState({
                                            chargePayment: !chargePayment,
                                        })
                                    }
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="chargePayment"
                                >
                                    {chargePayment
                                        ? "Enable online payment"
                                        : "Payment will be collected offline"}
                                </label>
                            </div>
                        </div>
                    )}
                    {chargePayment && (
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="form_title my-3">
                                    <h4>Checkout Details</h4>
                                </div>

                                <p>Item Descriptions:</p>
                                <div className="summary">
                                    <ul
                                        className="list-unstyled"
                                        style={{ lineHeight: "3em" }}
                                    >
                                        <li>
                                            Booking Cost:
                                            <strong className="float-right">
                                                {APP_CURRENCY_SYMBOL + price}
                                            </strong>
                                        </li>
                                        <li>
                                            Internet Handling Fee:
                                            <strong className="float-right">
                                                {APP_CURRENCY_SYMBOL +
                                                    APP_CONVINIENCE_FEE}
                                            </strong>
                                        </li>
                                        <li className="font-weight-bold">
                                            Total{" "}
                                            <strong className="float-right">
                                                {APP_CURRENCY_SYMBOL +
                                                    totalPrice}
                                            </strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mt-3 mb-1">
                        <RazorPayCheckout
                            amount={chargePayment ? totalPrice * 100 : 0}
                            actionTxt="Confirm and Pay"
                            brandName={subDomain.name || "ZDOC Technologies"}
                            brandLogo={subDomain.logo || "/assets/images/logo-icon.svg"}
                            name={`${first_name} ${last_name}`}
                            email={email || `${phone}@zdoc.in`}
                            phone={phone}
                            onComplete={(r) => {
                                console.log(r);
                                this.onFormSubmit(r.razorpay_payment_id);
                            }}
                        />
                    </div>
                    <small style={{ color: "#ccc" }}>
                        We do not store any payment details on our servers.
                    </small>
                </div>
            </div>
        );
    }
}
