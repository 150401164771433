import { gqlSend, apiSend } from "utils/AxiosUtil";

export const getVendorSite = ({ subDomain, customDomain }) => {
    const q = {
        query: `
          {
              websites(where:  {_or: [
                  {sub_domain: {_ilike: "${subDomain}"}},
                   {custom_domain: {_ilike: "${customDomain}"}}
              ]}) {
                  id, vendor_id, name, logo, sub_domain, custom_domain
                }
          }`,
    };
    return gqlSend(q);
};

export const getVendorSiteContent = (id) => {
    const q = {
        query: `
          {
            websites(where: {id: {_eq: ${id}}}) {
                  id, vendor_id, name, logo, sub_domain, pics, custom_domain, header_section, body_section
                }
          }`,
    };
    return gqlSend(q);
};

export const sendContactForm = data => {
  return apiSend(data, "/api/email/contact_form");
}
