import React, { Component } from "react";

export default class PubAbout extends Component {
    render() {
        return (
            <div>
                <div className="page-header text-center py-5">
                    <div className="container">
                        <h2 className="page-title pt-2 mb-3 font-weight-bold">
                            About Us
                        </h2>
                        <div className="page-intro single-col-max-width mx-auto mb-3">
                            Our story goes here. Lorem
                            ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget dolor. Aenean massa. Cum
                            sociis natoque penatibus et magnis dis parturient
                            montes, nascetur ridiculus mus. Donec quam felis,
                            ultricies nec, pellentesque eu, pretium quis.
                        </div>
                    </div>
                    {/*//container*/}
                </div>
                {/*//page-header*/}
                <section className="team-section py-5">
                    <div className="container">
                        <div className="card-deck">
                            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                <div className="card-body">
                                    <div className="member-profile position-absolute w-100 text-center">
                                        <img
                                            className="rounded-circle mx-auto d-inline-block shadow-sm"
                                            src="/assets/images/team/member-1.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="card-text pt-1">
                                        <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                                            John Lynch
                                        </h5>
                                        <div className="mb-3 text-center">
                                            Co-founder / CEO
                                        </div>
                                        <div>
                                            Lorem ipsum dolor sit amet,
                                            consectetuer adipiscing elit. Aenean
                                            commodo ligula eget dolor. Vivamus
                                            eget eros vestibulum, accumsan ante
                                            viverra, condimentum tellus.
                                            Curabitur pellentesque convallis
                                            purus non ornare. Donec bibendum sed
                                            purus dignissim rutrum. Maecenas
                                            bibendum feugiat est, et venenatis
                                            nunc.
                                        </div>
                                    </div>
                                </div>
                                {/*//card-body*/}
                                <div className="card-footer theme-bg-primary border-0 text-center">
                                    <ul className="social-list list-inline mb-0 mx-auto">
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="#!">
                                                <i className="fab fa-linkedin-in fa-fw" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="#!">
                                                <i className="fab fa-twitter fa-fw" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="#!">
                                                <i className="fab fa-medium fa-fw" />
                                            </a>
                                        </li>
                                    </ul>
                                    {/*//social-list*/}
                                </div>
                                {/*//card-footer*/}
                            </div>
                            {/*//card*/}
                            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                <div className="card-body">
                                    <div className="member-profile position-absolute w-100 text-center">
                                        <img
                                            className="rounded-circle mx-auto d-inline-block shadow-sm"
                                            src="/assets/images/team/member-2.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="card-text pt-1">
                                        <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                                            Joe Thomas
                                        </h5>
                                        <div className="mb-3 text-center">
                                            Co-founder / CTO
                                        </div>
                                        <div>
                                            Lorem ipsum dolor sit amet,
                                            consectetuer adipiscing elit. Aenean
                                            commodo ligula eget dolor. Vivamus
                                            eget eros vestibulum, accumsan ante
                                            viverra, condimentum tellus.
                                            Curabitur pellentesque convallis
                                            purus non ornare. Donec bibendum sed
                                            purus dignissim rutrum. Maecenas
                                            bibendum feugiat est, et venenatis
                                            nunc.
                                        </div>
                                    </div>
                                </div>
                                {/*//card-body*/}
                                <div className="card-footer theme-bg-primary border-0 text-center">
                                    <ul className="social-list list-inline mb-0 mx-auto">
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="#!">
                                                <i className="fab fa-linkedin-in fa-fw" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="#!">
                                                <i className="fab fa-twitter fa-fw" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="#!">
                                                <i className="fab fa-github fa-fw" />
                                            </a>
                                        </li>
                                    </ul>
                                    {/*//social-list*/}
                                </div>
                                {/*//card-footer*/}
                            </div>
                            {/*//card*/}
                        </div>
                        {/*//card-deck*/}
                    </div>
                </section>
                {/*//team-section*/}
                <section className="press-section py-5">
                    <div className="container">
                        <h3 className="mb-3 text-center">Zdoc in The Press</h3>
                        <div className="single-col-max-width mx-auto mb-3 text-center">
                            Use this section to link to your app's press
                            releases. <br />
                            You can also provide a press kit for interested
                            publishers to download here.
                        </div>
                        <div className="text-center mb-5">
                            <a
                                className="btn btn-primary theme-btn-ghost"
                                href="#!"
                            >
                                Download Press Kit
                            </a>
                        </div>
                        <div className="card-deck pt-3 mb-5">
                            <div className="card border-0 shadow-lg position-relative">
                                <div className="card-header theme-bg-secondary border-0">
                                    <h4 className="press-name mb-0  text-white">
                                        The New York Times
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-title">
                                        <h5>
                                            <a href="#!">
                                                Article Title Lorem Ipsum Dolor
                                                Sit Amet
                                            </a>
                                        </h5>
                                    </div>
                                    <div className="card-text">
                                        Lorem ipsum dolor sit amet, consectetuer
                                        adipiscing elit. Aenean commodo ligula
                                        eget dolor. Aenean massa. Cum sociis
                                        natoque penatibus...
                                    </div>
                                    <a className="my-3 d-block" href="#!">
                                        <i className="fas fa-external-link-alt mr-2" />
                                        Read more
                                    </a>
                                    <small className="text-muted py-1 d-block">
                                        July 18, 2018
                                    </small>
                                </div>
                                {/*//card-body*/}
                            </div>
                            {/*//card*/}
                            <div className="card border-0 shadow-lg position-relative">
                                <div className="card-header theme-bg-secondary border-0">
                                    <h4 className="press-name mb-0 text-white">
                                        LifeHacker
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-title">
                                        <h5>
                                            <a href="#!">
                                                Article Title Lorem Ipsum Dolor
                                                Sit Amet
                                            </a>
                                        </h5>
                                    </div>
                                    <div className="card-text">
                                        Lorem ipsum dolor sit amet, consectetuer
                                        adipiscing elit. Aenean commodo ligula
                                        eget dolor. Aenean massa. Cum sociis
                                        natoque penatibus...
                                    </div>
                                    <a className="my-3 d-block" href="#!">
                                        <i className="fas fa-external-link-alt mr-2" />
                                        Read more
                                    </a>
                                    <small className="text-muted py-1 d-block">
                                        April 3, 2018
                                    </small>
                                </div>
                                {/*//card-body*/}
                            </div>
                            {/*//card*/}
                        </div>
                        {/*//card-deck*/}
                        <div className="card-deck mb-5">
                            <div className="card border-0 shadow-lg position-relative">
                                <div className="card-header theme-bg-secondary border-0">
                                    <h4 className="press-name mb-0  text-white">
                                        TechCrunch
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-title">
                                        <h5>
                                            <a href="#!">
                                                Article Title Lorem Ipsum Dolor
                                                Sit Amet
                                            </a>
                                        </h5>
                                    </div>
                                    <div className="card-text">
                                        Lorem ipsum dolor sit amet, consectetuer
                                        adipiscing elit. Aenean commodo ligula
                                        eget dolor. Aenean massa. Cum sociis
                                        natoque penatibus et...
                                    </div>
                                    <a className="my-3 d-block" href="#!">
                                        <i className="fas fa-external-link-alt mr-2" />
                                        Read more
                                    </a>
                                    <small className="text-muted py-1 d-block">
                                        Feb 5, 2018
                                    </small>
                                </div>
                                {/*//card-body*/}
                            </div>
                            {/*//card*/}
                            <div className="card border-0 shadow-lg position-relative">
                                <div className="card-header theme-bg-secondary border-0">
                                    <h4 className="press-name mb-0 text-white">
                                        AppStorm
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-title">
                                        <h5>
                                            <a href="#!">
                                                Article Title Lorem Ipsum Dolor
                                                Sit Amet
                                            </a>
                                        </h5>
                                    </div>
                                    <div className="card-text">
                                        Lorem ipsum dolor sit amet, consectetuer
                                        adipiscing elit. Aenean commodo ligula
                                        eget dolor. Aenean massa. Cum sociis
                                        natoque penatibus...
                                    </div>
                                    <a className="my-3 d-block" href="#!">
                                        <i className="fas fa-external-link-alt mr-2" />
                                        Read more
                                    </a>
                                    <small className="text-muted py-1 d-block">
                                        Feb 11, 2018
                                    </small>
                                </div>
                                {/*//card-body*/}
                            </div>
                            {/*//card*/}
                        </div>
                        {/*//card-deck*/}
                    </div>
                </section>
            </div>
        );
    }
}
