import React, { Component } from "react";
import { sendOTP, verifyOTP } from "./network";
import SimpleReactValidator from "simple-react-validator";
import { Alert } from "reactstrap";

export default class MobileVerify extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({});
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.buttonIds = {
            sendOTP: "btnMVSendOTP",
            verifyOTP: "btnMVVerifyOTP",
            resendOTP: "btnMVReSendOTP",
        };
        this.state = {
            mobile: "",
            otp: "",
            otpMsg: "Send OTP",
            sentOTP: false,
            showOTPStatus: false,
            isOTPValid: false,
        };
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onClick(e) {
        const { id } = e.target;
        const { mobile, otp } = this.state;

        if (this.validator.allValid()) {
            if (mobile && id === this.buttonIds.sendOTP) {
                sendOTP(mobile)
                    .then((r) => {
                        console.log(r);
                        this.setState({ sentOTP: true, otpMsg: "Resend OTP" });
                    })
                    .catch((err) => console.log(err));
            } else if (mobile && otp && id === this.buttonIds.verifyOTP) {
                verifyOTP(mobile, otp)
                    .then((r) => {
                        console.log(r);
                        this.setState({
                            showOTPStatus: false,
                            isOTPValid: true,
                        });
                        this.props.onVerify(mobile);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({
                            showOTPStatus: true,
                            isOTPValid: false,
                        });
                    });
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const {
            mobile,
            sentOTP,
            showOTPStatus,
            isOTPValid,
            otp,
            otpMsg,
        } = this.state;
        return (
            <div className="">
                <div className="form-group">
                    <label className="text-muted">Mobile: </label>
                    <div className="input-group">
                        <input
                            type="text"
                            name="mobile"
                            className="form-control"
                            value={mobile}
                            disabled={isOTPValid}
                            onChange={this.onChange}
                            placeholder="ex: 9876549876"
                        />
                        <div className="input-group-append">
                            <button
                                id={this.buttonIds.sendOTP}
                                className="btn btn-outline-secondary"
                                type="button"
                                disabled={isOTPValid}
                                onClick={this.onClick}
                            >
                                {isOTPValid ? (
                                    <span className="text-success">
                                        <i className="fa fa-check mr-1"></i>
                                        Verified!
                                    </span>
                                ) : (
                                    otpMsg
                                )}
                            </button>
                        </div>
                    </div>
                    {this.validator.message(
                        "Moble",
                        mobile,
                        "required|numeric|size:10"
                    )}
                </div>

                {sentOTP && !isOTPValid && (
                    <div className="form-group w-75">
                        <label className="text-muted">
                            Please confirm the OTP:{" "}
                        </label>
                        <div className="input-group">
                            <input
                                type="text"
                                name="otp"
                                className="form-control"
                                value={otp}
                                onChange={this.onChange}
                                placeholder="Enter OTP"
                            />
                            <div className="input-group-append">
                                <button
                                    id={this.buttonIds.verifyOTP}
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={this.onClick}
                                >
                                    Verify
                                </button>
                            </div>
                        </div>
                        {this.validator.message(
                            "OTP",
                            otp,
                            "required|numeric|size:4"
                        )}
                    </div>
                )}

                {showOTPStatus &&
                    (isOTPValid ? (
                        <Alert color="success">Verified</Alert>
                    ) : (
                        <Alert color="danger">Invalid OTP</Alert>
                    ))}
            </div>
        );
    }
}
