import React, { Component } from "react";
import { getVendorSiteContent } from "page/network";
import { getSubDomain, isSMDevice } from "utils";
import BookAppointment from "components/BookAppointment";

export default class SubHome extends Component {
    constructor(props) {
        super(props);
        const subDomain = getSubDomain();
        this.state = {
            subDomain,
            header: "Header Section",
            body: "Body",
        };
        if (subDomain) {
            getVendorSiteContent(subDomain.id)
                .then((r) => {
                    const [sd] = r.data.websites;
                    if (sd) {
                        const {
                            header_section: header,
                            body_section: body,
                        } = sd;

                        this.setState({ header, body });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    render() {
        const { subDomain, header, body } = this.state;
        return (
            <div>
                <section className="hero-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="site-tagline mb-3 mt-lg-5">
                                    {header ? (
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: header,
                                            }}
                                        ></p>
                                    ) : (
                                        <div>Your header section goes here</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/*//row*/}
                    </div>
                </section>
                
                <section className="benefits-section py-5 mb-5">
                    <h4 className="mb-5 text-muted text-center font-weight-light">
                            Book an Appointment
                        </h4>
                    <div className={`container p-lg-5 ${isSMDevice()?"": "shadow-lg"}`}>                        
                        <div className="mt-4">
                            <BookAppointment vendor_id={subDomain.vendor_id} />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
