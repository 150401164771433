import { gqlSend } from "utils/AxiosUtil";
import { getVendorId } from "utils";

export const getPrescriptionTemplates = () => {
    const q = {
        query: `{
            prescription_templates { id, title, descr, details }
        }`,
    };

    return gqlSend(q);
};

export const createOrUpdateTemplate = ({ id, title, descr, details }) => {
    const vendor_id = getVendorId();
    const _s =
        "title: $title, descr: $descr, details: $details, vendor_id: $vendor_id";

    const st = id
        ? `update_prescription_templates ( where: {id: {_eq: $id}}, _set: {${_s}} ) {
      affected_rows
    }`
        : `insert_prescription_templates (objects: {${_s}}) {
          returning {id}
    }`;

    const q = {
        query: ` 
            mutation ($id: Int, $vendor_id: Int, $title: String, $descr: String, $details: jsonb ) {
            ${st}
        }`,
        variables: {
            id,
            title,
            descr,
            details,
            vendor_id,
        },
    };

    return gqlSend(q);
};

export const deleteTemplate = (id) => {
    return gqlSend({
        query: ` 
                  mutation {
                      delete_prescription_templates(where: {id: {_eq: ${id}}}) {
                          affected_rows
                        }
                  }
                  `,
    });
};
