import React, { Component } from "react";
import { getConsultationName, getConsultationIcon } from "utils";
import { APP_CURRENCY_SYMBOL } from "data/config/variables";

export default class BookingListingItem extends Component {
    render() {
        const { item } = this.props;
        return (
            <div
                className="shadow-sm border rounded mb-3 bg-white text-primary"
                onClick={(e) => this.props.onSelect(item)}
                style={{ cursor: "pointer" }}
            >
                <div className="d-flex align-items-center">
                    <div className="py-3 ml-2 mr-4" style={{ width: "25%" }}>
                        <img
                            src={item.avatar || "/assets/img/clinic-placeholder.png"}
                            className="img-fluid"
                            style={{ width: "120px", height: "120px" }}
                        />
                    </div>
                    <div className="" style={{ width: "75%" }}>
                        <div className="lead mb-2">{item.title}</div>
                        <div className="h6 mb-2">{item.line1}</div>
                        {item.address && (
                            <div className="text-muted  mb-2">
                                {item.address}
                            </div>
                        )}
                        <div className="d-flex text-secondary ">
                            {item.consultations && item.consultations.map((c, k1) => (
                                <div key={k1}>
                                    {c.status && (
                                        <div
                                            className="mr-3"
                                            title={getConsultationName(c.type)}
                                        >
                                            <i
                                                className={`fa mr-2 ${getConsultationIcon(
                                                    c.type
                                                )}`}
                                            ></i>
                                            {APP_CURRENCY_SYMBOL + c.price}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
