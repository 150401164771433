import React, { Component } from "react";
import { getSubDomain } from "utils";
import BookAppointment from "components/BookAppointment";

export default class SubHomeSnippet extends Component {
    constructor(props) {
        super(props);
        const subDomain = getSubDomain();
        this.state = {
            subDomain,
        };
    }

    render() {
        const { subDomain } = this.state;
        return (
            <div className="p-3">
                <BookAppointment vendor_id={subDomain.vendor_id} />
            </div>
        );
    }
}
