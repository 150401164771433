import { gqlSend } from "utils/AxiosUtil";
import { getVendorId, getUserId } from "utils";
import isEmpty from "utils/isEmpty";

export const getPrescriptions = (user = null, booking = null) => {
    let _s = `${user ? `user_id: {_eq: ${user.id}}` : ""}`;
    _s += `${booking ? `, booking_id: {_eq: ${booking.id}}` : ""}`;
    // vendor id already set in gql

    const q = {
        query: `{
            prescriptions(where: {${_s}}) { id, hash, created_at, details }
        }`,
    };

    return gqlSend(q);
};

export const createPrescription = ({ details, user_id, booking_id }) => {
    const vendor_id = getVendorId();
    const vendor_login_id = getUserId();

    const _s = `details: $details, user_id: $user_id, booking_id: $booking_id, 
        vendor_login_id: $vendor_login_id, vendor_id: $vendor_id`;

    const q = {
        query: ` 
            mutation ($user_id: Int, $booking_id: Int, $vendor_login_id: Int, $vendor_id: Int, $details: jsonb ) {
              insert_prescriptions (objects: {${_s}}) {
                returning {id}
          }
        }`,
        variables: {
            details,
            user_id,
            booking_id,
            vendor_id,
            vendor_login_id,
        },
    };

    return gqlSend(q);
};

export const getPrescriptionHeaderAndFooter = () => {
    const vendor_id = getVendorId();

    return gqlSend({
        query: `
      {
        websites(where: {vendor_id: {_eq: ${vendor_id}}}) {
          id, prescription_header, prescription_footer
        }
      }`,
    });
};

export const setPrescriptionHeaderAndFooter = ({ id, header, footer }) => {
    const vendor_id = getVendorId();

    const _s = ` prescription_header: $header, prescription_footer: $footer `;

    const subQuery = id
        ? `update_websites( where: {vendor_id: {_eq: ${vendor_id}}}, _set: {${_s}} ) {
      affected_rows
    }`
        : `insert_websites(objects: {${_s}}) {
      returning {id}
    }`;

    const q = {
        query: ` 
        mutation ($header: String, $footer: String ) {
          ${subQuery}
        }`,
        variables: {
            header,
            footer,
        },
    };

    return gqlSend(q);
};
