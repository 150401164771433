import React, { Component } from "react";
import FormElement from "components/Form/FormElement";
import ProfilePic from "components/Form/ProfilePic";
import Select from "react-select";
import { profile_pic_types } from "data/config/variables";
import { getUsersList } from "../../settings/network";
import isEmpty from "utils/isEmpty";

export default class ManageLiDetails extends Component {
  constructor(props) {
    super(props);

    this.validator = this.props.validator;

    const { data } = this.props;
    const { avatar, managers, vendor_login_id, title, line1, line2, address } =
      data || {};

    this.state = {
      avatar: avatar || null,
      allUsers: [],
      managers: managers || [],
      listingOwner: vendor_login_id || null,
      isMultiUser: false,
      title: title || "",
      line1: line1 || "",
      line2: line2 || "",
      address: address || ""
    };
    this.onChange = this.onChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  componentDidMount() {
    getUsersList()
      .then(r => {
        const users = r.data.vendor_logins;
        if (!isEmpty(users)) {
          const allUsers = users.map(u => {
            return { value: u.id, label: `${u.first_name} ${u.last_name}` };
          });
          if (users.length > 1) {
            this.setState({ allUsers, isMultiUser: true });
          } else {
            const [user] = users;
            this.setState({
              allUsers,
              isMultiUser: false,
              listingOwner: user.id,
              managers: [user.id]
            });
          }
        }
      })
      .catch(r => console.log(r));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, this.onUpdate);
  }

  onUpdate() {
    const {
      avatar,
      managers,
      title,
      listingOwner,
      line1,
      line2,
      address
    } = this.state;

    if(managers.indexOf(listingOwner) === -1) {
      managers.push(listingOwner);
    }

    this.props.onUpdate({
      avatar,
      vendor_login_id: listingOwner,
      managers,
      title,
      line1,
      line2,
      address
    });
  }

  render() {
    const {
      avatar,
      managers,
      listingOwner,
      allUsers,
      isMultiUser
    } = this.state;
    return (
      <div>
        <div className="d-flex d-flex justify-content-between">
          <div className="flex-grow-1 mr-5">
            <FormElement
              name="title"
              label="Clinic Name"
              placeHolder="Ex: Happy Hospitals"
              title="Enter Clinic Name"
              state={this.state}
              onChange={this.onChange}
              validator={this.validator}
              validation="required"
            />

            <FormElement
              label="Doctor Name"
              name="line1"
              placeHolder="Dr. Suresh M, General Physician"
              title="Enter Doctor name or degrees"
              state={this.state}
              onChange={this.onChange}
              validator={this.validator}
              validation="required"
            />

            <FormElement
              label="Description [Optional]"
              name="line2"
              placeHolder="Services provided"
              title="Enter services provided at the place"
              state={this.state}
              onChange={this.onChange}
            />

            <FormElement
              label="Address [Optional]"
              name="address"
              placeHolder="Ex: 18, Gandhi Bazaar, Bangalore - 50"
              title="Enter location"
              state={this.state}
              onChange={this.onChange}
            />

            {isMultiUser && (
              <div>
                <FormElement label="listingOwner" name="listingOwner">
                  <div title="Select users who can manage this listing and appointments">
                    <label className="text-muted">
                      User Providing the Consultation:{" "}
                    </label>
                    <Select
                      name="managers"
                      options={allUsers}
                      placeholder="Select User"
                      value={allUsers.map(op =>
                        listingOwner === op.value ? op : null
                      )}
                      onChange={sel =>
                        this.setState(
                          {
                            listingOwner: sel.value
                          },
                          this.onUpdate
                        )
                      }
                    />
                    {this.validator.message(
                      "Listing Owner",
                      listingOwner,
                      "required"
                    )}
                  </div>
                </FormElement>

                <FormElement label="managers" name="managers">
                  <div title="Select users who can manage this listing and appointments">
                    <label className="text-muted">
                      Users who has access to this Listing:{" "}
                    </label>
                    <Select
                      name="managers"
                      options={allUsers}
                      isMulti={true}
                      placeholder="Select User(s)"
                      value={allUsers.map(op =>
                        managers && managers.includes(op.value) ? op : false
                      )}
                      onChange={sel =>
                        this.setState(
                          {
                            managers:
                              sel && sel.length > 0 ? sel.map(i => i.value) : []
                          },
                          this.onUpdate
                        )
                      }
                    />
                  </div>
                </FormElement>
              </div>
            )}
          </div>
          <div className="mx-5 align-self-center">
            <ProfilePic
              avatar={avatar}
              type={profile_pic_types.vendorServicesAvatar}
              onUpload={avatar => this.setState({ avatar }, this.onUpdate)}
            />
          </div>
        </div>
      </div>
    );
  }
}
