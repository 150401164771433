import React, { Component } from "react";
import PropTypes from "prop-types";

import SimpleReactValidator from "simple-react-validator";
import { login, sendOTP } from "./network";
import { A4P_ROLE_USER } from "data/config/variables";
import FormElement from "components/Form/FormElement";
import SocialLogin from "./social-logins";

class Login extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validator = new SimpleReactValidator({});
        this.state = {
            email: "",
            password: "",
            socialType: "",
            socialToken: "",
            isSendOTP: false,
            role: this.props.role,
            otpMsg: "Send OTP",
            submitBtnTxt: "Log in",
            serverErrors: false, // for displaying errors from server
            serverErrorMessage: "Sorry! Something went wrong", // error message frm server
        };
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onFormSubmit(e) {
        if (e) e.preventDefault();
        const { socialType } = this.state; // to check if social login

        if (socialType || this.validator.allValid()) {
            this.setState({
                submitBtnTxt: "Please Wait...",
                serverErrors: false,
                serverErrorMessage: "",
            });

            const params = { ...this.state, phone: null };
            params.email = params.email.trim().toLowerCase();

            if (!isNaN(params.email) && params.email.length === 10) {
                params.phone = params.email;
                params.email = null;
            }

            login(params)
                .then((r) => {
                    console.log(r);
                    this.props.onLoginComplete(r);
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        submitBtnTxt: "Submit",
                        serverErrors: true,
                        serverErrorMessage: "Invalid email or password.",
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
        }
    }

    render() {
        const { email, isSendOTP, otpMsg } = this.state;
        const { showSignUpLink, showSocialLinks } = this.props;
        this.validator.purgeFields();

        const warningBlock = () => (
            <div className="alert alert-danger">
                <strong className="mr-2">Error!</strong>{" "}
                {this.state.serverErrorMessage}
            </div>
        );

        return (
            <div>
                <div className="">
                    <div>
                        {this.state.serverErrors && warningBlock()}
                        {showSocialLinks && (
                            <SocialLogin
                                onSuccess={(type, token, profile) =>
                                    this.setState(
                                        {
                                            socialType: type,
                                            socialToken: token,
                                            email: profile.email,
                                        },
                                        this.onFormSubmit
                                    )
                                }
                            />
                        )}
                        <form
                            id="vlogin"
                            method="post"
                            onSubmit={this.onFormSubmit}
                        >
                            <div className="row">
                                {isSendOTP ? (
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="text-muted">
                                                Mobile:
                                            </label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={this.onChange}
                                                    placeholder="ex: 9876549876"
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        onClick={(e) => {
                                                            if (
                                                                !isNaN(email) &&
                                                                email.length ===
                                                                    10
                                                            ) {
                                                                sendOTP(email)
                                                                    .then((r) =>
                                                                        this.setState(
                                                                            {
                                                                                otpMsg:
                                                                                    "Resend OTP",
                                                                            }
                                                                        )
                                                                    )
                                                                    .catch(
                                                                        (err) =>
                                                                            console.log(
                                                                                err
                                                                            )
                                                                    );
                                                            }
                                                        }}
                                                    >
                                                        {otpMsg}
                                                    </button>
                                                </div>
                                            </div>
                                            {this.validator.message(
                                                "Moble",
                                                email,
                                                "required|numeric|size:10"
                                            )}
                                        </div>{" "}
                                    </div>
                                ) : (
                                    <FormElement
                                        name="email"
                                        className="col-12"
                                        placeHolder="Mobile or Email"
                                        state={this.state}
                                        onChange={this.onChange}
                                        validator={this.validator}
                                        validation="required"
                                    />
                                )}

                                <FormElement
                                    name="password"
                                    type="password"
                                    className="col-12"
                                    placeHolder={isSendOTP ? "OTP" : "Password"}
                                    state={this.state}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required"
                                />

                                <div className="col-12">
                                    <div
                                        className="text-right mb-4"
                                        style={{ fontSize: "14px" }}
                                    >
                                        <a
                                            className="text-light"
                                            href="#!"
                                            onClick={(e) =>
                                                this.setState({
                                                    isSendOTP: !isSendOTP,
                                                })
                                            }
                                        >
                                            {isSendOTP
                                                ? "Log in with Password"
                                                : "Log in with OTP instead?"}
                                        </a>
                                    </div>

                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary theme-btn mx-auto"
                                        >
                                            {this.state.submitBtnTxt}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {showSignUpLink && (
                    <div className="option-container mt-4 pt-3 text-center">
                        <div className="lead-text">
                            Don't have an account?{" "}
                            <a
                                href="#0"
                                onClick={() => this.props.onSignupClick()}
                            >
                                Sign up
                            </a>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Login.defaultProps = {
    role: A4P_ROLE_USER,
};

Login.propTypes = {
    onForgotPWClick: PropTypes.func.isRequired,
    onSignupClick: PropTypes.func.isRequired,
    onLoginComplete: PropTypes.func.isRequired,
    role: PropTypes.string,
};

export default Login;
