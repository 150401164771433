import React from "react";
import { getSubDomain } from "utils";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const SiteLogo = () => {
	const subDomain = getSubDomain();
	return (
		<>
			{subDomain ? (
				<Helmet>
					<title>
						{subDomain.name} - Video Consultation, Appointment
						Booking
					</title>
					<link
						rel="shortcut icon"
						href="/assets/img/favicon-sub.ico"
					/>
				</Helmet>
			) : (
				<Helmet>
					<title>ZDOC- Clinic Management Suite</title>
					<link rel="shortcut icon" href="/assets/img/favicon.png" />
				</Helmet>
			)}

			<Link className="navbar-brand" to="/">
				{subDomain ? (
					subDomain.logo ? (
						<img
							src={subDomain.logo}
							className=""
							alt={subDomain.name}
							style={{
								width: "180px",
							}}
						/>
					) : (
						<div className="h3 mb-0" style={{ fontWeight: "800" }}>
							{subDomain.name}
						</div>
					)
				) : (
					<>
						<img
							className="svg-ie-fix logo-icon mr-2"
							src="/assets/img/logo.png"
							alt="ZDOC"
							style={{
								width: "120px",
							}}
						/>
					</>
				)}
			</Link>
		</>
	);
};
