import React, { Component } from "react";
import $ from "jquery";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import isEmpty from "utils/isEmpty";

class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.uniqueEditor = null;
        this.onImageUpload = this.onImageUpload.bind(this);

        this.state = {
            content: this.props.content,
            showEditor: false,
        };
    }

    /**
     * For some reason two modals appear when you init a SummernoteEditor
     * So just hide them
     */
    componentDidMount() {
        $(".note-link-popover").css("display", "none");
        $(".note-image-popover").css("display", "none");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content)
            this.setState({ content: this.props.content });
    }

    onImageInsertAsDataURL(fileList) {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.uniqueEditor.insertImage(reader.result);
        };
        reader.readAsDataURL(fileList[0]);
    }

    onImageUpload(fileList) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://imgur-apiv3.p.rapidapi.com/3/image");
        //xhr.withCredentials = true;
        //xhr.setRequestHeader("x-rapidapi-host", "imgur-apiv3.p.rapidapi.com");
        xhr.setRequestHeader(
            "x-rapidapi-key",
            "1de4df3a48mshfae4b0413bdbc4bp15aaf5jsn4d71e081a87a"
        );
        //xhr.setRequestHeader("content-type", "application/x-www-form-urlencoded");
        //xhr.open("POST", "https://api.imgur.com/3/image");
        xhr.setRequestHeader("Authorization", "Client-ID 1dd56b054dc62fe");

        const data = new FormData();
        data.append("image", fileList[0]);
        xhr.send(data);

        xhr.addEventListener("load", () => {
            const response = JSON.parse(xhr.responseText);
            this.uniqueEditor.insertImage(response.data.link);
            console.log(response);
        });

        xhr.addEventListener("error", () => {
            const error = JSON.parse(xhr.responseText);
            console.log(error);
        });
    }

    render() {
        const { showEditor, content } = this.state;
        
        return (
            <div>
                {showEditor ? (
                    <>
                        <div className="text-right cursor-pointer pb-1">
                            <span
                                className="mr-3"
                                onClick={(e) => {
                                    this.setState({
                                        showEditor: false,
                                    });
                                }}
                            >
                                <i className="fa fa-times"></i> Cancel
                            </span>
                            <span
                                onClick={(e) => {
                                    this.props.onContentChange(content);
                                    this.setState({
                                        showEditor: false,
                                    });
                                }}
                            >
                                <i className="fa fa-check"></i> Save
                            </span>
                        </div>

                        <ReactSummernote
                            ref={(el) => (this.uniqueEditor = el)}
                            value={content}
                            options={{
                                toolbar: [
                                    ["style", ["style"]],
                                    [
                                        "font",
                                        ["bold", "underline", "italic", "color", "fontsize" ],
                                    ],
                                    ["fontname", ["fontname"]], 
                                    [
                                        "para",
                                        ["ul", "ol", "paragraph", "height"],
                                    ],
                                    ["table", ["table"]],
                                    [
                                        "insert",
                                        ["link", "hr", "picture", "video"],
                                    ],
                                    ["view", ["fullscreen", "codeview"]],
                                ],
                            }}
                            onChange={(content) => this.setState({ content })}
                            onImageUpload={this.onImageUpload}
                        />
                    </>
                ) : (
                    <div>
                        <div
                            className="border p-4"
                            onClick={(e) => {
                                this.setState({ showEditor: true });
                            }}
                        >
                            <div
                                className="my-editor"
                                dangerouslySetInnerHTML={{
                                    __html: content,
                                }}
                            ></div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default RichTextEditor;
