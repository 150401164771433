import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class PubFeatures extends Component {
    render() {
        const features = [
            {
                icon: "fad fa-users-medical",
                title: "Bring your Team",
                descr:
                    "Add as many locations, doctors and support staff you need at no extra cost. They all get their own login credentials. They will be able to manage their own listings and appointments.",
            },
            {
                icon: "fad fa-lock-alt",
                title: "Number Masking",
                descr:
                    "We display masked numbers to your customers which are valid only for the duration of the appointment. Reach out safely while protecting your confidentiality.",
            },
            {
                icon: "fad fa-fighter-jet",
                title: "Get started within minutes",
                descr:
                    "All it would take is 10 minutes to setup and get going. You will get an instant dedicated subdomain where you can start accepting appointments and payments.",
            },
            {
                icon: "fad fa-file-prescription",
                title: "Digital Prescriptions",
                descr:
                    "Prescribe medicines for appointments online. Zdoc's easy to use tool lets you create and send prescriptions to your patients over email and SMS. They can also view in their portal",
            },
            {
                icon: "fad fa-users-class",
                title: "Customer CRM",
                descr:
                    "Acquiring new patients can be five times costlier than retaining existing patients. View all your Patients at one place. Alert them of upcoming holidays and campaigns on the go.",
            },
            {
                icon: "fab fa-google",
                title: "Google Calendar",
                descr:
                    "ZDOC integrates with your google calendar. You can view upcoming appointments for the day, week, month in a breeze with notifications and reminders",
            },
            {
                icon: "fad fa-sliders-v",
                title: "No App Installations",
                descr:
                    "Your customers dont have to signup or install any apps for video consultation. Infact they can book an appointment with just a missed call",
            },
            {
                icon: "fad fa-pencil-paintbrush",
                title: "Website Templates",
                descr:
                    "Choose any template from our beautifully designed templates for your website. Customize the logo and content as per you needs ",
            },
            {
                icon: "fad fa-calendar-star",
                title: "Easy Consultations",
                descr:
                    "Your customers can book quick consultation and you can call them as per your availability. You don’t need to schedule a consultation for a specific slot",
            },
            {
                icon: "fad fa-chart-pie",
                title: "Advanced Reporting",
                descr:
                    "Track your growth consultation type, revenue, patient, appointment wise. See how you performed last week, month. Total sales till date and many more metrics.",
            },
            {
                icon: "fad fa-comments",
                title: "Request For Feedback",
                descr:
                    "Grow your practise by collecting feedback about the consultation. We will send feedback request after the consultation and encourage them to leave you online testimonials.",
            },
            {
                icon: "fad fa-megaphone",
                title: "Digital Marketing",
                descr:
                    "We will ensure your website is SEO optimized and show up in google results when people search for doctors in google. Nearly 70% of the customers search for doctors online.",
            },
            {
                icon: "fad fa-pills",
                title: "E-Pharmacy",
                descr:
                    "[Coming soon...] Patients will be able to order medicines online from your website. We deliver and you will be earning a share from the revenue.",
            },
            {
                icon: "fad fa-vials",
                title: "Lab Tests",
                descr:
                    "[Coming soon...] You will be able to order lab tests for your patients, we will collect the samples and share reports, and you will be earning a share from the revenue.",
            },
            {
                icon: "fad fa-user-headset",
                title: "Onboarding and Support",
                descr:
                    "Last but not the least, we are here to guide you at every step. We are happy to help you out completely during the onboarding process. And just a call away for any technical assistance thereafter.",
            },
        ];
        return (
            <div>
                <div className="page-header text-center py-5">
                    <div className="container">
                        <h2 className="page-title pt-2 mb-3 font-weight-bold">
                            Features
                        </h2>
                        <div className="mb-3 page-intro">
                            Learn how ZDOC can help your clinic reach your
                            patients in a more effective manner
                            <br />
                        </div>
                        <div className="w-75 p-lg-3 border rounded shadow-lg mx-auto mt-4">
                            <img
                                className="img img-fluid d-none d-md-block"
                                src="/assets/img/feature-main.jpg"
                                alt=""
                            />
                            <img
                                className="img img-fluid d-block d-md-none"
                                src="/assets/img/feature-mobile-main.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                    {/*//container*/}
                </div>
                {/*//page-header*/}
                <section className="feature-item feature-item-1 pt-5">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 col-lg-6 align-self-center">
                                <h4>Your Brand, Your Customers</h4>
                                <div className="mb-5">
                                    Get a dedicated subdomain of your choice
                                    where you can direct your customers to book
                                    appointments. You have the flexibility of
                                    customizing this website with your own logo
                                    and content. What’s more, you can even point
                                    your domain to this site.
                                </div>
                                <div className="feature-quote d-flex mb-4 mb-lg-0">
                                    <div className="align-self-center mr-4">
                                        <img
                                            className="feature-quote-profile  rounded-circle shadow-sm"
                                            src="/assets/img/docs/jaysimha.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="feature-quote-box shadow-lg p-4 rounded position-relative">
                                        <blockquote>
                                            <p>
                                                "Its finally good to run a
                                                digital practice under my own
                                                website domain. I just ask my
                                                patients to visit my website to
                                                book an appointment easily"
                                            </p>
                                        </blockquote>
                                        <div className="source">
                                            <div>
                                                <a href="#twitter">
                                                    Dr. Jayasimha Murthy
                                                </a>
                                            </div>
                                            <div>Pediatrician, Basavangudi</div>
                                        </div>
                                        {/*//source*/}
                                        <div className="source-icon position-absolute">
                                            <i className="fab fa-twitter text-light" />
                                        </div>
                                    </div>
                                    {/*//quote-box*/}
                                </div>
                                {/*//feature-quote*/}
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="feature-figure-holder"></div>
                            </div>
                        </div>
                        {/*//row*/}
                    </div>
                </section>
                {/*//feature-item*/}
                <section className="feature-item feature-item-2 pt-5">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 col-lg-6 align-self-center order-lg-2">
                                <h4>Future is Digital, and it is here</h4>
                                <div className="mb-5">
                                    ZDOC helps users book a quick consultation
                                    or schedule a clinic visit. Apart from these
                                    it also enables users to book a video or
                                    telephonic consultation. Upon confirmation,
                                    they receive a link which they can open on
                                    their smartphone or laptop and begin the
                                    consultation.
                                </div>
                                <div className="feature-quote d-flex mb-4 mb-lg-0">
                                    <div className="align-self-center mr-4">
                                        <img
                                            className="feature-quote-profile  rounded-circle shadow-sm"
                                            src="/assets/img/docs/prabhakar.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="feature-quote-box shadow-lg p-4 rounded position-relative">
                                        <blockquote>
                                            <p>
                                                "Many people used to message me in
                                                the middle of the night on
                                                Whatsapp with their child’s
                                                photos or ailments. With zdoc,
                                                they take proper appointment and
                                                I am also happy with the
                                                consultation."
                                            </p>
                                        </blockquote>
                                        <div className="source">
                                            <div>
                                                <a href="#!">
                                                    Dr. Prabhakaran Vellappan
                                                </a>
                                            </div>
                                            <div>
                                                General Physician, Koramangala
                                            </div>
                                        </div>
                                        {/*//source*/}
                                        <div className="source-icon position-absolute">
                                            <i className="fab fa-facebook text-light" />
                                        </div>
                                    </div>
                                    {/*//quote-box*/}
                                </div>
                                {/*//feature-quote*/}
                            </div>
                            <div className="col-12 col-lg-6 order-lg-0">
                                <div className="feature-figure-holder"></div>
                            </div>
                        </div>
                        {/*//row*/}
                    </div>
                </section>
                {/*//feature-item*/}
                <section className="feature-item feature-item-3 pt-5">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 col-lg-6 align-self-center">
                                <h4>Grow your Practice</h4>
                                <div className="mb-5">
                                    With digitization you automatically reach a
                                    larger number of people. To top that we will
                                    setup your business under google places,
                                    reviews and other local business avenues so
                                    when people search for a doctor or clinic
                                    nearby, your name pops up. We will also help you 
                                    collect feedback from your customers and
                                    encourage them to leave online testimonials
                                    for a positive footprint.
                                </div>
                                {/*/* 
				    <a className="btn btn-primary theme-btn-primary" href="#!">Get started</a>
				    * / */}
                                <div className="feature-quote d-flex mb-4 mb-lg-0">
                                    <div className="align-self-center mr-4">
                                        <img
                                            className="feature-quote-profile  rounded-circle shadow-sm"
                                            src="/assets/img/docs/deepthi.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="feature-quote-box shadow-lg p-4 rounded position-relative">
                                        <blockquote>
                                            <p>
                                                "When you are pregnant for the
                                                first time, you have so many
                                                questions. And its difficult to
                                                visit the clinic everytime. Zdoc
                                                helps me connect with my
                                                patients from anywhere and
                                                anytime."
                                            </p>
                                        </blockquote>
                                        <div className="source">
                                            <div>
                                                <a href="#!">
                                                    Dr. Deepthi Vishwanath
                                                </a>
                                            </div>
                                            <div>
                                                Gynecologist, Electronic City
                                            </div>
                                        </div>
                                        {/*//source*/}
                                        <div className="source-icon position-absolute">
                                            <i className="fab fa-whatsapp text-light" />
                                        </div>
                                    </div>
                                    {/*//quote-box*/}
                                </div>
                                {/*//feature-quote*/}
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="feature-figure-holder"></div>
                            </div>
                        </div>
                        {/*//row*/}
                    </div>
                </section>
                {/*//feature-item*/}
                <section className="feature-list py-5 text-center">
                    <div className="container">
                        <h3 className="mt-4 font-weight-bold">ZDOC Benefits</h3>
                        <div className="mb-3">
                            ZDOC comes with in-built capabilities to digitize
                            your medical practice
                        </div>
                        <div className="row">
                            {features.map((f, k) => (
                                <div key={k} className="item col-12 col-lg-4">
                                    <div className="item-inner text-center p-3 p-lg-5">
                                        <i
                                            className={`${f.icon} fa-3x text-primary fa-fw`}
                                        ></i>
                                        <h5 className="mt-4 pt-3">{f.title}</h5>
                                        <div>{f.descr}</div>
                                    </div>
                                    {/*//item-inner*/}
                                </div>
                            ))}
                        </div>
                        {/*//row*/}
                    </div>
                    {/*//container*/}
                </section>
                <section className="cta-section py-5 theme-bg-secondary text-center">
                    <div className="container">
                        <h3 className="text-white font-weight-bold mb-3">
                            Start Using ZDOC today
                        </h3>
                        <div className="text-white mx-auto single-col-max-width section-intro">
                            Sign up now. Get a tech-enabled practice and stay
                            ahead of the curve for Free.
                        </div>
                        <Link
                            className="btn theme-btn theme-btn-ghost theme-btn-on-bg mt-4"
                            to="/pricing"
                        >
                            Get Started
                        </Link>
                    </div>
                </section>
                {/*//cta-section*/}
            </div>
        );
    }
}
