import React, { Component } from "react";

export default class PTListingItem extends Component {
    render() {
        const { item } = this.props;
        return (
            <div className="mb-2">
                <div
                    className="p-2 shadow-sm border d-flex"
                    style={{ cursor: "pointer" }}
                >
                    <div
                        className="flex-grow-1"
                        onClick={(e) => this.props.onSelect(item)}
                    >
                        <h5>
                            {item.title}
                            <small className="ml-1 text-muted">
                                ({item.details.length})
                            </small>
                        </h5>
                        <div className="text-muted">{item.descr}</div>
                    </div>
                    {this.props.onDelete && (
                        <div className="mt-2">
                            <a
                                className="h6 text-danger mx-3"
                                href="#!"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.onDelete(item);
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </a>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
