export const FaqCategories = [
    { id: "general", title: "Getting Started" },
    { id: "records", title: "Medical Records" },
    { id: "consultation", title: "Consultations" },
    { id: "payment", title: "Billing" },
];

const common = [
    {
        category: "general",
        showInDocSite: false,
        question: "What is ZDOC and how does it help me?",
        answer:
            "ZDOC is a web app created to help doctors and physicians with telemedicine. It basically helps you practice in the digital domain with the use of telephone and video consultations. It also helps you book for a physical consultation.",
    },
    {
        category: "general",
        showInDocSite: true,
        question: "What are the compatible browsers and devices for using this site?",
        answer:
            "You can access all features of this site on a smart phone, tablet, laptop or PC with a webcam. We have ensured that this functions properly on all browsers.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "What is quick consultation and How does it work?",
        answer:
            "This is mostly a follow up on the phone. The patient can book this type of consultation and when the doctor is free s/he may call the patient on the number provided.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "What is Telephonic consultation and How does it work?",
        answer:
            "When a patient opts for this, he gets a time and number to call. This will not be your phone number but will be directed to your mobile phone or landline. This number will be valid only for the time of the consultation. The patient will describe his problem and you can send an email with prescription for the same.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "What is Clinic Consultation and How does it work?",
        answer:
            "This is when the patient comes to your clinic. They will book an appointment online and meet you in person. The consultation will be a normal one, but will be prepaid, as the patient may have paid online.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "What is Video Consultation and How does it work?",
        answer:
            "When a patient books a video consultation, both the doctor and the patient receives a link via email and SMS. Once you click on this link at the time of the appointment, the webcam will get activated and you can consult the patient.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question:
            " What do I need to check before starting Video Consultation?",
        answer:
            "Ensure your internet connection is fine and check the email or sms for the link to start the video consultation.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question:
            "What do I need to check before starting Telephonic Consultation?",
        answer:
            "Before you set the telephonic consultation, be sure of the days, time, duration and price that you need to enter.",
    },
    {
        category: "payment",
        showInDocSite: true,
        question: "What are the different payment methods you accept?",
        answer:
            "We accept all type of credit cards, debit cards, netbanking, wallets and UPI. ",
    },
];

export const DoctorBillingQuestions = [
    {
        category: "payment",
        showInDocSite: true,
        question: "Will Zdoc help me get more patients?",
        answer:
            "Yes. When you signup with Zdoc you get a responsive website of your own which is SEO optimized for the clinic location. Nearly 75% of the first time users search online to find doctors, and you will be found.",
    },
    {
        category: "payment",
        showInDocSite: true,
        question: "How will I get paid from patients?",
        answer:
            "We provide UPI, net banking, credit/debit card, wallets and many more methods to make online payment for your patients. When the patient completes the payment, our fee is deducted and remaining is transferred to your account right away. However, the receiving bank would require 2~3 working days to process.",
    },
    {
        category: "payment",
        showInDocSite: true,
        question: "How long do you take to disburse the money?",
        answer:
        "We immediately payout the money the very moment we collect it from your patients. However, the banks do take 2 to 3 business working days to process the same and deposit into your account.",
    },
    {
        category: "payment",
        showInDocSite: true,
        question: "How does cancellations and refund work?",
        answer:
        "You can refund the patients either in full or partial from your portal",
    },
];

export const DoctorQuestions = [
    ...common,
    ...DoctorBillingQuestions,
    {
        category: "general",
        showInDocSite: true,
        question: "How do I set up my practice?",
        answer:
            "This is mostly a one-time effort and not very time consuming. ZDOC is created with simplicity and ease of use in mind. All you need to do is create a subdomain for your clinic. You may add logo and text for your webpage as per your brand guidelines. You can go to practice and set up various practice and appointments. You can add the dates and timing you will be available for clinic as well as telephonic and video consultation as well as your charges.",
    },
    {
        category: "general",
        showInDocSite: true,
        question: "What is a dedicated sub domain?",
        answer:
            "In short, you will get a web url with your clinic’s name and no one else can access this. This will look like www.yourclinicname.zdoc.in. You will also be able to direct your website to this subdomain.",
    },
    {
        category: "general",
        showInDocSite: true,
        question: "How do I setup my own domain?",
        answer:
            "If you already have a custom domain booked, you don’t need to use our subdomain at all. You can directly add your domain, update some records at your DNS provider and the application will get setup there.",
    },
    {
        category: "general",
        showInDocSite: true,
        question: "How do I add additional staff or doctors to my account?",
        answer:
            "Please visit the team section in the settings. You can add as many users you want. Once you add users navigate to the practice menu and set practice owner and manager who have access to that listing.",
    },
    {
        category: "general",
        showInDocSite: true,
        question: "Can I assign a practice to a doctor in my team?",
        answer:
            "Yes. Navigate to the practices menu, select the practice and set the doctor as the practice owner. You can also additional users as manager who can also view and manage the practice.",
    },
    {
        category: "general",
        showInDocSite: true,
        question:
            "How can I grant access to my front desk to manage my practice and appointments?",
        answer:
            "You have to first add the front desk members in your team, and then in the practice details, add them as managers. They will be able to view your appointments, receive notifications and manage practice for you.",
    },
    {
        category: "general",
        showInDocSite: true,
        question: "How do I manage multiple locations?",
        answer:
            "Under the practices menu you can create as many practice you want. Just ensure that you mention the location name either in the title or in the doctor field for the user to idetify it.",
    },

    {
        category: "consultation",
        showInDocSite: true,
        question: "How do I start accepting appointments",
        answer:
            "Once you set up your availability, your patients will start booking appointments. Once a patient has submitted, you will receive an email and SMS notifying you about the appointment along with the link for video or telecall.",
    },

    {
        category: "consultation",
        showInDocSite: true,
        question: "How do I setup a video consultation?",
        answer:
            "While setting up your consultation, you need to mention the days and timing you will be available for a video consultation. Once a patient books the same, you will be notified via email and SMS.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "What is Number Masking?",
        answer:
            "This is to protect your personal number from displaying it to everyone. So this app creates a temporary number that is directed to your desired phone no. The validity of this number is only till the time the consultation is on.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "What if the patient/doctor is late to the video call?",
        answer:
            "In case the patient is late for the video consultation, a masked number will be provided to you, you may call the patient and check if he is coming online soon.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "How do I specify unavailable days for consultation?",
        answer: "",
    },
];

export const UserQuestions = [
    ...common,
    {
        category: "general",
        showInDocSite: true,
        question: "How do I get started?",
        answer:
            "All you need to do is visit the doctor’s website and book an appointment.",
    },
    {
        category: "records",
        showInDocSite: true,
        question: "What is Medical Records and how do I use it?",
        answer:
            "Each patient has a profile. Under the profile, you have a section of medical records. You can upload your medical records along with date under this section.",
    },
    {
        category: "records",
        showInDocSite: true,
        question: "How do I add documents to medical reports",
        answer:
            "Many labs email medical records these days. You can directly upload this. If you have physical copies, you may scan them or take a picture and upload the same.",
    },
    {
        category: "records",
        showInDocSite: true,
        question:
            "How do I view prescriptions and consultation notes from doctor?",
        answer:
            "This will be available on your profile as well as will be sent to you via email. This feature will be coming soon",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "Where should I go to book an appointment?",
        answer:
            "Once you visit the doctor’s website, you will be able to book an appointment.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question:
            "What should I do to ensure that the doctor has joined the video consultation?",
        answer:
            "Please click on the link only when it is time for your appointment. If you do it beforehand, the video consultation will not begin. If it is time and you have joined the call and the doctor hasn’t joined, we will display a masked number that you can call on to connect with the doctor and check if he is coming online.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "How do I cancel appointments?",
        answer:
            "You can go back to your appointments and click on the cancel appointment button. Once cancelled both you and the doctor will receive an email and an SMS about the cancellation.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "How do I book consultations over phone or SMS?",
        answer:
            "When you visit the clinic, they would be displaying a phone number or an SMS code for you to book a consultation. They could be sharing this number even via emails or SMS. All you need to do is call that number or send the given code to the number and the system will send you an SMS with a link that you can book your appointment at. This will then take you to the payment link where you can pay the amount and confirm your appointment.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: " What type of consultation can I book over phone/sms?",
        answer:
            "You will be able to book all types of consultation like quick, clinic, video or telephonic consultation.",
    },
    {
        category: "consultation",
        showInDocSite: true,
        question: "Where can I view all my appointments?",
        answer:
            "Please click on your profile/account and you will be able to see all your appointments under the same.",
    },
    {
        category: "payment",
        showInDocSite: true,
        question: "How do I pay for online consultation?",
        answer:
            "Once you book the consultation, you will be directed to a payment gateway. Please complete the payment to confirm your appointment.",
    },
];
