import React, { Component } from "react";
import AppointmentsIndex from "components/Appointments";
import { getUserOnboardingStatus } from "./network";

class VendorAppointments extends Component {
    /* constructor(props) {
        super(props);
        if (!localStorage.UserOnboarded) {
            getUserOnboardingStatus()
                .then((r) => {
                    console.log(r);
                    const [v] = r.data.vendors;
                    if (v && v.status)
                        localStorage.setItem("UserOnboarded", true);
                    else this.props.history.push("/clinic/onboarding?d=1");
                })
                .catch((err) => console.log(err));
        }
    } */

    render() {
        return <AppointmentsIndex {...this.props} />;
    }
}

export default VendorAppointments;
