import React, { Component } from "react";
import { getConsultationName, getConsultationIcon, getUserRole, getConsultationColor } from "utils";
import moment from "moment-timezone";
import { A4P_ROLE_VENDOR, APP_CONSULTATION_TYPE_NOW_PHONE } from "data/config/variables";
import { Fragment } from "react";
import { RenderStatus } from "./index";

export default class AppointmentListItem extends Component {
    render() {
        const { booking, timezone } = this.props;
        const { details, status, type } = booking;

        const slot = moment.tz(booking.slot || booking.created_at, timezone);
        const userRole = getUserRole();

        return (
            <div className="col-sm-12 mb-3">
                <div
                    className="shadow-sm border"
                    style={{ cursor: "pointer" }}
                    onClick={e => this.props.onClick(booking)}
                >
                    <div className="d-flex">
                        <div className="align-items-center mr-4 border-right">
                            <div
                                className="text-center"
                                style={{ minWidth: "120px" }}
                            >
                                <div className="py-2 text-white" style={{fontSize: "0.9em", background: getConsultationColor(details.type)}}>
                                    <strong>{slot.format("dddd")}</strong>
                                </div>
                                <div className="flex-grow border-top">
                                    <div className="h1 text-muted mb-0">
                                        {slot.format("DD")}
                                    </div>
                                    <div className=" text-muted">
                                        {slot.format("MMMM")}
                                    </div>
                                    <div
                                        className=" text-muted pb-2"
                                        style={{ fontWeight: "900" }}
                                    >
                                        {slot.format("hh:mm A")}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-2 flex-grow align-self-center">
                            <div className="mb-2 text-muted" style={{fontSize: "0.9em"}}>
                                <i
                                    className={`fa mr-2 ${getConsultationIcon(
                                        details.type
                                    )}`}
                                    style={{ fontSize: "1.25em" }}
                                ></i>
                                {getConsultationName(details.type)}
                            </div>
                            {userRole === A4P_ROLE_VENDOR ? (
                                <Fragment>
                                    <div className="mb-1 h5 ">
                                        <strong>{`${details.user.first_name} ${details.user.last_name}`}</strong>
                                    </div>
                                    <div className="mb-1 text-muted lead">
                                        {details.details.title}
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className="mb-1 h4 ">
                                        <strong>{details.details.title}</strong>
                                    </div>
                                    <div className="mb-1 text-muted lead">
                                        <strong>{`${details.user.first_name} ${details.user.last_name}`}</strong>
                                    </div>
                                </Fragment>
                            )}
                            <div className="d-none d-md-block text-muted">
                                {details.details.line1}
                            </div>
                            {/* show only in mobile device */}
                            <div className="mt-2 lead d-block d-md-none">
                                {RenderStatus(slot, timezone, status)}
                            </div>
                        </div>

                        {/* Hide in mobile devices */}
                        <div className="p-2 ml-auto d-none d-md-flex flex-column justify-content-end text-right">
                            <div className="mb-auto lead">
                                {RenderStatus(slot, timezone, status, details.type)}
                            </div>
                            <div className="mb-2 text-dark">#{booking.id}</div>
                            <div className="mb-2 text-dark" title={`${moment
                                    .tz(booking.created_at, timezone)
                                    .format("DD-MM-YYYY hh:mm A z")}`}>
                                {moment
                                    .tz(booking.created_at, timezone)
                                    .format("DD-MM-YYYY")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
