import axios from "axios";
import { gqlSend, apiSend } from "utils/AxiosUtil";
import { getPetType } from "utils";
import { APP_ENABLE_OLD_PHR } from "data/config/variables";

export const getPets = userId => {
    const q = {
        query: `
    {
      pets ${userId ? "(where: {user_id: {_eq: " + userId + "}})" : ""} {
        breed created_at details id name status user_id updated_At type phr_id notes
      }
    }`
    };
    return gqlSend(q);
};

export const insertOrUpdatePet = ({ id, name, type, breed, notes, userId }) => {
    // update operation
    if (id)
        return gqlSend({
            query: `mutation( $id: Int, $userId: Int, $type: Int, $name: String, $breed:String, $notes: String)
        {
          update_pets(where: {id: {_eq: $id}}, _set: {name: $name, type: $type, breed: $breed, notes: $notes, user_id: $userId}) {
            affected_rows
          }
        }`,
            variables: { id, name, type, breed, notes, userId }
        });

    const _v = (phrId = null) =>
        gqlSend({
            query: `mutation( $userId: Int, $type: Int, $phrId: Int, $name: String, $breed:String, $notes: String)
				{
				  insert_pets(objects: {name: $name, type: $type, breed: $breed, notes: $notes, phr_id: $phrId, user_id: $userId}) {
					returning {id, phr_id}
				  }
				}`,
            variables: { name, type, breed,  notes,  phrId, userId }
        });

    if (!APP_ENABLE_OLD_PHR) return _v();

    return axios
        .get( `https://login.activ4pets.com/Account/AddNewPet?petname=${name}&pettype=${getPetType(type)}`)
        .then(r => _v(r.data.PetId));
};

// delete pet
export const deletePet = id => {
    return gqlSend({
        query: `mutation( $id: Int, $status: Int)
		  {
			update_pets(where: {id: {_eq: $id}}, _set: {status: $status}) {
			  affected_rows
			}
		  }`,
        variables: { id, status: 0 }
    });
};
