import React, { Component } from "react";
import Button from "components/Form/Button";
import { confirmAlert } from "react-confirm-alert"; // Import
import { insertUsers, updateUser } from "./network";
import { getVendorId } from "utils";
import { Alert } from "reactstrap";
import isEmpty from "utils/isEmpty";

export default class VendorCustomersImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            loading: false,
            imported: null,
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit() {
        const vendorId = getVendorId();
        const { data } = this.state;
        this.setState({ loading: true, imported: null });

        let records = [];
        const lines = data.split("\n");
        lines.forEach((l) => {
            const tokens = l.split(/,|;|\t/);
            if (tokens.length === 2) {
                const [fullName, phone] = tokens;
                const [firstName, lastName] = fullName.trim().split(" ");
                // clean up phone number
                const ph = phone.trim().replace(/\D/g, "").slice(-10);
                records.push({
                    first_name: firstName,
                    last_name: lastName || "",
                    phone: ph,
                    source: { medium: "vendor_import" },
                    vendor_id: vendorId,
                    saved_vendors: [vendorId],
                });
            } 
        });

        if (!isEmpty(records)) {
            const imported = `Imported Successfully! Total Records: ${lines.length}, Valid Records: ${records.length}`;
            insertUsers(records)
                .then((r) => {
                    const { affected_rows } = r.data.insert_users;
                    if (affected_rows !== records.length) {
                        const insertedUsers = r.data.insert_users.returning;

                        const nonInsertedUsers = records.filter(
                            (u) =>
                                !insertedUsers.some(
                                    (u1) => u.phone === u1.phone
                                )
                        );

                        const usrs = nonInsertedUsers.map((u) => u.phone);

                        updateUser(usrs, vendorId)
                            .then((r) =>
                                this.setState({ loading: false, imported })
                            )
                            .catch((err) => console.log(err));
                    } else this.setState({ loading: false, imported });
                })
                .catch((err) => console.log(err));
        }
    }

    render() {
        const { loading, imported } = this.state;
        return (
            <div className="mb-5">
                <div className="text-muted p-2 h6 mb-3 border-bottom">
                   Add Customers:
                </div>
                <div className="theme-bg-primary p-2 text-muted legal-note mb-2">
                    Instructions: <br />
                    1. Each contact should be entered in seperate line. <br />
                    2. Every line should have two fields (name and mobile
                    number) seperated by comma or semicolon or tab.
                    <br />
                    3. The name field can be a single or a double word
                    <br />
                    4. Phone numbers can be in any format except ensure it is a
                    valid mobile number.
                    <br />
                </div>
                <div className="my-3">
                    <textarea
                        className="rounded p-2 w-100"
                        name=""
                        id=""
                        rows="8"
                        placeholder="Name, Mobile &#10;Ajay Kapoor, +91-9876567819&#10;Ramesh, 9234567819 &#10;Suresh Rao, 923-456-7819 "
                        onChange={(e) =>
                            this.setState({ data: e.target.value })
                        }
                    />
                </div>
                {imported && (
                    <div>
                        <Alert color="success">{imported}</Alert>
                    </div>
                )}
                <div className="my-3">
                    <Button
                        btnCls="btn-primary px-5"
                        iconCls="fa-save"
                        value="Import Data"
                        loading={loading}
                        onClick={(e) => {
                            confirmAlert({
                                message: `Are you sure you want to import the contacts?`,
                                buttons: [
                                    {
                                        label: "Yes",
                                        onClick: this.onFormSubmit,
                                    },
                                    {
                                        label: "No",
                                    },
                                ],
                            });
                        }}
                    />
                </div>
            </div>
        );
    }
}
