import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "data/config";
import { APP_CURRENCY, APP_CURRENCY_SYMBOL } from "data/config/variables";
import { DoctorBillingQuestions } from "data/config/faq";

export default class PubPricing extends Component {
    render() {
        return (
            <div>
                <div className="page-header text-center py-5">
                    <div className="container">
                        <h2 className="page-title pt-2 mb-3 font-weight-bold">
                            Simple and Transparent Pricing
                        </h2>
                        <div className="mb-lg-5 page-intro single-col-max-width mx-auto">
                            Plans designed for Clinics and Hospitals
                        </div>
                    </div>
                    {/*//container*/}
                </div>
                {/*//page-header*/}
                <div className="section pricing-section pb-5">
                    <div className="container">
                        <div className="row pricing-table justify-content-center">
                            <div className="pricing-item col-12 col-lg-5 mb-5 ">
                                <div className="pricing-item-inner shadow-lg p-4 rounded h-100">
                                    
                                <div className="pricing-item-header text-center">
                                        <h3 className="mb-0 pricing-plan-name text-primary font-weight-bold">
                                            Customized Plans
                                        </h3>
                                        <div className="my-3">
                                            (White-labeled, Customized)
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="pricing-item-body pl-3">
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                SEO Optimized Website
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Unlimited Consultations
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Payment Gateway Integration
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Appointment Reminders
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Digital Prescriptions
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Total Data Privacy 
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Number Masking
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Google Calendar Integration
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Custom Domains
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                Analytics and Reports
                                            </li>
                                            <li className="mb-2">
                                                <i className="fas fa-check-circle mr-2 " />
                                                CRM and Campaigns
                                            </li>
                                        </ul>
                                    </div>
                                    {/*//pricing-item-body*/}
                                    <hr className="my-4" />
                                    <div className="pricing-item-header text-center">                                        
                                        <div>
                                            <Link
                                                className="btn btn-primary theme-btn-primary"
                                                to="/contact"
                                            >
                                                Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/*//pricing-item-inner*/}
                            </div>
                            {/*//pricing-item*/}
                        </div>                        
                    </div>
                </div>
                <section className="logos-section py-5 text-center">
                    <div className="container">
                        <h3 className="mb-5">
                            Trusted by hundreds of doctors like you
                        </h3>
                        <div className="logos-row row mx-auto">
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-1.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-2.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-3.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-4.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-5.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-6.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                        </div>
                    </div>
                    {/*//container*/}
                </section>

                {/*//logo-section*/}
                <div className="container py-5">
                    <hr />
                </div>
                <section className="social-proof-section text-center pt-4">
                    <h3 className="mb-3 font-weight-bold container">
                        See what people are saying about ZDOC
                    </h3>
                    <div className="mb-5 single-col-max-width mx-3 mx-lg-auto">
                        You can list your user stories or quotes below to
                        further convince your visitors to download your app.
                    </div>
                    <div className="theme-bg-primary pt-5 ">
                        <div className="container text-center ">
                            <div className="row">
                                <div className="user-quote col-12 col-lg-6 order-2 align-self-center pb-5">
                                    <div className="user-quote-inner p-5">
                                        <div className="quote-icon-holder rounded-circle text-center mx-auto mb-4">
                                            <i className="fas fa-quote-right" />
                                        </div>
                                        <h4>An absolute game changer!</h4>
                                        <div>
                                            Zdoc is better than I imagined it to
                                            be. I didn’t know that I could reach
                                            out to homebound patients. With zdoc
                                            I can. Some of my patients have gone
                                            to another city and still prefer
                                            consulting me. This enables me to
                                            reach them as well. I never knew
                                            that an application could help me
                                            expand my business.
                                        </div>
                                        <Link
                                            className="btn btn-primary theme-btn-ghost mt-3"
                                            to="/features"
                                        >
                                            Learn more
                                        </Link>
                                    </div>
                                </div>
                                {/*//user-quote*/}
                                <div className="doc-figure-holder col-12 col-lg-6 order-1" />
                            </div>
                            {/*//row*/}
                        </div>
                        {/*//container*/}
                    </div>
                </section>
                <section className="cta-section py-5 theme-bg-secondary text-center">
                    <div className="container">
                        <h3 className="text-white font-weight-bold mb-3">
                            Ready to expand your practise?
                        </h3>
                        <div className="text-white mx-auto single-col-max-width section-intro">
                            Sign up with ZDOC today. Stay ahead of the curve by
                            tech-enabling your medical practice today. Offer
                            your patients a never before experience.
                        </div>
                        <Link
                            className="btn theme-btn theme-btn-ghost theme-btn-on-bg mt-4"
                            to="/clinic/signup"
                        >
                            Get Started
                        </Link>
                    </div>
                </section>
                {/*//cta-section*/}
                <section className="faq-section py-5">
                    <div className="container text-center">
                        <h3 className="mb-5">FAQs</h3>
                        <div
                            id="faq-accordion"
                            className="faq-accordion text-left single-col-max-width mx-auto"
                        >
                            {DoctorBillingQuestions.map((q, qk) => {
                                const k = `faq${qk}`;
                                return (
                                    <div
                                        key={k}
                                        className="card border-0 shadow-lg mb-4"
                                    >
                                        <div className="card-header bg-white border-0 p-4">
                                            <h4 className="card-title mb-0">
                                                <a
                                                    data-parent="#faq-accordion"
                                                    data-toggle="collapse"
                                                    className="card-toggle collapsed"
                                                    href={`#${k}`}
                                                    aria-expanded="false"
                                                >
                                                    <i className="far fa-plus-square mr-2" />
                                                    {q.question}
                                                </a>
                                            </h4>
                                        </div>
                                        <div
                                            className="card-collapse collapse"
                                            id={k}
                                        >
                                            <div className="card-body pt-0">
                                                {q.answer}
                                            </div>
                                            {/*//card-body*/}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {/*//faq-accordion*/}
                        <div className="faq-intro text-center py-5">
                            <h5 className="mb-4">
                                Can't find the answer you're looking for? Need
                                more help?
                            </h5>
                            <div>
                                <Link
                                    className="btn btn-primary theme-btn-ghost"
                                    to="/contact"
                                >
                                    Get in touch
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/*//container*/}
                </section>
            </div>
        );
    }
}
