import React, { Component } from "react";
import PropTypes from "prop-types";
import MyDropZone from "components/Form/MyDropZone";
import isEmpty from "utils/isEmpty";

class FileManager extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { uploadType, height = 80, onUpdate, files } = this.props;
        return (
            <div>
                <MyDropZone
                    placeHolder="Drag and Drop files here or Click to browse"
                    uploadType={uploadType}
                    acceptTypes="*"
                    height={height}
                    canRemove={false}
                    onUpload={onUpdate}
                />
                {!isEmpty(files) && (
                    <table className="table table-sm mt-3">
                        <thead className="thead-dark">
                            <tr>
                                <th className="w-75">File</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file, k1) => (
                                <tr key={k1}>
                                    <td>
                                        {file.substr(file.lastIndexOf("/") + 1)}
                                    </td>
                                    <td>
                                        <a
                                            row-id={k1}
                                            className="edit mr-2"
                                            title="Download"
                                            data-toggle="tooltip"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={file}
                                            download
                                        >
                                            <i className="fad fa-download mr-2"></i>
                                        </a>
                                        <a
                                            row-id={file}
                                            className="delete"
                                            title="Delete"
                                            data-toggle="tooltip"
                                            href="#!"
                                            onClick={(e) => {
                                                if (
                                                    window.confirm(
                                                        "Are you sure you wish to delete this item?"
                                                    )
                                                ) {
                                                    const filteredItems = files.filter(
                                                        (i) => i !== file
                                                    );
                                                    console.log(
                                                        file,
                                                        filteredItems
                                                    );
                                                    onUpdate(
                                                        filteredItems,
                                                        true
                                                    );
                                                }
                                            }}
                                        >
                                            <i
                                                className={`fa fa-trash text-danger`}
                                            ></i>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        );
    }
}

export default FileManager;
