import React, { Component } from "react";
import moment from "moment-timezone";
import FormElement from "components/Form/FormElement";
import Select from "react-select";
import {
    A4P_CONSULTATION_NAMES,
    APP_CURRENCY,
    APP_CONSULTATION_TYPE_NOW_PHONE,
    APP_CONSULTATION_TYPE_CLINIC,
    APP_CONSULTATION_TYPE_VIDEO,
    APP_CONSULTATION_TYPE_TELEPHONE,
    APP_TIMEZONE,
} from "data/config/variables";
import { DAYS2, TIMEZONES } from "components/Roster/constants";
import { _retBlank, getConsultationName, getConsultationDescr } from "utils";
import isEmpty from "utils/isEmpty";
import ReactDatePicker from "react-datepicker";

export default class ManageLiConsultation extends Component {
    constructor(params) {
        super(params);

        this.validator = this.props.validator;
        this.initialConsultation = [
            { status: false, type: APP_CONSULTATION_TYPE_NOW_PHONE },
            { status: false, type: APP_CONSULTATION_TYPE_CLINIC, schedule: [{from: "09:00", to: "18:00"}] },
            { status: false, type: APP_CONSULTATION_TYPE_VIDEO, schedule: [{from: "15:00", to: "21:00"}] },
            { status: false, type: APP_CONSULTATION_TYPE_TELEPHONE, schedule: [{from: "17:00", to: "21:00"}] },
        ];
        this.emptyConsultation = { status: true, schedule: [{}] };

        const { data } = this.props;
        const { consultations, timezone } = data || {};

        this.state = {
            timezone: APP_TIMEZONE,
            consultations: consultations || [...this.initialConsultation],
        };
        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate() {
        const { timezone, consultations } = this.state;
        this.props.onUpdate({ timezone, consultations });
    }

    render() {
        const { timezone, consultations } = this.state;

        return (
            <div>
                <div className="">
                    {consultations &&
                        consultations.map((c, k) => (
                            <div
                                key={k}
                                className="theme-bg-primary p-4 d-flex justify-content-between align-items-start mt-4 rounded"
                            >
                                <div className="flex-fill mr-5">
                                    <h5 className="">
                                        {getConsultationName(c.type)}
                                    </h5>
                                    <div className="pt-2 custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`switch${k}`}
                                            checked={c.status}
                                            onChange={(e) => {
                                                const sched = [
                                                    ...consultations,
                                                ];
                                                sched[k]["status"] = !sched[k][
                                                    "status"
                                                ];
                                                this.setState(
                                                    {
                                                        consultations: [
                                                            ...sched,
                                                        ],
                                                    },
                                                    this.onUpdate
                                                );
                                            }}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={`switch${k}`}
                                        >
                                            {c.status
                                                ? "Enabled."
                                                : "Not Offered"}
                                        </label>
                                    </div>

                                    {c.status && (
                                        <div className="mt-3">
                                            <div className="d-flex justify-content-start">
                                                <FormElement
                                                    className="flex-fill mr-5"
                                                    name="price"
                                                    label="Session Price"
                                                    placeHolder={`Price in ${APP_CURRENCY} / session`}
                                                    value={c.price}
                                                    onChange={(e) => {
                                                        const sched = [
                                                            ...consultations,
                                                        ];
                                                        sched[k][
                                                            "price"
                                                        ] = e.target.value.trim();
                                                        this.setState({
                                                            consultations: [
                                                                ...sched,
                                                            ],
                                                        });
                                                    }}
                                                    validator={this.validator}
                                                    validation="required|numeric"
                                                />

                                                {c.type !==
                                                    APP_CONSULTATION_TYPE_NOW_PHONE && (
                                                    <FormElement
                                                        className="flex-fill ml-5"
                                                        name="duration"
                                                        label="Session Duration"
                                                        placeHolder="Duration in minutes"
                                                        value={c.duration}
                                                        onChange={(e) => {
                                                            const sched = [
                                                                ...consultations,
                                                            ];
                                                            sched[k][
                                                                "duration"
                                                            ] = e.target.value.trim();
                                                            this.setState({
                                                                consultations: [
                                                                    ...sched,
                                                                ],
                                                            });
                                                        }}
                                                        validator={
                                                            this.validator
                                                        }
                                                        validation="required|numeric"
                                                    />
                                                )}
                                            </div>

                                            {c.type !==
                                                APP_CONSULTATION_TYPE_NOW_PHONE && (
                                                <div className="form-group">
                                                    <label className="text-muted">
                                                        Schedule:{" "}
                                                    </label>

                                                    {isEmpty(c.schedule) && (
                                                        <p className="mb-3 h6">
                                                            No schedules are
                                                            added. Click below
                                                            to add schedule.
                                                        </p>
                                                    )}

                                                    {c.schedule &&
                                                        c.schedule.map(
                                                            (s, k1) => (
                                                                <div
                                                                    key={k1}
                                                                    className="d-flex align-items-center"
                                                                >
                                                                    <FormElement className="flex-fill mr-4">
                                                                        <div title="Select Days">
                                                                            <label className="text-muted">
                                                                                Select
                                                                                Days:{" "}
                                                                            </label>
                                                                            <Select
                                                                                name="days"
                                                                                options={
                                                                                    DAYS2
                                                                                }
                                                                                isMulti={
                                                                                    true
                                                                                }
                                                                                closeMenuOnSelect={
                                                                                    false
                                                                                }
                                                                                placeholder="Select Day(s)"
                                                                                value={DAYS2.map(
                                                                                    (
                                                                                        op
                                                                                    ) =>
                                                                                        s.days &&
                                                                                        s.days.includes(
                                                                                            op.value
                                                                                        )
                                                                                            ? op
                                                                                            : false
                                                                                )}
                                                                                onChange={(
                                                                                    sel
                                                                                ) => {
                                                                                    const sched = [
                                                                                        ...consultations,
                                                                                    ];
                                                                                    sched[
                                                                                        k
                                                                                    ][
                                                                                        "schedule"
                                                                                    ][
                                                                                        k1
                                                                                    ][
                                                                                        "days"
                                                                                    ] =
                                                                                        sel &&
                                                                                        sel.length >
                                                                                            0
                                                                                            ? sel.map(
                                                                                                  (
                                                                                                      i
                                                                                                  ) =>
                                                                                                      i.value
                                                                                              )
                                                                                            : "";
                                                                                    this.setState(
                                                                                        {
                                                                                            consultations: [
                                                                                                ...sched,
                                                                                            ],
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {this.validator.message(
                                                                            "days",
                                                                            s.days,
                                                                            "required"
                                                                        )}
                                                                    </FormElement>

                                                                    <div
                                                                        style={{
                                                                            width:
                                                                                "18%",
                                                                        }}
                                                                    >
                                                                        <FormElement>
                                                                            <div title="Select Days">
                                                                                <label className="text-muted w-100">
                                                                                    From
                                                                                    Time:{" "}
                                                                                </label>
                                                                                <ReactDatePicker
                                                                                    className="px-2 py-1 w-100" 
                                                                                    selected={moment(
                                                                                        s.from || "09:00",
                                                                                        "HH:mm"
                                                                                    ).toDate()}
                                                                                    onChange={(
                                                                                        selectedDate
                                                                                    ) => {
                                                                                        const sched = [
                                                                                            ...consultations,
                                                                                        ];
                                                                                        sched[
                                                                                            k
                                                                                        ][
                                                                                            "schedule"
                                                                                        ][
                                                                                            k1
                                                                                        ][
                                                                                            "from"
                                                                                        ] = moment(
                                                                                            selectedDate
                                                                                        ).format(
                                                                                            "HH:mm"
                                                                                        );
                                                                                        this.setState(
                                                                                            {
                                                                                                consultations: [
                                                                                                    ...sched,
                                                                                                ],
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={
                                                                                        15
                                                                                    }
                                                                                    timeCaption="Time"
                                                                                    dateFormat="h:mm aa"
                                                                                />
                                                                            </div>
                                                                        </FormElement>
                                                                    </div>

                                                                    <div className="mt-2 mx-2">
                                                                        --to--
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            width:
                                                                                "18%",
                                                                        }}
                                                                    >
                                                                        <FormElement>
                                                                            <div title="Select Days">
                                                                                <label className="text-muted w-100">
                                                                                    End
                                                                                    Time:{" "}
                                                                                </label>
                                                                                <ReactDatePicker
                                                                                    className="px-2 py-1 w-100" 
                                                                                    selected={moment(
                                                                                        s.to || "18:00",
                                                                                        "HH:mm"
                                                                                    ).toDate()}
                                                                                    onChange={(
                                                                                        selectedDate
                                                                                    ) => {
                                                                                        const sched = [
                                                                                            ...consultations,
                                                                                        ];
                                                                                        sched[
                                                                                            k
                                                                                        ][
                                                                                            "schedule"
                                                                                        ][
                                                                                            k1
                                                                                        ][
                                                                                            "to"
                                                                                        ] = moment(
                                                                                            selectedDate
                                                                                        ).format(
                                                                                            "HH:mm"
                                                                                        );
                                                                                        this.setState(
                                                                                            {
                                                                                                consultations: [
                                                                                                    ...sched,
                                                                                                ],
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={
                                                                                        15
                                                                                    }
                                                                                    timeCaption="Time"
                                                                                    dateFormat="h:mm aa"
                                                                                />
                                                                            </div>
                                                                        </FormElement>
                                                                    </div>

                                                                    {k1 !==
                                                                        0 && (
                                                                        <div className="mt-2">
                                                                            <a
                                                                                className="h4 text-danger ml-3"
                                                                                href="#!"
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.preventDefault();
                                                                                    const sched = [
                                                                                        ...consultations,
                                                                                    ];
                                                                                    sched[
                                                                                        k
                                                                                    ][
                                                                                        "schedule"
                                                                                    ].splice(
                                                                                        k1,
                                                                                        1
                                                                                    );
                                                                                    this.setState(
                                                                                        {
                                                                                            consultations: [
                                                                                                ...sched,
                                                                                            ],
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-minus-circle"></i>
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        )}

                                                    <div className="mt-3">
                                                        <button
                                                            className="btn btn-secondary px-4"
                                                            onClick={(e) => {
                                                                const sched = [
                                                                    ...consultations,
                                                                ];
                                                                if (
                                                                    !sched[k][
                                                                        "schedule"
                                                                    ]
                                                                )
                                                                    sched[k][
                                                                        "schedule"
                                                                    ] = [];
                                                                sched[k][
                                                                    "schedule"
                                                                ].push({});
                                                                this.setState({
                                                                    consultations: [
                                                                        ...sched,
                                                                    ],
                                                                });
                                                            }}
                                                        >
                                                            <i className="fa fa-plus mr-2"></i>
                                                            Add Schedule
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="legal-note mb-3 text-light">
                                                {getConsultationDescr(c.type)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}
