import React, { Component } from "react";
import { Alert } from "reactstrap";
import { sendContactForm } from "page/network";

export default class PubContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            sent: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onFormSubmit(e) {
        e.preventDefault();
        const { name, email, message } = this.state;
        sendContactForm({name, email, message})
            .then((r) => this.setState({ sent: true }))
            .catch((err) => console.log(err));
    }

    render() {
        const { name, email, message, sent } = this.state;
        return (
            <div>
                <div>
                    <div className="page-header text-center py-5">
                        <div className="container">
                            <h2 className="page-title pt-2 mb-3 font-weight-bold">
                                Contact Us
                            </h2>
                            <div className="page-intro single-col-max-width mx-auto mb-5">
                                Want to get in touch? You can either email us at{" "}
                                <a href="mailto:hello@zdoc.in">hello@zdoc.in</a>{" "}
                                or use the contact form below.
                                <br />
                                You can also reach us on our Whatsapp Number
                                +91-888-448-3300. We have a help section under
                                the profile menu when you log into your portal
                                where you can checkout videos.
                            </div>
                            <h5 className="mb-3">Follow Us</h5>
                            <ul className="social-list list-inline mb-3">
                                <li className="list-inline-item">
                                    <a href="#!">
                                        <i className="fab fa-twitter fa-fw fa-lg" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#!">
                                        <i className="fab fa-facebook-f fa-fw fa-lg" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#!">
                                        <i className="fab fa-instagram fa-fw fa-lg" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#!">
                                        <i className="fab fa-youtube fa-fw fa-lg" />
                                    </a>
                                </li>
                            </ul>
                            {/*//social-list*/}
                        </div>
                        {/*//container*/}
                    </div>
                    {/*//page-header*/}
                    <section className="contact-form-section pb-5">
                        <div className="container pb-5">
                            <div className="form-wrapper shadow-lg single-col-max-width mx-auto p-5">
                                <form
                                    onSubmit={this.onFormSubmit}
                                    id="contact-form"
                                    className="contact-form"
                                    method="post"
                                >
                                    <h3 className="text-center mb-4">
                                        Contact Form
                                    </h3>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label
                                                className="sr-only"
                                                htmlFor="cname"
                                            >
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cname"
                                                name="name"
                                                placeholder="Name"
                                                minLength={2}
                                                required
                                                aria-required="true"
                                                onChange={this.onChange}
                                                value={name}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label
                                                className="sr-only"
                                                htmlFor="cemail"
                                            >
                                                Phone/Email
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cemail"
                                                name="email"
                                                placeholder="Phone/Email"
                                                required
                                                onChange={this.onChange}
                                                aria-required="true"
                                                value={email}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <label
                                                className="sr-only"
                                                htmlFor="cmessage"
                                            >
                                                Your message
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="cmessage"
                                                name="message"
                                                placeholder="Enter your message"
                                                rows={10}
                                                required
                                                aria-required="true"
                                                onChange={this.onChange}
                                                value={message}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <button
                                                type="submit"
                                                className="btn btn-block btn-primary py-2"
                                            >
                                                Send It
                                            </button>
                                        </div>
                                        {sent && <div className="col-12 text-center">
                                            <Alert
                                                className="btn btn-block"
                                                color="success"
                                            >
                                                Message sent successfully!
                                            </Alert>
                                        </div>}
                                    </div>
                                    {/*//form-row*/}
                                </form>
                            </div>
                        </div>
                        {/*//container*/}
                    </section>
                    {/*//contact-form-section*/}
                </div>
            </div>
        );
    }
}
