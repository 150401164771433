import React, { Component } from "react";
import PrescriptionManageTemplates from "./manageList";
import PrescriptionSelectTemplates from "./selectList";

export default class PrescriptionTemplatesIndex extends Component {
    render() {
        const { mode } = this.props;
        return (
            <div>
                {mode === "select" ? (
                    <PrescriptionSelectTemplates {...this.props} />
                ) : (
                    <PrescriptionManageTemplates {...this.props} />
                )}
            </div>
        );
    }
}
