import React, { Component } from "react";
import { APP_EXOTEL_NUMBER } from "data/config/variables";

export default class RTCTeleConsult extends Component {
    render() {
        const number = APP_EXOTEL_NUMBER;
        const {bookingId, isVendor} = this.props;

        return (
            <div className="my-3 mx-4 text-center">
                <div className="d-flex theme-bg-primary py-3 px-4 mb-1 align-items-center justify-content-center">
                    <div className="">
                        To start the Telephonic Consultation dial 
                        <strong> {number}</strong> and enter ID as
                        <strong> {bookingId}</strong>
                    </div>
                    <a
                        href={`tel: ${number}`}
                        className="btn btn-outline-success px-4 ml-4 "
                    >
                        Call {isVendor? "User":"Doctor"}
                    </a>
                </div>
                <small className="text-muted mt-4">Note: This virtual number is valid only for the duration of the appointment.</small>
            </div>
        );
    }
}
