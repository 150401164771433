import React, { Component } from "react";
import PropTypes from "prop-types";

import SimpleReactValidator from "simple-react-validator";
import { login, forgotPW } from "./network";
import { A4P_ROLE_USER } from "data/config/variables";
import FormElement from "components/Form/FormElement";

class ForgotPW extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.validator = new SimpleReactValidator({});
    this.state = {
      email: "",
      password: "",
      submitBtnTxt: "Reset",
      thankyou: false,
      role: this.props.role
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value.trim() });
  }

  onFormSubmit(e) {
    e.preventDefault();        
    if (this.validator.allValid()) {

      this.setState({
        submitBtnTxt: "Please Wait...",
        thankyou: false,
        serverErrors: false,
        serverErrorMessage: ""
      });

      forgotPW(this.state)
        .then(r => {
          console.log(r);
          this.setState({
            submitBtnTxt: "Submit",
            thankyou: true
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            submitBtnTxt: "Submit",
            serverErrors: true,
            serverErrorMessage: "Invalid email or password."
          });
        });

    } else {
      this.validator.showMessages();
      this.forceUpdate(); // [validator] rerender to show messages for the first time
    }
  }

  render() {
    const warningBlock = () => (
      <div className="alert alert-danger">
        <strong className="mr-2">Error!</strong> {this.state.serverErrorMessage}
      </div>
    );

    const successBlock = () => (
      <div className="alert alert-success">
        <strong>
          Please check your inbox for further instructions on resetting your password.
        </strong>
      </div>
    );

    return (
      <div>
        <h4 className="text-center mb-4">Forgot password?</h4>
        <div className="box_form">
          <div>
            {this.state.thankyou && successBlock()}
            {this.state.serverErrors && warningBlock()}
            <form id="vlogin" method="post" onSubmit={this.onFormSubmit}>
              <div className="row">
                <FormElement
                  name="email"
                  className="col-12"
                  placeHolder="Email"
                  state={this.state}
                  onChange={this.onChange}
                  validator={this.validator}
                  validation="required|email"
                />

                <div className="col-12 form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary theme-btn mx-auto "
                    value={this.state.submitBtnTxt}
                  >
                    {this.state.submitBtnTxt}
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>
        <div className="text-center link_bright mt-2 mb-5">
          <span>
            <a className="text-light" href="#!" onClick={() => this.props.onLoginClick()}>
              <i className="fa fa-long-arrow-alt-left mr-2"></i>
              Go Back
            </a>
          </span>
        </div>
      </div>
    );
  }
}

ForgotPW.defaultProps = {
  role: A4P_ROLE_USER
};

ForgotPW.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  role: PropTypes.string
};

export default ForgotPW;
