import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

class PageTabsSimple extends Component {
    constructor(props) {
        super(props);
        this.settingsMenu = [];
    }

    render() {
        const { menu } = this.props;
        const pt = this.props.location.pathname;
        let sel = menu.find((m) => m.path === pt);
        if (!sel) sel = menu[0];

        return (
            <div>
                <ul className="nav justify-content-start navbar align-items-start border-bottom mt-4" style={{background: "#fafafa"}}>
                    {menu.map((m, k) => (
                        <li
                            key={k}
                            className={`mr-3 nav-item  ${
                                sel.path === m.path ? "active" : ""
                            }`}
                        >
                            <Link className="nav-link" to={m.path}>
                                {m.title}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="py-2">{sel.render()}</div>
            </div>
        );
    }
}

export default withRouter(PageTabsSimple);
