import React, { Component } from "react";
import Auth, { AuthModes } from "components/Auth";
import { A4P_ROLE_VENDOR } from "data/config/variables";
import { Link } from "react-router-dom";
import { SiteLogo } from "components/logo";

export default class PubClinicLogin extends Component {
    render() {
        const mode = this.props.location.search.includes("forgot=")
            ? AuthModes.changePW
            : AuthModes.login;

        return (
            <div className="theme-bg-primary no-bg-xs pt-3 pb-5" >
                <div className="login-section auth-section">
                    <div className="auth-wrapper mx-auto my-md-5 py-4 px-5 shadow-lg bg-white">
                        <h1 className="site-logo mb-3 text-center">
                            <SiteLogo />
                        </h1>
                        <h2 className="auth-heading mb-4 text-center">
                            Login to your Clinic Account
                        </h2>
                        <div className="auth-intro mb-4 text-center">
                            Try ZDOC for free. No credit card needed.
                        </div>
                        <div className="legal-note mb-5 text-light text-center">
                            Note: If you are a patient,
                            <Link to="/login" className="ml-1 text-light">
                                please click here.
                            </Link>
                        </div>

                        <div className="auth-form-container text-left mx-auto">
                            <Auth
                                mode={mode}
                                role={A4P_ROLE_VENDOR}
                                showSignUpLink={false}
                            />
                        </div>

                        {/*//auth-form-container*/}
                        <div className="option-container my-4 pt-3 text-center">
                            <div className="lead-text">
                                Don't have an account?{" "}
                                <Link to="/clinic/signup">Sign up</Link>
                            </div>
                        </div>
                        {/*//option-container*/}
                    </div>
                    {/*//auth-wrapper*/}
                </div>
                {/*//signup-section*/}
            </div>
        );
    }
}
