import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

import { getUser, getSubDomain, isSMDevice } from "utils";
import { logoutUser } from "utils/LoginChecker";
import "bootstrap/dist/js/bootstrap.min.js";
import * as qs from "query-string";
import { SiteLogo } from "components/logo";
import { A4P_ROLE_VENDOR } from "data/config/variables";

class Header extends Component {
    constructor(params) {
        super(params);
        const user = getUser();
        this.state = {
            user,
            userPic: user.pic || "/assets/img/user-placeholder.jpg",
            subDomain: getSubDomain(),
        };

        // public menu
        this.pubMenu = [
            {
                path: "/",
                title: "Home",
            },
            {
                path: "/features",
                title: "Features",
            },
            {
                path: "/pricing",
                title: "Pricing",
            },
            /*             {
                path: "/about",
                title: "About",
            },*/
            {
                path: "/faq",
                title: "FAQs",
            },
            {
                path: "/contact",
                title: "Contact",
            },
            {
                path: "/clinic/login",
                title: "Log in",
            },
        ];

        // sub domain menu
        this.subMenu = [
            {
                path: "/",
                title: "Home",
            },
            {
                path: "/about",
                title: "About",
            },
            {
                path: "/gallery",
                title: "Gallery",
            },
            {
                path: "/contact",
                title: "Contact",
            },
            {
                path: "/login",
                title: "Log in",
            },
        ];

        // user menu
        this.userMenu = [
            {
                path: "/app/my-appointments",
                icon: "fa-calendar-check",
                title: "My Appointments",
            },
            {
                path: "/app/medical-records",
                icon: "fa-files-medical",
                title: "Medical Records",
            },
            {
                path: "/app/prescriptions",
                icon: "fa-pills",
                title: "Prescriptions",
            },
            {
                path: "/app/my-profile",
                icon: "fa-id-card",
                title: "My Profile",
            },
        ];

        // vendor menu
        this.vendorMenu = [
            /* { path: "/app", icon: "dashboard", title: "Dashboard" }, */
            {
                path: "/app/appointments",
                icon: "fa-calendar-check",
                title: "Appointments",
            },
            {
                path: "/app/customers",
                icon: "fa-users-medical",
                title: "Customers",
            },
            { path: "/app/reports", icon: "fa-chart-line", title: "Reports" },
            { path: "/app/settings", icon: "fa-cogs", title: "Settings" },
        ];

        if (isSMDevice()) {
            const logout = {
                path: "/logout",
                icon: "not_interested",
                title: "Logout",
            };
            this.vendorMenu.push(logout);
            this.userMenu.push(logout);
        }
    }

    render() {
        const { user, userPic } = this.state;
        const pt = this.props.location.pathname;
        const subDomain = getSubDomain();

        // redirect to booking page
        const z = qs.parse(this.props.location.search).z;
        if (z) return <Redirect to={{ pathname: "/appointments", z }} />;

        // populate main / subdomain menu
        const pubMenu = subDomain ? this.subMenu : this.pubMenu;

        // populate user/vendor menu
        const menu = user.role === "vendor" ? this.vendorMenu : this.userMenu;

        // redirect to app page if the user is logged in
        if (user.id && pt === "/") return <Redirect to="/app" />;

        // skip rendering header for certain keywords
        const exclKeywords = [
            "login",
            "signup",
            "onboarding",
            "landing",
            "snip",
            "Z",
            "1",
            "P",
        ];
        let ki,
            showHeader = true;
        for (ki of exclKeywords) {
            let regex = new RegExp(`${ki}.*`);
            if (Boolean(pt.match(regex))) {
                showHeader = false;
                break;
            }
        }

        return (
            <div>
                {showHeader && (
                    <header className="header">
                        <div className="branding">
                            <div className="container position-relative">
                                <nav className="navbar navbar-expand-lg">
                                    <h1 className="site-logo">
                                        <SiteLogo />
                                    </h1>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#navigation"
                                        aria-controls="navigation"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span className="navbar-toggler-icon" />
                                    </button>
                                    <div
                                        className="collapse navbar-collapse p-1 p-lg-0"
                                        id="navigation"
                                    >
                                        <ul
                                            id="mainNav"
                                            className="navbar-nav ml-lg-auto"
                                        >
                                            {user.id ? (
                                                <li className="nav-item dropdown">
                                                    <a
                                                        id="myAccount"
                                                        href="#!"
                                                        className="nav-link dropdown-toggle "
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        style={{
                                                            marginTop: "-5px",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "40px",
                                                                borderRadius:
                                                                    "50%",
                                                            }}
                                                            className="img-responsive"
                                                            src={userPic}
                                                        />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right mt-1 mr-1">
                                                        <div
                                                            className="px-2 text-center text-muted"
                                                            style={{
                                                                minWidth:
                                                                    "210px",
                                                            }}
                                                        >
                                                            <div className="">
                                                                {`${user.first_name} ${user.last_name}`}
                                                            </div>
                                                            <small>
                                                                {user.email ||
                                                                    user.phone}
                                                            </small>
                                                        </div>
                                                        <div className="dropdown-divider" />
                                                        <Link
                                                            className="dropdown-item"
                                                            to="/app/my-profile"
                                                        >
                                                            <i className="fad fa-user-md mr-2"></i>{" "}
                                                            My Profile
                                                        </Link>
                                                        {user.role ===
                                                            A4P_ROLE_VENDOR && (
                                                            <a
                                                                className="dropdown-item"
                                                                href="https://zdoc.helpsite.com"
                                                                target="_blank"
                                                            >
                                                                <i className="fad fa-life-ring mr-2"></i>{" "}
                                                                Help
                                                            </a>
                                                        )}
                                                        <a
                                                            className="dropdown-item"
                                                            href="#!"
                                                            onClick={() =>
                                                                logoutUser()
                                                            }
                                                        >
                                                            <i className="fad fa-sign-out-alt mr-2"></i>{" "}
                                                            Logout
                                                        </a>
                                                    </div>
                                                </li>
                                            ) : (
                                                pubMenu.map((m, k) => (
                                                    <li
                                                        key={k}
                                                        className={`nav-item mr-lg-4 ${
                                                            pt === m.path
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Link
                                                            className="nav-link"
                                                            to={m.path}
                                                        >
                                                            {m.title}
                                                        </Link>
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            {/*//container*/}
                        </div>

                        {user.id && (
                            <div className="theme-bg-primary">
                                <ul className="nav  container navbar justify-content-start">
                                    {menu.map((m, k) => (
                                        <li
                                            key={k}
                                            className={`nav-item mr-lg-4 ${
                                                pt.includes(m.path)
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            <Link
                                                className="nav-link"
                                                to={m.path}
                                            >
                                                <i
                                                    className={`fad ${m.icon} mr-2`}
                                                ></i>
                                                {m.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </header>
                )}
            </div>
        );
    }
}

export default withRouter(Header);
