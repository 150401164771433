import React, { Component } from "react";
import FormElement from "components/Form/FormElement";
import SimpleReactValidator from "simple-react-validator";
import { setDomainDetails, getDomainDetails } from "./network";
import Button from "components/Form/Button";
import { GQL_ERROR_DUPLICATE } from "utils/HasuraParser";
import { getDomainName } from "utils";
import config from "data/config";
import ConfigureSubDomain from "./subDomain";

export default class SettingsDomainsIndex extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validator = new SimpleReactValidator({});

        this.state = {
            recordId: null,
            custom_domain: null,
            sub_domain: null,
            updating: false,
            success: false,
            error: null,
        };
    }

    componentDidMount() {
        getDomainDetails()
            .then((r) => {
                const [site] = r.data.websites;
                const { id, sub_domain, custom_domain } = site || {};
                this.setState({ recordId: id, sub_domain, custom_domain });
            })
            .catch((r) => console.log(r));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onFormSubmit() {
        if (this.validator.allValid()) {
            const { recordId, sub_domain, custom_domain } = this.state;
            this.setState({ updating: true, success: false });
            setDomainDetails(
                recordId,
                getDomainName(sub_domain),
                getDomainName(custom_domain, true, true)
            )
                .then((r) => this.setState({ updating: false, success: true }))
                .catch((err) => {
                    console.log(err);
                    if (err === GQL_ERROR_DUPLICATE) {
                        this.setState({
                            updating: false,
                            error: "The domain is already taken!",
                        });
                    }
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
        return false;
    }

    render() {
        const {
            sub_domain,
            custom_domain,
            updating,
            success,
            error,
        } = this.state;
        const siteUrl = getDomainName(config.SITE_URL, true);

        return (
            <div>
                <div className="mb-5">
                    <div className="text-muted p-2 h6 mb-3 border-bottom">
                        Your Domains:
                    </div>
                    {success && (
                        <p className="mx-3 alert alert-success">
                            Updated successfully!
                        </p>
                    )}
                    {error && (
                        <p className="mx-3 alert alert-danger">{error}</p>
                    )}
                    <div>
                        <div className="col mt-4">
                            <ConfigureSubDomain
                                validator={this.validator}
                                subDomain={sub_domain}
                                onChange={(sub_domain) =>
                                    this.setState({ sub_domain })
                                }
                            />
                        </div>

                        <FormElement
                            className="col-10 mt-5"
                            label="Custom Domain"
                            placeHolder="ex: www.happyclinics.com"
                            value={custom_domain}
                            name="custom_domain"
                            onChange={this.onChange}
                            validator={this.validator}
                            validation=""
                        />
                        <div className="col col-11 theme-bg-primary ml-3 py-2 text-muted legal-note mb-1">
                            You can choose your own custom domain as your Zdoc URL. <br/>
                            After you choose a domain name, we will provide you instructions for setting up CNAME DNS records.
                        </div>

                        <div className="mt-5">
                            <label htmlFor="" className="col-10 text-muted">
                                For Embedding on Existing Websites:
                            </label>
                            <div className="col col-11 theme-bg-primary ml-3 py-2 text-muted legal-note mb-1">
                                <p>
                                    This code can be added to any HTML website
                                    or blog such as Wordpress.{" "}
                                </p>
                                <p>
                                    {`<iframe src="https://${sub_domain}.${siteUrl}/snip" height="100%" width="100%" scrolling="auto"></iframe>`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col my-3">
                    <Button
                        btnCls="btn-outline-success px-5 theme-btn"
                        iconCls="fa-save"
                        value="Update"
                        onClick={this.onFormSubmit}
                        loading={updating}
                    />
                </div>
            </div>
        );
    }
}
