import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { APP_CURRENCY_SYMBOL } from "data/config/variables";
import RazorPayCheckout from "components/RazorPay";
import { getUser } from "utils";

class SettingsAccountIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const user = getUser();
        const { first_name, last_name, email, phone } = user;

        return (
            <div>
                <div className="mb-5">
                    <div className="text-muted p-2 h6 mb-3 border-bottom">
                        Your Current Plan:
                    </div>
                    <h5 className="ml-2">Free Forever Plan</h5>

                    <div className="text-muted p-2 h6 my-5 border-bottom">
                        Add Ons:
                    </div>

                    <div className=" section pricing-section">
                        <div className="container">
                            <div className="row pricing-table ">
                                <div className="pricing-item col-12 col-lg-5 mb-5 ">
                                    <div className="pricing-item-inner shadow-lg p-4 rounded h-100">
                                        <div className="pricing-item-header text-center">
                                            <h3 className="mb-0 pricing-plan-name text-primary font-weight-bold">
                                                Digital Booster Pack
                                            </h3>
                                            <div className="mb-3">
                                                (inclusive of GST)
                                            </div>
                                            <h4 className="price display-4 font-weight-light">
                                                <span className="price-currency">
                                                    {APP_CURRENCY_SYMBOL}
                                                </span>
                                                <span className="price-number">
                                                    1899
                                                </span>
                                            </h4>
                                            <div>
                                                <RazorPayCheckout
                                                    amount={1899 * 100}
                                                    actionTxt="Buy Now"
                                                    brandName="ZDOC Technologies"
                                                    brandLogo="/assets/images/logo-icon.svg"
                                                    name={`${first_name} ${last_name}`}
                                                    email={email}
                                                    phone={phone}
                                                    onComplete={(r) => {
                                                        console.log(r);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/*//pricing-item-header*/}
                                        <hr />
                                        <div className="pricing-item-body">
                                            <ul className="list-unstyled">
                                                <li className="mb-2">
                                                    <i className="fas fa-check-circle mr-2 " />
                                                    Domain name of your choice
                                                </li>
                                                <li className="mb-2">
                                                    <i className="fas fa-check-circle mr-2 " />
                                                    DNS configurations
                                                </li>
                                                <li className="mb-2">
                                                    <i className="fas fa-check-circle mr-2 " />
                                                    Website Content Creation
                                                </li>
                                                <li className="mb-2">
                                                    <i className="fas fa-check-circle mr-2 " />
                                                    SEO Optimization as per your
                                                    Niche
                                                </li>
                                                <li className="mb-2">
                                                    <i className="fas fa-check-circle mr-2 " />
                                                    Google Search Console
                                                    Optimization
                                                </li>
                                                <li className="mb-2">
                                                    <i className="fas fa-check-circle mr-2 " />
                                                    Google Maps Local Business
                                                    Optimization
                                                </li>
                                                <li className="mb-2">
                                                    <i className="fas fa-check-circle mr-2 " />
                                                    Facebook Local Business
                                                    Setup
                                                </li>
                                            </ul>
                                        </div>
                                        {/*//pricing-item-body*/}
                                    </div>
                                    {/*//pricing-item-inner*/}
                                </div>
                                {/*//pricing-item*/}
                            </div>
                            {/*//pricing-tabel*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SettingsAccountIndex);
