import store from "data/store";
import {
    A4P_SERVICE_NAMES,
    vet_doc_specialities,
    vet_doc_services,
    pet_types,
    APP_CONSULTATION_NAMES
} from "data/config/variables";
import { setCheckoutPayload } from "data/actions/configActions";
import { DIRECTIONS, DURATIONS } from "page/vendor/customers/list/details/prescriptions/drugseditor/drugs";

// get current user id
export const getUserId = () => {
    if (store.getState().auth.user) return store.getState().auth.user.id;
    return 0;
};

// get vendor id
export const getVendorId = () => {
    if (store.getState().auth.user) return store.getState().auth.user.vendor_id;
    return 0;
};

// get current user email
export const getUserEmail = () => {
    if (store.getState().auth.user) return store.getState().auth.user.email;
    return null;
};

// get current user role
export const getUserRole = () => {
    if (store.getState().auth.user) return store.getState().auth.user.role;
    return null;
};

export const getSubRole = () => {
    if (store.getState().auth.user) return store.getState().auth.user.subrole;
    return null;
};

// get current user
export const getUser = () => {
    if (store.getState().auth.user) return store.getState().auth.user;
    return null;
};

// get subdomain info
export const getSubDomain = () => {
    return store.getState().config.SubDomain;
};

export const getPetType = v => {
    let t = pet_types.find(e => e.value === v);
    return t ? t.label : "";
};

export const getVetDocSpeciality = v => {
    let str = "";
    v &&
        v.map(i => {
            let t = vet_doc_specialities.find(e => e.value === i);
            if (t) str = str + (str ? ", " : "") + t.label;
        });
    return str;
};

export const getVetDocService = v => {
    let t = vet_doc_services.find(e => e.value === v);
    return t ? t.label : "";
};

export const getA4PService = v => {
    let t = A4P_SERVICE_NAMES.find(e => e.value === v);
    return t ? t.label : "";
};

export const getDrugDirection = v => {
    let t = DIRECTIONS.find(e => e.value === v);
    return t ? t.label : "";
};

export const getDrugDuration = v => {
    let t = DURATIONS.find(e => e.value === v);
    return t ? t.label : "";
};

// set checkout data
export const setCheckoutData = data => {
    store.dispatch(setCheckoutPayload(data));
};

export const clearCheckoutData = () => {
    setCheckoutData(null);
};

export const _retBlank = v => (v ? v : "");

export const _retNull = v => (v ? v : null);

export const _retFalse = v => (v ? v : false);

export const getConsultationName = v => {
    let t = APP_CONSULTATION_NAMES.find(e => e.value === v);
    return t ? t.label : "";
};

export const getConsultationIcon = v => {
    let t = APP_CONSULTATION_NAMES.find(e => e.value === v);
    return t ? t.icon : "";
};

export const getConsultationDescr = v => {
    let t = APP_CONSULTATION_NAMES.find(e => e.value === v);
    return t ? t.descr : "";
};

export const getConsultationColor = v => {
    let t = APP_CONSULTATION_NAMES.find(e => e.value === v);
    return t ? t.color : "#ccc";
};

export const isSMDevice = () => {
    return window.innerWidth < 850;
};

export const isMDDevice = () => {
    return window.innerWidth > 850;
};

// extract domain name from string
export const getDomainName = (url, includeSubDomiain = false, includeWWW = false) => {
    if(!url) return url;

    if(includeWWW) 
        url = url.replace(/(https?:\/\/)?/i, "");
    else 
        url = url.replace(/(https?:\/\/)?(www.)?/i, "");

    if (!includeSubDomiain) {
        url = url.split(".");
        url = url.slice(url.length - 2).join(".");
    }

    if (url.indexOf("/") !== -1) {
        url = url.split("/")[0];
    }

    return url;
};
