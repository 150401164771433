import React, { Component } from "react";
import FormElement from "components/Form/FormElement";
import SimpleReactValidator from "simple-react-validator";
import { getSiteDetails, setSiteDetails } from "./network";
import Button from "components/Form/Button";
import { GQL_ERROR_DUPLICATE } from "utils/HasuraParser";
import { profile_pic_types } from "data/config/variables";
import ProfilePic from "components/Form/ProfilePic";
import FileManager from "components/Form/FileManager";
import RichTextEditor from "components/Form/RichTextEditor";

export default class SettingsSiteIndex extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validator = new SimpleReactValidator({});

        this.state = {
            recordId: null,
            className: null,
            logo: null,
            pics: [],
            header: false,
            body: false,
            updating: false,
            success: false,
            error: null,
        };
    }

    componentDidMount() {
        getSiteDetails()
            .then((r) => {
                const [site] = r.data.websites;
                const {
                    id: recordId,
                    name,
                    logo,
                    pics,
                    header_section: header,
                    body_section: body,
                } = site || {};
                this.setState({ recordId, name, logo, header, pics, body });
            })
            .catch((r) => console.log(r));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onFormSubmit() {
        if (this.validator.allValid()) {
            const {
                recordId,
                name,
                logo,
                new_header,
                pics,
                header,
                new_body,
                body,
            } = this.state;

            this.setState({ updating: true, success: false });

            setSiteDetails({
                id: recordId,
                name,
                logo,
                pics,
                header: new_header || header,
                body: new_body || body,
            })
                .then((r) => this.setState({ updating: false, success: true }))
                .catch((err) => {
                    console.log(err);
                    if (err === GQL_ERROR_DUPLICATE) {
                        this.setState({
                            updating: false,
                            error: "The domain is already taken!",
                        });
                    }
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
        return false;
    }

    render() {
        const {
            name,
            logo,
            header,
            pics,
            body,
            updating,
            error,
            success,
        } = this.state;
        return (
            <div>
                <div className="mb-5">
                    <div className="text-muted p-2 h6 mb-3 border-bottom">
                        Your Website Content:
                    </div>
                    {error && (
                        <p className="mx-3 alert alert-danger">{error}</p>
                    )}
                    <div className="pt-3 row">
                        <div className="pl-0 col-md-6 col-sm-12">
                            <FormElement
                                className="col-12"
                                label="Clinic Name"
                                placeHolder="ex: Happy Clincs"
                                name="name"
                                value={name}
                                onChange={this.onChange}
                                validator={this.validator}
                                validation="required"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label className="text-muted ">
                                Brand Logo[Optional]:
                            </label>
                            <div className="d-flex mt-2">
                                <ProfilePic
                                    avatar={logo}
                                    type={profile_pic_types.brandLogo}
                                    width={280}
                                    height={120}
                                    onUpload={(avatar) =>
                                        this.setState({ logo: avatar })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <label className="text-muted ">Header Section:</label>
                        <RichTextEditor
                            content={header || ""}
                            // needs to be copied in a separate state to avoid continous updates
                            onContentChange={(content) =>
                                this.setState({ new_header: content })
                            }
                        />
                        

                    </div>
                    <div className="my-5 ">
                        <label className="text-muted ">
                            Gallery(Establishment Images):
                        </label>
                        <div className="mt-2 p-3 border">
                            <FileManager
                                uploadType={
                                    profile_pic_types.vendorServicesListings
                                }
                                files={pics}
                                onUpdate={(files, replace = false) =>
                                    this.setState({
                                        pics: [
                                            ...new Set(
                                                replace
                                                    ? files
                                                    : files.concat(pics || [])
                                            ),
                                        ],
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <label className="text-muted ">About Page: </label>
                        <RichTextEditor
                            content={body || ""}
                            // needs to be copied in a separate state to avoid continous updates
                            onContentChange={(content) =>
                                this.setState({ new_body: content })
                            }
                        />
                    </div>
                    <div className="mt-3 ">
                        <label className="text-muted ">Tips: </label>
                        <div>
                            <a
                                href="https://www.mail-signatures.com/articles/direct-link-to-hosted-image/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                How to generate image url for inserting images
                                above
                            </a>
                        </div>
                    </div>
                </div>

                {/* <p
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        ></p> */}

                {success && (
                    <p className="mx-3 alert alert-success">
                        Updated successfully!
                    </p>
                )}
                <div className=" my-3">
                    <Button
                        btnCls="btn-outline-success px-5 theme-btn"
                        iconCls="fa-save"
                        value="Update"
                        onClick={this.onFormSubmit}
                        loading={updating}
                    />
                </div>
            </div>
        );
    }
}
