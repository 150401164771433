import { gqlSend } from "utils/AxiosUtil";
import { A4P_ROLE_USER } from "data/config/variables";

export const updateUserProfile = (state) => {
    const tblName =
        state.role === A4P_ROLE_USER ? "update_users" : "update_vendor_logins";
    return gqlSend({
        query: `mutation( $id: Int, $fn: String, $ln:String, $email:String, $pic: String)
        {
          ${tblName}(where: {id: {_eq: $id}}, _set: {first_name: $fn, last_name: $ln, email: $email, pic: $pic}) {
            affected_rows
          }
        }`,
        variables: {
            id: state.id,
            fn: state.first_name,
            ln: state.last_name,
            pic: state.pic,
            email: state.email,
        },
    });
};
