import {
    A4P_ROLE_USER,
    A4P_APPOINTMENT_STATUSES,
    A4P_ROLE_VENDOR,
    A4P_VENDOR_TYPE
} from "data/config/variables";
import { gqlSend } from "utils/AxiosUtil";
import { getUserId, getSubRole, getVendorId, getUserRole } from "utils";

export const searchBookings = params => {
    const {
        offset = 0,
        limit = 10,

        status,
        type,
        listing,
        sortBy,
        id,

        user,
        role = getUserRole(),
        vendorId = getVendorId(),
        userId = getUserId(),
        subRole = getSubRole(),

        stCancel = A4P_APPOINTMENT_STATUSES.CANCELED,
        stComplete = A4P_APPOINTMENT_STATUSES.COMPLETE,
        stResched = A4P_APPOINTMENT_STATUSES.RESCHEDULED,
    } = params;
    
    let recs = "";
    switch (status) {
        case A4P_APPOINTMENT_STATUSES.CANCELED:
            recs = `, status: {_eq: ${stCancel}}`;
            break;
        case A4P_APPOINTMENT_STATUSES.RESCHEDULED:
            recs = `, status: {_eq: ${stResched}}`;
            break;
        case A4P_APPOINTMENT_STATUSES.IN_PROGRESS:
            recs = `, _or: [{slot: {_gt: "now()"}}, {slot: {_is_null: true}}], status: {_nin: [${stComplete}, ${stCancel}]}`;
            break;
        case A4P_APPOINTMENT_STATUSES.COMPLETE:
            recs = `, slot: {_lt: "now()"}`;
            break;
        default:
            recs = "";
    }

    let whr = `user_id: {_eq: ${userId}} `;
    if (role === A4P_ROLE_VENDOR) {
        whr = `vendor_id: {_eq: ${vendorId}}
               ${
                    user
                        ? `, user_id: {_eq: ${user.id}}`
                        : ""
               } 
               ${
                   subRole === A4P_VENDOR_TYPE.USER
                       ? `, vendor_listing: {managers: {_contains: [${userId}]}}`
                       : ""
               }`;
    }

    whr = ` ${whr}   
            ${recs}  
            ${listing ? `, vendor_listing_id: {_eq: ${listing}}` : ""}
            ${type ? `, type: {_eq: ${type}}` : ""} 
            ${id ? `, id: {_eq: ${id}}` : ""} `;

    const srt = `${sortBy}: ${sortBy === "slot"? `asc`: `desc`}`;

    const q = {
        query: `{
        bookings(where: {${whr}}, limit: ${limit}, offset: ${offset}, order_by: {${srt}}) {
          id, slot, type, status, created_at, details
        }
      }`
    };

    return gqlSend(q);
};

export const updateAppointmentStatus = (id, status) => {
    return gqlSend({
        query: ` 
            mutation {
                update_bookings(where: {id: {_eq: ${id}}}, _set: {status: ${status}}) {
                    affected_rows
                    }
            }`
    });
};