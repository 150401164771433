import React, { Component } from "react";
import { Modal, ModalBody, Alert } from "reactstrap";
import UsersList from "./list";
import UserDetails from "./details";
import Button from "components/Form/Button";
import VendorCustomersImport from "../import";
import SectionHead from "components/sectionHead";

export default class VendorCustomersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: null,
            modal: false,
        };
    }

    render() {
        const { selectedUser, modal } = this.state;

        const toggle = () => this.setState({ modal: !modal });

        return (
            <div>
                {selectedUser && (
                    <UserDetails
                        user={selectedUser}
                        onGoBack={(e) => this.setState({ selectedUser: null })}
                    />
                )}
                <div
                    style={{
                        display: selectedUser ? "none" : "block",
                    }}
                >
                    <SectionHead title="My Customers" />
                    <div className="text-right mb-4">
                        <Button
                            btnCls="btn-outline-primary px-5 theme-btn"
                            iconCls="fa-plus"
                            value="Add Customer"
                            onClick={() => this.setState({ modal: true })}
                        />
                    </div>
                    <UsersList
                        onSelectRow={(user) =>
                            this.setState({ selectedUser: user })
                        }
                    />
                </div>

                <Modal isOpen={modal} toggle={toggle}>
                    <ModalBody>
                        <VendorCustomersImport />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
