import React, { Component } from "react";
import NoRecords from "components/noRecords";
import SectionHead from "components/sectionHead";
import { getPrescriptions } from "./network";
import isEmpty from "utils/isEmpty";
import ManagePrescription from "./details";
import PrescriptionListingItem from "./listItem";
import SubSectionHead from "components/SubSectionHead";
import { getUserRole } from "utils";
import { A4P_ROLE_USER } from "data/config/variables";

export default class PrescriptionsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            items: [],
            error: false,
            showDetails: false,
        };
        this.onSelect = this.onSelect.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onGoBack = this.onGoBack.bind(this);
    }

    onSelect(data) {
        let item = this.state.items.find((item) => item.id === data.id);
        this.setState({ selectedItem: item, showDetails: true });
    }

    onUpdate(item) {
        const { items } = this.state; 
        const { id } = item;

        let updated = false;
        items.forEach((e, k) => {
            if (e.id === id) {
                items[k] = { ...item };
                updated = true;
                return true;
            }
        });

        if (!updated) items.push(item);

        this.setState({ items });
    }

    onGoBack() {
        this.setState({ selectedItem: null, showDetails: false });
    }

    componentDidMount() {
        const { items } = this.state;
        const { user, booking } = this.props;

        getPrescriptions(user, booking)
            .then((r) => {
                const li = r.data.prescriptions;
                if (!isEmpty(li))
                    this.setState({
                        items: [...items, ...li],
                    });
            })
            .catch((err) => {
                this.setState({ error: true });
                console.log(err);
            });
    }

    render() {
        const { items, selectedItem, error, showDetails } = this.state;
        const role = getUserRole();

        return (
            <div
                className={`${
                    role === A4P_ROLE_USER ? "container py-5" : "py-4"
                }`}
            >
                {showDetails ? (
                    <ManagePrescription
                        onGoBack={this.onGoBack}
                        item={selectedItem}
                        onUpdate={this.onUpdate}
                        user={this.props.user}
                        booking={this.props.booking}
                    />
                ) : (
                    <div className="">
                        {role !== A4P_ROLE_USER && (
                            <SubSectionHead
                                createTxt="New Prescription"
                                onCreateClick={(e) =>
                                    this.setState({ showDetails: true })
                                }
                            />
                        )}

                        {isEmpty(items) ? (
                            <NoRecords />
                        ) : (
                            <div>
                                {error ? (
                                    <div className="container text-center py-5">
                                        Sorry something went wrong. Please try
                                        again later
                                    </div>
                                ) : (
                                    <div>
                                        <div className="row">
                                            {items &&
                                                items.map((item, k) => (
                                                    <div
                                                        key={k}
                                                        className="col-12"
                                                    >
                                                        <PrescriptionListingItem
                                                            item={item}
                                                            onSelect={
                                                                this.onSelect
                                                            }
                                                            onDelete={
                                                                this.onDelete
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
