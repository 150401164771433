import React, { Component } from "react";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { verifyToken } from "./network";
import config from "data/config";
import { Link } from "react-router-dom";

export default class SocialLogin extends Component {
    render() {
        const { btnTxt = "Log in", onSuccess } = this.props;

        return (
            <div>
                <div className="social-auth text-center mx-auto">
                    <ul className="social-buttons list-unstyled">
                        <li className="mb-3">
                            <GoogleLogin
                                clientId={config.GOOGLE_CLIENT_ID}
                                buttonText="Login"
                                onSuccess={(r) => {
                                    if (r.tokenId) {
                                        onSuccess("google", r.tokenId, {
                                            name: r.profileObj.name,
                                            email: r.profileObj.email,
                                            pic: r.profileObj.imageUrl,
                                        });
                                    }
                                }}
                                onFailure={(r) => console.log(r)}
                                cookiePolicy={"single_host_origin"}
                                render={(renderProps) => (
                                    <button
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                        className="btn btn-social btn-google btn-block shadow-sm"
                                    >
                                        <img className="" src="/assets/img/google-signin.png" />
                                    </button>
                                )}
                            />
                        </li>
                        <li className="mb-3">
                            <FacebookLogin
                                appId={config.FACEBOOK_CLIENT_ID}
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={(r) => {
                                    if (r.accessToken)
                                        onSuccess("facebook", r.accessToken, {
                                            name: r.name,
                                            email: r.email,
                                            pic: r.picture.data.url,
                                        });
                                    else console.log(r);
                                }}
                                textButton="Sign in with Facebook"
                                cssClass="btn btn-social btn-facebook btn-block"
                                icon="fab fa-facebook-f fa-fw icon-holder mr-4"
                            />
                        </li>
                    </ul>
                </div>
                <div className="divider my-5">
                    <span className="or-text">OR</span>
                </div>
            </div>
        );
    }
}
