import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import PrescriptionTemplatesIndex from "../templates";
import DrugsEditorIndex from "../drugseditor";
import { Modal, ModalBody, Alert } from "reactstrap";
import Button from "components/Form/Button";
import {
    getPrescriptionHeaderAndFooter,
    setPrescriptionHeaderAndFooter,
    createPrescription,
} from "../network";
import PrescriptionPreview from "./preview";
import SectionHead from "components/sectionHead";
import { getUser } from "utils";
import SubSectionHead from "components/SubSectionHead";
import RichTextEditor from "components/Form/RichTextEditor";

export default class ManagePrescription extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({});

        const vendor = getUser();
        const { user, booking } = this.props;
        this.state = {
            user,
            booking,
            vendor,
            name: user ? `${user.first_name} ${user.last_name}` : "",
            age: "",
            notes: "",
            recordId: null,
            modal: false,
            header: "",
            footer: "",
            drugs: [],
            sending: false,
            sent: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onUpdateHeader = this.onUpdateHeader.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value }, this.onUpdate);
    }

    componentDidMount() {
        getPrescriptionHeaderAndFooter()
            .then((r) => {
                const [site] = r.data.websites;
                const {
                    id: recordId,
                    prescription_header: header,
                    prescription_footer: footer,
                } = site || {};
                this.setState({ recordId, header, footer });
            })
            .catch((r) => console.log(r));
    }

    onUpdateHeader() {
        const { recordId: id, header, footer } = this.state;
        setPrescriptionHeaderAndFooter({ id, header, footer })
            .then((r) => console.log(r))
            .catch((r) => console.log(r));
    }

    onFormSubmit() {
        if (this.validator.allValid()) {
            this.setState({ sending: true });

            const { name, age, notes, header, footer, drugs } = this.state;
            const { user, vendor, booking } = this.state;
            const details = {
                user,
                vendor,
                booking,
                prescription: {
                    name,
                    age,
                    notes,
                    header,
                    footer,
                    drugs,
                },
            };
            const user_id = user ? user.id : null;
            const booking_id = booking ? booking.id : null;

            createPrescription({ details, user_id, booking_id })
                .then((r) => {
                    this.setState({ sending: false, sent: true });
                    const [row] = r.data.insert_prescriptions.returning;
                    this.props.onUpdate({ id: row.id, details });
                })
                .catch((r) => console.log(r));
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const {
            id,
            name,
            age,
            modal,
            notes,
            header,
            footer,
            drugs,
            sending,
            sent,
        } = this.state;
        const { onGoBack } = this.props;

        const inpStyl = {
            border: "none",
            borderBottom: "1px solid #aaa",
            padding: "5px",
        };

        const toggle = () => this.setState({ modal: !modal });

        const { item } = this.props;

        return (
            <div className="">
                <SubSectionHead
                    title={item ? "View Prescription" : "New Prescription"}
                    onGoBack={onGoBack}
                />

                {item ? (
                    <PrescriptionPreview
                        data={{
                            ...item.details.prescription,
                            created_at: item.created_at,
                        }}
                    />
                ) : (
                    <div className="d-flex">
                        <div className="w-75">
                            <div className="shadow-sm p-3 border">
                                <div className="">
                                    <label htmlFor="" className="text-muted">
                                        Header:
                                    </label>
                                    <RichTextEditor
                                        content={header || ""}
                                        onContentChange={(content) => {
                                            this.setState(
                                                { header: content },
                                                this.onUpdateHeader
                                            );
                                        }}
                                    />
                                </div>

                                <div className="my-4 ">
                                    <label htmlFor="" className="text-muted">
                                        Patient Information:
                                    </label>
                                    <div className=" ">
                                        <div>
                                            {this.validator.message(
                                                "name",
                                                name,
                                                "required"
                                            )}
                                            <input
                                                type="text"
                                                className="mr-3"
                                                style={inpStyl}
                                                placeholder="Full Name"
                                                name="name"
                                                value={name}
                                                onChange={this.onChange}
                                            />
                                            <input
                                                type="text"
                                                className="mr-3"
                                                style={inpStyl}
                                                placeholder="Age/Sex"
                                                name="age"
                                                value={age}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="" className="text-muted">
                                        Rx:
                                    </label>
                                    <div className="">
                                        <DrugsEditorIndex
                                            drugs={drugs}
                                            onUpdate={(drugs) =>
                                                this.setState({ drugs })
                                            }
                                        />
                                        {this.validator.message(
                                            "Medicines",
                                            drugs,
                                            "required"
                                        )}
                                    </div>
                                </div>

                                <div className="py-4">
                                    <input
                                        type="text"
                                        className="mr-3 w-100"
                                        style={inpStyl}
                                        placeholder="Notes"
                                        name="notes"
                                        value={notes}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="" className="text-muted">
                                        Footer:
                                    </label>
                                    <RichTextEditor
                                        content={footer || ""}
                                        onContentChange={(content) => {
                                            this.setState(
                                                { footer: content },
                                                this.onUpdateHeader
                                            );
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="mt-4 py-3">
                                {!sent ? (
                                    <div>
                                        <Button
                                            btnCls="btn-outline-secondary px-5 theme-btn  mr-4"
                                            iconCls="fa-eye"
                                            value="Preview"
                                            onClick={(e) =>
                                                this.setState({ modal: true })
                                            }
                                        />

                                        <Button
                                            loading={sending}
                                            onClick={this.onFormSubmit}
                                            btnCls="btn-outline-primary px-5 theme-btn  mr-4"
                                            iconCls="fa-paper-plane"
                                            value="Submit and Send"
                                        />
                                    </div>
                                ) : (
                                    <div className="mt-4">
                                        <Alert
                                            className="btn btn-block"
                                            color="success"
                                        >
                                            The prescription has been sent to
                                            the patient successfully!
                                        </Alert>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="w-25">
                            <div className="px-3">
                                <PrescriptionTemplatesIndex
                                    mode="select"
                                    onSelect={(item) => {
                                        this.setState({
                                            drugs: [...drugs, ...item.details],
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <Modal isOpen={modal} toggle={toggle}>
                    <ModalBody>
                        <PrescriptionPreview
                            data={{ name, age, notes, header, footer, drugs }}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
