import React, { Component } from "react";
import Button from "components/Form/Button";
import moment from "moment";
import { getDrugDirection, getDrugDuration, isSMDevice } from "utils";

export default class PrescriptionPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    render() {
        const { data } = this.state;
        
        return (
            <div className="p-2 shadow-sm border">
                <div className="text-right">
                    <Button
                        btnCls="btn-outline-secondary px-4"
                        iconCls="fa-print"
                        value="Print"
                        onClick={() => window.print()}
                    />
                </div>
                <div className="m-3 print-me">
                    <div
                        className="my-editor"
                        dangerouslySetInnerHTML={{
                            __html: data.header,
                        }}
                    ></div>
                    <hr />

                    <div className="d-flex flex-wrap justify-content-between my-4 border p-3">
                        {/* <div className="d-flex ">
                            <div>
                                <strong>#</strong>
                            </div>
                            <div className="ml-2">26</div>
                        </div> */}
                        <div className="d-flex ">
                            <div>
                                <strong>Name:</strong>
                            </div>
                            <div className="ml-2">{data.name}</div>
                        </div>
                        <div className="d-flex ">
                            <div>
                                <strong>Age/Sex:</strong>
                            </div>
                            <div className="ml-2">{data.age}</div>
                        </div>
                        <div className="d-flex">
                            <div>
                                <strong>Date:</strong>
                            </div>
                            <div className="ml-2">{moment(data.created_at).format("DD-MM-YYYY")}</div>
                        </div>
                    </div>

                    <div className="">
                        <h5>Rx:</h5>
                        <div className="my-2">
                            <table className={`table table-bordered ${isSMDevice()?"table-responsive":""}`}>
                                <thead>
                                    <tr>
                                        <th scope="col">Rx</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Frequency</th>
                                        <th scope="col">Direction</th>
                                        <th scope="col">Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.drugs && data.drugs.map((d, k) => (
                                        <tr key={k}>
                                            <th scope="row">{k + 1}</th>
                                            <td>
                                                {`${d.name} x ${d.unit || 1}`}
                                                {d.instruction && <><br/>{d.instruction}</>}
                                            </td>
                                            <td>
                                                {d.frequency? `
                                                  ${d.frequency.includes(1)?"1":"0"}
                                                  ${d.frequency.includes(2)?"-1":"-0"}
                                                  ${d.frequency.includes(3)?"-1":"-0"}
                                                `: ""}
                                            </td>
                                            <td>{getDrugDirection(d.direction)}</td>
                                            <td>{getDrugDuration(d.duration)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mb-5 pb-3">Notes: {data.notes}</div>
                    <hr />
                    <div
                        className="my-editor"
                        dangerouslySetInnerHTML={{
                            __html: data.footer,
                        }}
                    ></div>
                </div>
            </div>
        );
    }
}
