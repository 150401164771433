import React, { Component } from "react";
import SectionHead from "components/sectionHead";

export default class VendorHelp extends Component {
    render() {
        return (
            <div className="container py-5">
                <SectionHead title="Help" />
                <div className="col my-3">
                    <a href="https://youtu.be/48kZ7ngrffU" target="_blank">
                        <p className="text-muted">
                            1. Quick Introduction: How to setup and get started
                        </p>
                    </a>
                    <p className="pt-5">
                        Please call us at 9972112944 if you have any queries
                    </p>
                </div>
            </div>
        );
    }
}
