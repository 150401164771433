import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isSMDevice } from "utils";

export default class SubSectionHead extends Component {
    render() {
        const {
            createTxt,
            onCreateLinkClick,
            onCreateClick,
            onGoBack,
        } = this.props;
        const isMobile = isSMDevice();

        return (
            <div>
                <div className="d-flex pb-2 mb-3 align-items-end">
                    {onGoBack && (
                        <button
                            title="Home"
                            className="btn border-none btn-outline-secondary px-3 mr-3"
                            onClick={onGoBack}
                            style={{ height: "fit-content" }}
                        >
                            <i className="fa fa-long-arrow-alt-left "></i>
                        </button>
                    )}

                    <div className="h5">{this.props.title}</div>

                    {onCreateLinkClick && (
                        <Link
                            title="Home"
                            className="btn btn-outline-primary theme-btn px-3 ml-auto"
                            to={this.props.createLink}
                            style={{ height: "fit-content" }}
                        >
                            <i className="far fa-plus fa-lg mr-3"></i>
                            {createTxt}
                        </Link>
                    )}
                    {onCreateClick && (
                        <button
                            className={`btn btn-outline-primary px-3 ml-auto ${
                                isMobile ? "" : " theme-btn "
                            }`}
                            style={{ height: "fit-content" }}
                            onClick={onCreateClick}
                        >
                            <i className="far fa-plus fa-lg"></i>
                            {!isMobile && (
                                <span className="ml-3">{createTxt}</span>
                            )}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}
