import React, { Component } from "react";
import Select from "react-select";
import MyDropZone from "components/Form/MyDropZone";
import { profile_pic_types } from "data/config/variables";
import isEmpty from "utils/isEmpty";
import FileManager from "components/Form/FileManager";

class CRUDFormElement extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderElement() {
        const { props } = this;
        const type = props.type;
        switch (type) {
            case "dropdown":
                return (
                    <Select
                        name={props.name}
                        options={props.data}
                        //isMulti={true}
                        placeholder={props.placeHolder}
                        value={props.data.map((op) =>
                            props.state[props.name] &&
                            props.state[props.name].includes(op.value)
                                ? op
                                : false
                        )}
                        onChange={(sel) =>
                            props.onChange(props.name, sel.value)
                        }
                    />
                );
            case "text-area":
                return (
                    <textarea
                        name={props.name}
                        value={
                            props.state[props.name]
                                ? props.state[props.name]
                                : ""
                        }
                        rows="3"
                        className="form-control"
                        placeholder={props.placeHolder}
                        value={
                            props.state[props.name]
                                ? props.state[props.name]
                                : ""
                        }
                        onChange={(e) =>
                            props.onChange(props.name, e.target.value)
                        }
                    />
                );
            case "file":
                return (
                    <div>
                        <FileManager
                            uploadType={profile_pic_types.phr}
                            files={props.state[props.name]}
                            onUpdate={(files, replace = false) =>
                                props.onChange(props.name, [
                                    ...new Set(
                                        replace
                                            ? files
                                            : files.concat(
                                                  props.state[props.name] || []
                                              )
                                    ),
                                ])
                            }
                        />
                    </div>
                );
            default:
                return (
                    <input
                        type={type || "text"}
                        className="form-control"
                        placeholder={props.placeHolder}
                        onChange={(e) =>
                            props.onChange(props.name, e.target.value)
                        }
                        name={props.name}
                        value={
                            props.state[props.name]
                                ? props.state[props.name]
                                : ""
                        }
                        title={props.title}
                        disabled={props.disabled}
                    />
                );
        }
    }

    render() {
        const { props } = this;
        return (
            <div className={props.className} key={props.name}>
                <div className="form-group">
                    <label className="text-muted">{`${props.label}${
                        props.validation ? "*" : ""
                    }`}</label>

                    {props.children ? props.children : this.renderElement()}

                    {props.validation &&
                        props.validator.message(
                            props.name,
                            props.state[props.name],
                            props.validation
                        )}
                </div>
            </div>
        );
    }
}

export default CRUDFormElement;
