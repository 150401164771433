import React, { Component } from "react";
import BookAppointment from "components/BookAppointment";
import MCUser from "./user";
import MCVendors from "./clincs";
import MCSearchVendors from "./clincs/search";

export default class MCBooking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: null,
            vendorId: null,
        };
    }

    render() {
        const { userId, vendorId } = this.state;
        return (
            <div className="theme-bg-primary no-bg-xs py-lg-4">
                <div className="login-section auth-section">
                    <div className="auth-wrapper mx-auto my-md-5 pt-3 pb-5 px-3 shadow-lg bg-white">
                        <div className="lead mb-4 text-center">
                            Book an Appointment
                        </div>
                        {!userId && (
                            <div className="my-2">
                                <MCUser
                                    {...this.props}
                                    onUserFound={(user) =>
                                        this.setState({
                                            userId: user.id,
                                        })
                                    }
                                />
                            </div>
                        )}
                        {userId && (
                            <div>
                                <MCVendors
                                    userId={userId}
                                    onClinicSelected={(c) =>
                                        this.setState({
                                            vendorId: c ? c.vendor_id : null,
                                        })
                                    }
                                />
                            </div>
                        )}

                        {vendorId && (
                            <div>
                                <BookAppointment
                                    vendor_id={vendorId}
                                    skipInfo={true}
                                />
                            </div>
                        )}
                    </div>
                    {/*//auth-wrapper*/}
                    <div
                        className="position-fixed py-2 text-center w-100 bg-white"
                        style={{ bottom: "0px" }}
                    >
                        <small className="text-light">powered by zdoc</small>
                    </div>
                </div>
            </div>
        );
    }
}
