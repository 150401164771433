import React, { Component } from "react";
import config from "data/config";
import "./rtc.css";
import { APP_EXOTEL_NUMBER } from "data/config/variables";

export default class RTCVideo extends Component {

    constructor(props){
        super(props);
        this.state={loading: true, confId : this.props.id}
    }

	render() {
		const {loading} = this.state;

        const number = APP_EXOTEL_NUMBER;
        const {bookingId, isVendor} = this.props;
		
		return (
			<div className="">
				<div className="d-flex theme-bg-primary py-1 px-4 align-items-center justify-content-end">
                    <div className="">
                        Please wait till the other person joins. To call the {isVendor? "User":"Doctor"} dial 
                        <strong> {number}</strong> and enter ID as
                        <strong> {bookingId}</strong>
                    </div>
                    <a
                        href={`tel: ${number}`}
                        className="btn btn-outline-success px-4 ml-4 "
                    >
                        Call {isVendor? "User":"Doctor"}
                    </a>
                </div>
                {loading && <p className="mt-3 col-12">loading....</p>}
				<iframe
					allow="geolocation; microphone; camera" 
					src= {`https://tokbox.com/embed/embed/ot-embed.js?embedId=${config.TOKBOX_EMBED_ID}&room=${bookingId}&iframe=true`}
					scrolling="auto"
                    frameBorder="0"
					onLoad={()=>this.setState({loading: false})}
					className=" w-100"
                    style={{height: "94vh"}}
				/>
			</div>
		);
	}
}
