import React, { Component } from "react";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import CRUDFormElement from "./FormElement";

export default class CRUDUpdate extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validator = new SimpleReactValidator({});
        this.state = {
            isViewMode: this.props.isViewMode,
            loading: false,
            formElements: this.props.elements,
            ...this.props.data
        };
    }

    /* onChange(e) {
    this.setState({ [e.target.name]: e.target.value.trim() });
  } */

    onChange(name, value) {
        this.setState({ [name]: value });
    }

    onFormSubmit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            // get formElements
            const ele = this.props.elements.map(i => i.name);
            let result = {};
            for (const k in this.state) {
                if (ele.includes(k)) result[k] = this.state[k];
            }
            // send updated data back
            const id = this.props.data ? this.props.data.id : null;
            this.props.onSave(id, result);
        } else {
            console.log("reaching hree");
            this.validator.showMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
        }
    }

    render() {
        const { formElements, id, isViewMode } = this.state;
        return (
            <div className="pt-3 px-5 border shadow-sm">
                <div className="h5 text-muted text-center mb-4 border-bottom pb-3">{`${id ? "Update" : "Add"} Record`}</div>
                <form onSubmit={this.onFormSubmit}>
                    <div className="row">
                        {formElements.map((e, i) => (
                            <CRUDFormElement
                                key={i}
                                name={e.name}
                                type={e.type}
                                label={e.title}
                                data={e.data}
                                className="col-sm-12 col-md-6"
                                placeHolder={e.placeHolder}
                                state={this.state}
                                onChange={this.onChange}
                                validator={this.validator}
                                validation={e.validation? e.validation: e.required ? "required" : ""}
                            />
                        ))}

                        <div className="col-12 form-group my-4">
                            {!isViewMode && <button type="submit" className="btn btn-outline-success py-2 px-4">
                                <i className="fa fa-save"></i> Save
                            </button>}
                            <button className="ml-3 btn btn-outline-secondary py-2 px-4" onClick={this.props.onCancel}>
                                <i className="fa fa-save"></i> {isViewMode? "Go Back": "Cancel"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
