import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import FormElement from "components/Form/FormElement";
import DrugsEditorIndex from "../drugseditor";
import Button from "components/Form/Button";
import SectionHead from "components/sectionHead";
import { createOrUpdateTemplate } from "./network";

export default class ManagePT extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({});
        const { item } = this.props || {};
        this.state = {
            ...item,
            onSaved: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit() {
        if (this.validator.allValid()) {
            let { id, title, descr, details } = this.state;
            this.setState({ onSaved: false });
            createOrUpdateTemplate({ id, title, descr, details })
                .then((r) => {
                    if (!id) {
                        const [
                            row,
                        ] = r.data.insert_prescription_templates.returning;
                        id = row.id;
                    }
                    this.setState({ onSaved: true });
                    this.props.onUpdate({ id, title, descr, details });
                })
                .catch((r) => console.log(r));
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onUpdate(data) {
        const v = { ...this.state.item, ...data };
        this.setState({ item: v });
    }

    render() {
        const { id, title, descr, details, onSaved } = this.state;
        const { onGoBack } = this.props;
        this.validator.purgeFields();

        return (
            <div className="container py-5">
                <SectionHead
                    title={id ? "Update Template" : "New Template"}
                    onGoBack={onGoBack}
                />

                <div className="pt-2">
                    <div className="">
                        <FormElement
                            name="title"
                            label="Template Name"
                            placeHolder="Ex: Cold and Cough"
                            value={title}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required"
                        />

                        <FormElement
                            name="descr"
                            label="Description"
                            value={descr}
                            placeHolder="Ex: 3+ age cold and cougn prescription"
                            onChange={this.onChange}
                        />
                    </div>

                    <label className="text-muted">Drugs:</label>
                    <div
                        style={{
                            border: "1px solid #ccc",
                            padding: "1em",
                            marginBottom: "0.8em",
                        }}
                    >
                        <DrugsEditorIndex
                            drugs={details}
                            onUpdate={(details) => this.setState({ details })}
                        />
                        {this.validator.message("drugs", details, "required")}
                    </div>
                </div>

                {onSaved && (
                    <div className="alert alert-success">
                        <strong>Saved Successfully!</strong>.
                    </div>
                )}

                <div className="mt-4 py-3">
                    <Button
                        btnCls="btn-outline-success px-5 theme-btn  mr-4"
                        iconCls="fa-save"
                        value={`${id ? "Update" : "Save"} Template`}
                        onClick={this.onFormSubmit}
                    />
                </div>
            </div>
        );
    }
}
