import React, { Component } from "react";
import MCSearchVendors from "./search";
import isEmpty from "utils/isEmpty";
import { fetchSavedVendors, addToSavedVendorList } from "../network";
import { getUser } from "utils";

export default class MCVendors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: this.props.userId,
            vendorsLi: [],
            selClinic: null,
            isAddClinic: false,
        };
        this.addToSavedList = this.addToSavedList.bind(this);
    }

    componentDidMount() {
        const user = getUser();
        const savedVendors = user.saved_vendors || [];

        if (!isEmpty(savedVendors)) {
            fetchSavedVendors(savedVendors)
                .then((r) => {
                    const li = r.data.vendors;
                    if (!isEmpty(li)) {
                        let _d = [];
                        li.forEach((l) => _d.push({ ...l.websites[0] }));
                        this.setState({ vendorsLi: _d });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    addToSavedList(v) {
        const { userId, vendorsLi } = this.state;
        if (!vendorsLi.find((l) => l.vendor_id === v.vendor_id)) {
            // save in db
            addToSavedVendorList(userId, v.vendor_id)
                .then((r) => {
                    const temp = [...this.state.vendorsLi];
                    temp.push({ ...v.vendor.websites[0] });
                    this.setState({ vendorsLi: temp });
                })
                .catch((err) => console.log(err));
        }
    }

    render() {
        const { selClinic, vendorsLi, isAddClinic } = this.state; 
        return (
            <div>
                {isAddClinic ? (
                    <div>
                        <MCSearchVendors
                            onGoBack={(e) =>
                                this.setState({ isAddClinic: false })
                            }
                            onVendorSelect={(v) => {
                                this.addToSavedList(v);
                                this.setState({ isAddClinic: false });
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <div className="my-3">
                            <p className="h6 text-muted">
                                {selClinic ? "" : "Select"} Clinic:
                            </p>
                        </div>
                        {selClinic ? (
                            <div
                                className="p-3 mb-3 shadow-sm"
                                onClick={(e) => {
                                    this.setState({ selClinic: null });
                                    this.props.onClinicSelected(null);
                                }}
                            >
                                <i className="fad fa-check mr-2"></i>
                                {selClinic.name}
                            </div>
                        ) : isEmpty(vendorsLi) ? (
                            <p className="text-muted">
                                You do not have any saved clinics. Please click
                                the button below to add one.
                            </p>
                        ) : (
                            vendorsLi.map((v, k) => (
                                <div
                                    key={k}
                                    className="p-3 mb-3 shadow-sm"
                                    style={{cursor: "pointer"}}
                                    onClick={(e) => {
                                        this.setState({ selClinic: v });
                                        this.props.onClinicSelected(v);
                                    }}
                                >
                                    {v.name}
                                </div>
                            ))
                        )}
                        {!selClinic && (
                            <div className="pt-3">
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary theme-btn mx-auto"
                                    onClick={(e) =>
                                        this.setState({ isAddClinic: true })
                                    }
                                >
                                    Add Clinic
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
