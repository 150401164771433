import React, { Component } from "react";
import base62 from "base62/lib/ascii";
import { fetchCode } from "./network";
import isEmpty from "utils/isEmpty";
import { insertEvent } from "utils/GAUtil";

export default class CampaignsIndex extends Component {
    constructor(params) {
        super(params);

        //1wa  1w3f 1wa!f1
        const link = this.props.location.pathname;
        const decoded = link.split("/1")[1];

        // split code and  userId
        let blocks = decoded.split("!");
        let [code, user] = blocks;
        user = user ? base62.decode(user) : null;

        fetchCode(code)
            .then((r) => {
                const [params] = r.data.update_utm_campaigns.returning;
                if (!isEmpty(params)) {
                    const {
                        utm_source,
                        utm_medium,
                        utm_content,
                        utm_term,
                        utm_campaign,
                    } = params;

                    insertEvent({
                        category: "campaigns",
                        action: "shares",
                        label: "user",
                        value: "vendor",
                        details: { ...params, userId: user },
                    }).then(
                        (r) =>
                            (window.location.href = `/landing?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_term=${utm_term}&utm_content=${
                                user ? user : utm_content
                            }`)
                    );
                } else window.location.href = "/";
            })
            .catch((err) => console.log(err));
    }

    render() {
        return <div>loading...</div>;
    }
}
