import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaqCategories, DoctorQuestions, UserQuestions } from "data/config/faq";

export default class PubFAQ extends Component {
    state = {
        isDoctor: true,
    };

    render() {
        const { isDoctor } = this.state;

        const questions = isDoctor ? DoctorQuestions : UserQuestions;

        return (
            <div>
                <div>
                    <section className="faq-section py-5">
                        <div className="container text-center">
                            <div className="mb-5">
                                <h3>For {isDoctor ? "Doctors" : "Patients"}</h3>
                                <a
                                    href="#!"
                                    className="legal-note"
                                    onClick={(e) =>
                                        this.setState({ isDoctor: !isDoctor })
                                    }
                                >
                                    Are you a {isDoctor ? "Patient" : "Doctor"}?
                                </a>
                            </div>

                            <div
                                id="faq-accordion"
                                className="faq-accordion text-left single-col-max-width mx-auto"
                            >
                                {FaqCategories.map((cat, ck) => (
                                    <div key={ck}>
                                        <h5 className="text-muted mb-4">
                                            {cat.title}:{" "}
                                        </h5>
                                        {questions.map((q, qk) => {
                                            if (q.category === cat.id) {
                                                const k = `faq${ck}${qk}`;
                                                return (
                                                    <div
                                                        key={k}
                                                        className="card border-0 shadow-lg mb-4"
                                                    >
                                                        <div className="card-header bg-white border-0 p-4">
                                                            <h4 className="card-title mb-0">
                                                                <a
                                                                    data-parent="#faq-accordion"
                                                                    data-toggle="collapse"
                                                                    className="card-toggle collapsed"
                                                                    href={`#${k}`}
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="far fa-plus-square mr-2" />
                                                                    {q.question}
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div
                                                            className="card-collapse collapse"
                                                            id={k}
                                                        >
                                                            <div className="card-body pt-0">
                                                                {q.answer}
                                                            </div>
                                                            {/*//card-body*/}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                ))}
                            </div>
                            {/*//faq-accordion*/}
                            <div className="faq-intro text-center py-5">
                                <h5 className="mb-4">
                                    Can't find the answer you're looking for?
                                    Need more help?
                                </h5>
                                <div>
                                    <Link
                                        className="btn btn-primary theme-btn-ghost"
                                        to="/contact"
                                    >
                                        Get in touch
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*//container*/}
                    </section>
                </div>
            </div>
        );
    }
}
