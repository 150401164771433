import { gqlSend } from "utils/AxiosUtil";

export const fetchCode = (code) => {
    const q = {
        query: `
            mutation {
                update_utm_campaigns(where: {code: {_eq: "${code}"}}, _inc: {count: 1}) {
                    returning {
                        code, utm_campaign, utm_content, utm_medium, utm_source, utm_term
                    }
                }
        }`,
    };

    return gqlSend(q);
};