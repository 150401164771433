import React, { Component } from "react";
import {
  getConsultationName,
  getConsultationIcon,
  getConsultationDescr,
  isSMDevice,
} from "utils";
import { APP_CURRENCY_SYMBOL } from "data/config/variables";
import isEmpty from "utils/isEmpty";

export default class BookAppointmentService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selIdx: null,
    };
  }

  render() {
    const { selIdx } = this.state;
    const { listing } = this.props;
    const { consultations } = listing || {};

    // remove disabled consultations
    const enabledConsultations =
      consultations && consultations.filter((c) => c.status);

    return (
      <div>
        <div className="my-3">
          <p className="h6 text-muted">Select Consultation Type:</p>
        </div>
        <div className="mt-2 d-flex flex-wrap flex-md-nowrap text-center">
          {!isEmpty(enabledConsultations) ? (
            enabledConsultations.map((c, k) => (
              <div
                key={k}
                title={getConsultationDescr(c.type)}
                className={`shadow-sm border rounded py-2 px-3 mb-3 mr-3 ${
                  selIdx === k
                    ? "bg-primary text-white"
                    : "bg-white text-primary"
                }`}
                onClick={(e) => {
                  this.setState({ selIdx: k });
                  this.props.onSelect(c);
                }}
                style={{
                  width: isSMDevice()? "75%": "25%",
                  cursor: "pointer",
                }}
              >
                <div className="d-flex align-items-center ">
                  <div>
                    <i
                      className={`fa mr-3 ${getConsultationIcon(c.type)}`}
                      style={{ fontSize: "1.4em" }}
                    ></i>
                  </div>
                  <div className="flex-grow">
                    <div className="text-left lead" style={{fontWeight: "600"}}>
                      { c.price} INR
                    </div>
                    <div className="">{getConsultationName(c.type)}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="h5 text-muted py-2">
              Consultations are not available currently.
            </div>
          )}
        </div>
      </div>
    );
  }
}
