import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import config from "data/config";
import * as qs from "query-string";
import { getCalIntegrationInfo } from "./network";

class SettingsIntegrationsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIntegrationBlock: false,
            integrationUrl: null,
        };
    }

    componentDidMount() {
        const redirectUrl = `${config.SITE_URL}${this.props.location.pathname}`;
        const code = qs.parse(this.props.location.search).code;
        getCalIntegrationInfo(code, redirectUrl)
            .then((r) => {
                console.log(r);
                if (r.status) {
                    this.setState({
                        integrationUrl: r.gUrl,
                        showIntegrationBlock: true,
                    });
                }
            })
            .catch((err) => console.log(err.response.data));
    }

    render() {
        const { showIntegrationBlock, integrationUrl } = this.state;

        return (
            <div>
                <div className="mb-5">
                    <div className="text-muted p-2 h6 mb-3 border-bottom">
                        Enable Google Calendar:
                    </div>

                    <div>
                        <div className="py-3 ml-1 lead">
                            View upcoming appointments for the day, week and
                            month directly from your calendar. Receive
                            appointment alerts and more. Click the below button
                            and grant us the permission to update your calendar
                            automatically. Note: We will create a calendar by
                            the name of ZDOC Appointments in your account, for which
                            you can set custom alerts, share with assistants and
                            much more
                        </div>
                    </div>
                    {showIntegrationBlock && (
                        <div className="mt-3">
                            {integrationUrl ? (
                                <a
                                    href={integrationUrl}
                                    className="btn btn-social btn-google shadow-sm"
                                >
                                    <img
                                        className=""
                                        src="/assets/img/google-signin.png"
                                    />
                                </a>
                            ) : (
                                <span className="p-3 lead shadow-sm">
                                    <i className="far fa-check text-success mr-2"></i>
                                    Google Calendar Integrated
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(SettingsIntegrationsIndex);
