import * as qs from "query-string";

export const parseUTMParams = (location) => {
    // capture UTM parameters
    const utm_source = qs.parse(location.search).utm_source;
    const utm_medium = qs.parse(location.search).utm_medium;
    const utm_campaign = qs.parse(location.search).utm_campaign;
    const utm_term = qs.parse(location.search).utm_term;
    const utm_content = qs.parse(location.search).utm_content;

    if (utm_source) {
        //set utm in localstorage
        localStorage.setItem("utm_source", utm_source);
        localStorage.setItem("utm_medium", utm_medium);
        localStorage.setItem("utm_campaign", utm_campaign);
        localStorage.setItem("utm_content", utm_content);
        localStorage.setItem("utm_term", utm_term);
    }

    return { utm_source, utm_medium, utm_campaign, utm_term, utm_content };
};
