import { gqlSend } from "utils/AxiosUtil";

export const insertUsers = (users) => {
    const q = {
        query: ` 
            mutation ($users: [users_insert_input!]! ) {
                insert_users(
                        objects: $users,
                        on_conflict: {
                            constraint: users_phone_key,
                            update_columns: []
                        }
                    ) {
                        affected_rows, returning {id, phone}
                }
        }`,
        variables: {
            users
        },
    };
    return gqlSend(q);
}

export const updateUser = (users, vendorId) => {
    const q = {
        query: `mutation  {
            update_users(where: {phone: {_in: ["${users.join('","')}"]}}, _append: {saved_vendors: ${vendorId}}) {
              affected_rows
            }
          }`
    };
    return gqlSend(q);
}
