import React, { Component } from "react";
import { fetchPrescriptionByHash } from "./network";
import PrescriptionPreview from "page/vendor/customers/list/details/prescriptions/details/preview";

export default class PrescriptionsPub extends Component {
    constructor(props) {
        super(props);

        const link = this.props.location.pathname;
        const prescriptionId = link.split("/P")[1];

        this.state = { prescriptionId, details: null, created_at: null };
    }

    componentDidMount() {
        const { prescriptionId } = this.state;
        console.log(prescriptionId);
        if (prescriptionId) {
            fetchPrescriptionByHash(prescriptionId)
                .then((r) => {
                    const [row] = r.data.prescriptions;
                    if (row) this.setState({ details: row.details, created_at: row.created_at });
                })
                .catch((err) => console.log(err));
        }
    }

    render() {
        const { details, created_at } = this.state;

        return (
            <div>
                {details ? (
                    <div className="container py-4">
                        <PrescriptionPreview data={{...details.prescription, created_at}} />
                    </div>
                ) : (
                    <div>loading...</div>
                )}
            </div>
        );
    }
}
