import React, { Component } from "react";
import PropTypes from "prop-types";

import SimpleReactValidator from "simple-react-validator";
import { login, changePW } from "./network";
import { A4P_ROLE_USER } from "data/config/variables";
import FormElement from "components/Form/FormElement";
import queryString from "query-string";

class ChangePW extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.validator = new SimpleReactValidator({});
    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      forgotCode: queryString.parse(window.location.search).forgot,
      thankyou: false,
      submitBtnTxt: "Reset",
      role: this.props.role
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value.trim() });
  }

  onFormSubmit(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        submitBtnTxt: "Please Wait...",
        thankyou: false,
        serverErrors: false,
        serverErrorMessage: ""
      });

      if (this.state.newPassword !== this.state.confirmPassword) {
        return this.setState({
          submitBtnTxt: "Submit",
          serverErrors: true,
          serverErrorMessage: "The new password and confirm should match"
        });
      }

      console.log(this.state);
      changePW(this.state)
        .then(r => {
          console.log(r);
          this.setState({
            submitBtnTxt: "Submit",
            thankyou: true
          });
        })
        .catch(err => {
          console.log(err, err.response);
          this.setState({
            submitBtnTxt: "Submit",
            serverErrors: true,
            serverErrorMessage: err.response.data.msg
          });
        });

    } else {
      console.log("reaching hree");
      this.validator.showMessages();
      this.forceUpdate(); // [validator] rerender to show messages for the first time
    }
  }

  render() {
    const {onLoginClick, showLoginLinkInChangePW} = this.props;

    const warningBlock = () => (
      <div className="alert alert-danger">
        <strong className="mr-2">Error!</strong> {this.state.serverErrorMessage}
      </div>
    );

    const successBlock = () => (
      <div className="alert alert-success">
        <strong className="mr-2">Success!</strong> Your password has been reset successfully!. 
        {showLoginLinkInChangePW && onLoginClick && (
          <span> Please <a href="#0" onClick={onLoginClick}>
            <strong>Click here to Login.</strong>
          </a> 
        </span> 
        )}
      </div>
    );

    return (
      <div>
        <h4 className="text-center mb-4">Change password</h4>
        <div className="box_form">
          <div>
            {this.state.thankyou && successBlock()}
            {this.state.serverErrors && warningBlock()}
            <form id="vlogin" method="post" onSubmit={this.onFormSubmit}>
              <div className="row">
                {!this.state.forgotCode && (
                  <FormElement
                    name="currentPassword"
                    type="password"
                    className="col-12"
                    label="Current Password"
                    state={this.state}
                    onChange={this.onChange}
                    validator={this.validator}
                    validation="required"
                  />
                )}

                <FormElement
                  name="newPassword"
                  type="password"
                  className="col-12"
                  label="New Password"
                  state={this.state}
                  onChange={this.onChange}
                  validator={this.validator}
                  validation="required|min:8"
                />

                <FormElement
                  name="confirmPassword"
                  type="password"
                  className="col-12"
                  label="Confirm Password"
                  state={this.state}
                  onChange={this.onChange}
                  validator={this.validator}
                  validation="required|min:8"
                />

                <div className="col-12 form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary theme-btn btn-block mb-2"
                    value={this.state.submitBtnTxt}
                  >
                    {this.state.submitBtnTxt}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ChangePW.defaultProps = {
  role: A4P_ROLE_USER,
};

ChangePW.propTypes = {
  onLoginClick: PropTypes.func,
  role: PropTypes.string
};

export default ChangePW;
