import React, { Component } from "react";
import AppointmentsIndex from "components/Appointments";

export default class VendorUserAppointments extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="py-4">
                <AppointmentsIndex {...this.props} user={this.props.user} />
            </div>
        );
    }
}
