import React, { Component } from "react";
import Select from "react-select";
import { FREQUENCIES, DIRECTIONS, DURATIONS } from "./drugs";
import "./style.css";

export default class DrugsEditorRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.data,
        };
        this.onChange = this.onChange.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
    }

    componentWillReceiveProps(props) {
        const {
            name,
            unit,
            frequency,
            duration,
            direction,
            instruction,
        } = props.data;
        this.setState({
            name,
            unit,
            frequency,
            duration,
            direction,
            instruction,
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value }, this.onUpdate);
    }

    onUpdate() {
        this.props.onUpdate({ ...this.state });
    }

    render() {
        const {
            name,
            unit,
            frequency,
            duration,
            direction,
            instruction,
        } = this.state;

        return (
            <div className="" style={{fontSize: "0.92em"}}>
                <div className="d-flex mt-2 align-items-center flex-wrap border px-3">
                    <div className="form-group mr-3">
                        <label className="text-muted">
                            Drug Name and Strength:{" "}
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={name || ""}
                            onChange={this.onChange}
                            className="formEle"
                        />
                    </div>

                    <div className="form-group mr-3">
                        <label className="text-muted">Unit: </label>
                        <input
                            type="text"
                            name="unit"
                            value={unit || 1}
                            onChange={this.onChange}
                            className="formEle"
                            style={{ width: "50px" }}
                        />
                    </div>

                    <div className="form-group mr-3">
                        <label className="text-muted ml-1 my-1">Frequency: </label>
                        <Select
                            name="frequency"
                            className="formEle selectWidth"
                            options={FREQUENCIES}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            placeholder="Frequency"
                            value={FREQUENCIES.map((op) =>
                                frequency && frequency.includes(op.value)
                                    ? op
                                    : false
                            )}
                            onChange={(sel) =>
                                this.setState(
                                    {
                                        frequency:
                                            sel && sel.length > 0
                                                ? sel.map((i) => i.value)
                                                : [],
                                    },
                                    this.onUpdate
                                )
                            }
                        />
                    </div>

                    <div className="form-group mr-3">
                        <label className="text-muted ml-1 my-1">Duration:</label>
                        <Select
                            className="formEle selectWidth"
                            options={DURATIONS}
                            value={DURATIONS.map((op) =>
                                duration === op.value ? op : null
                            )}
                            onChange={(sel) =>
                                this.setState(
                                    { duration: sel.value },
                                    this.onUpdate
                                )
                            }
                        />
                    </div>

                    <div className="form-group mr-3">
                        <label className="text-muted ml-1 my-1">Direction:</label>
                        <Select
                            className="formEle selectWidth"
                            options={DIRECTIONS}
                            value={DIRECTIONS.map((op) =>
                                direction === op.value ? op : null
                            )}
                            onChange={(sel) =>
                                this.setState(
                                    { direction: sel.value },
                                    this.onUpdate
                                )
                            }
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Instructions:</label>​
                        <textarea
                            id="txtArea"
                            rows="1"
                            className="formEle"
                            name="instruction"
                            onChange={this.onChange}
                            value={instruction || ""}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
