import React, { Component } from "react";
import MyProfile from "components/MyProfile";
import SectionHead from "components/sectionHead";

export default class UserMyProfile extends Component {
    render() {
        return (
            <div>
                <div className="container py-5">
                    <div>
                        <SectionHead title="My Profile" />
                        <div className="p-3 border">
                            <MyProfile />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
