import React, { Component } from "react";
import { Link } from "react-router-dom";
import { parseUTMParams } from "utils/parseUTM";

export default class PubHome extends Component {
    componentDidMount() {
        parseUTMParams(this.props.location);
    }

    render() {
        return (
            <div>
                <section className="hero-section">
                    <div className="container">
                        <div className="row figure-holder">
                            <div className="col-12 col-md-6 pt-3 pt-md-4">
                                <h2 className="site-headline font-weight-bold mt-lg-5 pt-lg-5">
                                    A Single Platform For All Your Practice's
                                    Needs
                                </h2>
                                <div className="site-tagline mb-3">
                                    Own an online appointment booking website,
                                    grow your practice with telephonic and video
                                    consultations, manage your operations, track
                                    growth, and market your practice with Zdoc's
                                    suite
                                </div>
                                <div className="cta-btns">
                                    <div className="my-4">
                                        <Link
                                            className="btn btn-primary font-weight-bold theme-btn"
                                            to="/clinic/signup"
                                        >
                                            Launch your Website Now. It's Free!
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*//row*/}
                    </div>
                </section>
                {/*//hero-section*/}
                <section className="logos-section theme-bg-primary py-5 text-center">
                    <div className="container">
                        <h3 className="mb-5">
                            Trusted by hundreds of doctors like you
                        </h3>
                        <div className="logos-row row mx-auto">
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-1.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-2.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-3.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-4.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-5.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                                <div className="item-inner">
                                    <img
                                        src="/assets/img/logos/logo-6.png"
                                        alt="logo"
                                    />
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                        </div>
                    </div>
                    {/*//container*/}
                </section>
                {/*//logo-section*/}
                <section className="benefits-section py-5">
                    <div className="container py-lg-5">
                        <h3 className="mb-5 text-center font-weight-bold">
                            Modernize your practice with ZDOC
                        </h3>
                        <div className="row">
                            <div className="item col-12 col-lg-4">
                                <div className="item-inner text-center p-3 p-lg-5">
                                    <img
                                        className="mb-3"
                                        src="assets/img/icons/icon-target.svg"
                                        alt=""
                                    />
                                    <h5>Retain and Attract Patients</h5>
                                    <div>
                                        Stop your patients from flocking to
                                        other platforms. Zdoc offers a fully
                                        white labeled solution. Delight your
                                        patients by providing virtual
                                        consultations under your own domain
                                        name.
                                    </div>
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-12 col-lg-4">
                                <div className="item-inner text-center p-3 p-lg-5">
                                    <img
                                        className="mb-3"
                                        src="assets/img/icons/icon-plane.svg"
                                        alt=""
                                    />
                                    <h5>Easy Appointments</h5>
                                    <div>
                                        The world is going digital. So can you.
                                        Let your patients pay and book online
                                        appointments for clinc visits or tele
                                        consultations through your site or just
                                        a missed call. No signup or installation
                                        required.
                                    </div>
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                            <div className="item col-12 col-lg-4">
                                <div className="item-inner text-center p-3 p-lg-5">
                                    <img
                                        className="mb-3"
                                        src="/assets/img/icons/rocket.svg"
                                        alt=""
                                    />
                                    <h5>Grow your Practise</h5>
                                    <div>
                                        We’ll help you establish prominent web
                                        presence in google and social media, and
                                        reach more patients. Collect feedback
                                        from patients and encourage them to
                                        leave online testimonials.
                                    </div>
                                </div>
                                {/*//item-inner*/}
                            </div>
                            {/*//item*/}
                        </div>
                        {/*//row*/}
                        <div className="pt-3 text-center">
                            <Link
                                className="btn btn-primary theme-btn theme-btn-ghost font-weight-bold"
                                to="/features"
                            >
                                Learn More
                            </Link>
                        </div>
                    </div>
                </section>
                {/*//benefits-section*/}
                <section className="features-section py-5">
                    <div className="container py-lg-5">
                        <h3 className="mb-3 text-center font-weight-bold section-heading">
                            Highlights
                        </h3>
                        <div className="row pt-5 mb-5">
                            <div className="col-12 col-md-6 col-xl-5 offset-xl-1 d-none d-md-block">
                                <img
                                    className="product-figure product-figure-1 img-fluid"
                                    src="/assets/img/home-figure-1.png"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 col-xl-5 pr-xl-3 pt-md-3">
                                <div className="card rounded border-0 shadow-lg  mb-5">
                                    <div className="card-body p-4">
                                        <h5 className="card-title">
                                            <i className="fad fa-virus mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                                            Covid-19
                                        </h5>
                                        <p className="card-text">
                                            People are very apprehensive of
                                            visiting healthcare centers. Ensure
                                            that you don't loose out your
                                            patients to other online platforms
                                            by offering your own tele medicine
                                            platform. Tele medicine shall soon
                                            be the norm.
                                        </p>
                                        <Link to="/features">
                                            Learn more{" "}
                                            <span className="more-arrow">
                                                →
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                {/*//card*/}
                                <div className="card rounded border-0 shadow-lg mb-5">
                                    <div className="card-body p-4">
                                        <h5 className="card-title">
                                            <i className="far fa-video mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                                            Virtual Consultations
                                        </h5>
                                        <p className="card-text">
                                            Did you know 80% of the customers
                                            prefer telemedicine for follow-up
                                            visits? Reduce drop-offs and
                                            increase revenue. Offer secure and
                                            private video and telephonic
                                            consultations.
                                        </p>
                                        <Link to="/features">
                                            Learn more{" "}
                                            <span className="more-arrow">
                                                →
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                {/*//card*/}
                                <div className="card rounded border-0 shadow-lg">
                                    <div className="card-body p-4">
                                        <h5 className="card-title">
                                            <i className="fad fa-prescription mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                                            Digital Prescriptions
                                        </h5>
                                        <p className="card-text">
                                            Our easy to use prescription maker
                                            lets you send out digital
                                            prescriptions to your patients at
                                            ease. You patients can access it
                                            anytime from their patient portal
                                        </p>
                                        <Link to="/features">
                                            Learn more{" "}
                                            <span className="more-arrow">
                                                →
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                {/*//card*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-5 order-md-2 pr-xl-3 d-none d-md-block mt-5 pt-5">
                                <img
                                    className="product-figure product-figure-2 img-fluid"
                                    src="/assets/img/home-figure-2.png"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 col-xl-5 order-md-1 offset-xl-1 pt-xl-5">
                                <div className="card rounded border-0 shadow-lg mb-5">
                                    <div className="card-body p-4">
                                        <h5 className="card-title">
                                            <i className="far fa-user-chart mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                                            Customer CRM
                                        </h5>
                                        <p className="card-text">
                                            Zdoc's powerfull CRM lets you view
                                            all your patients at one place. Send
                                            a SMS blast to alert them of
                                            upcoming holiday, events or
                                            promotions.
                                        </p>
                                        <Link to="/features">
                                            Learn more{" "}
                                            <span className="more-arrow">
                                                →
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card rounded border-0 shadow-lg  mb-5">
                                    <div className="card-body p-4">
                                        <h5 className="card-title">
                                            <i className="fad fa-analytics mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                                            Powerfull Analytics
                                        </h5>
                                        <p className="card-text">
                                            Run your clinic just like the big
                                            hospitals do. Keep track of
                                            everything that matters. View daily,
                                            weekly and monthly growth charts of
                                            patients, revenue, appointments and
                                            more
                                        </p>
                                        <Link to="/features">
                                            Learn more{" "}
                                            <span className="more-arrow">
                                                →
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                {/*//card*/}
                                <div className="card rounded border-0 shadow-lg">
                                    <div className="card-body p-4">
                                        <h5 className="card-title">
                                            <i className="fad fa-shield-alt mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                                            HIPAA Compliant
                                        </h5>
                                        <p className="card-text">
                                            Security and privacy are paramount
                                            to us. Both your and your patients’
                                            data is absolutely private and
                                            secure with our HIPAA compliant
                                            service.
                                        </p>
                                        <Link to="/features">
                                            Learn more{" "}
                                            <span className="more-arrow">
                                                →
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                {/*//card*/}
                            </div>
                        </div>
                        <div className="pt-5 text-center">
                            <Link
                                className="btn btn-primary theme-btn theme-btn-ghost font-weight-bold"
                                to="/features"
                            >
                                View all features
                            </Link>
                        </div>
                    </div>
                    {/*//container*/}
                </section>
                {/*//features-section*/}
                <section className="cta-section py-5 theme-bg-secondary text-center">
                    <div className="container">
                        <h3 className="text-white font-weight-bold mb-3">
                            Ready to digitize your practise?
                        </h3>
                        <div className="text-white mx-auto single-col-max-width section-intro">
                            Sign up with ZDOC today. Stay ahead of the curve by
                            Tech-enabling your medical practice. Offer your
                            patients a never before experience.
                        </div>
                        <Link
                            className="btn theme-btn theme-btn-ghost theme-btn-on-bg mt-4"
                            to="/clinic/signup"
                        >
                            Get Started - It's FREE
                        </Link>
                    </div>
                </section>
            </div>
        );
    }
}
