import React, { Component } from "react";
import base62 from "base62/lib/ascii";
import FormElement from "components/Form/FormElement";
import SimpleReactValidator from "simple-react-validator";
import { getUserInfo } from "./network";
import { detect } from "detect-browser";
import { signup } from "components/Auth/network";
import { Alert } from "reactstrap";
import { loginUser } from "utils/LoginChecker";

export default class MCUser extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({});
        this.checkUser = this.checkUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);

        // /ZaSRY7j   /Z2rO  /Z643d7P
        const link = this.props.location.pathname
        const decoded = base62.decode(link.split("/Z")[1]).toString();

        // split userId
        let mobile = decoded.slice(0, 3);
        let userId = decoded.slice(3);
        if (decoded.length === 10) {
            mobile = decoded;
            userId = null;
        }

        this.state = {
            mobile,
            userId,
            first_name: "",
            last_name: "",
            isUserNameRequired: false,
            error: null,
            invalidLink: false,
        };
    }

    componentDidMount() {
        const { userId, mobile } = this.state;
        this.checkUser(userId, mobile)
            .then((r) => {
                if (r.id) this.props.onUserFound(r);
                else if (mobile.length === 10)
                    this.setState({ isUserNameRequired: true });
            })
            .catch((err) => {
                if (mobile.length === 10)
                    this.setState({ isUserNameRequired: true });
                else this.setState({ invalidLink: true });
            });
    }

    checkUser(userId, mobile) {
        return getUserInfo(userId, mobile).then((r) => {
            if (r.id) {
                loginUser(r.token);
            }
            return r;
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onFormSubmit(e) {
        e.preventDefault();

        if (this.validator.allValid()) {
            // get browser info
            const browser = detect();
            const source = {
                medium: "Web",
                browser: browser.name,
                version: browser.version,
                os: browser.os,
                channel: "MissedCall",
                vendor_id: null,
            };

            const { first_name, last_name, mobile: phone } = this.state;

            signup({ first_name, last_name, phone, source })
                .then((r) => {
                    this.checkUser(r.id, phone).then((r) =>
                        this.props.onUserFound({
                            id: r.id,
                        })
                    );
                })
                .catch((err) =>
                    this.setState({ error: "Sorry, something went wrong. If you are already registered, please give a call to get a new link." })
                );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const {
            isUserNameRequired,
            first_name,
            last_name,
            error,
            invalidLink,
        } = this.state;

        if (invalidLink) return <p className="text-center">Invalid link</p>;

        return (
            <div>
                {isUserNameRequired && (
                    <div>
                        <p className="text-muted">
                            We did not find your account in our system. Please
                            provide your name
                        </p>
                        <form onSubmit={this.onFormSubmit}>
                            <div className="row">
                                <FormElement
                                    className="col-sm-12 "
                                    name="first_name"
                                    placeHolder="First Name"
                                    value={first_name}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required|alpha"
                                />

                                <FormElement
                                    name="last_name"
                                    className="col-sm-12"
                                    placeHolder="Last Name"
                                    value={last_name}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required|alpha"
                                />

                                {error && (
                                    <div className="col-sm-12 mt-2">
                                        <Alert color="danger">{error}</Alert>
                                    </div>
                                )}

                                <div className="col-12 mt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-primary theme-btn mx-auto"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        );
    }
}
