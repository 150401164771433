export const FREQUENCIES = [
    { value: 1, label: "Morning" },
    { value: 2, label: "Noon" },
    { value: 3, label: "Night" },
];

export const DIRECTIONS = [
    { value: 1, label: "Before Food" },
    { value: 2, label: "After Food" },
    { value: 3, label: "Empty Stomach" },
    { value: 4, label: "Bedtime" },
];

export const DURATIONS = [
    { value: 1, label: "1 Day" },
    { value: 2, label: "2 Days" },
    { value: 3, label: "3 Days" },
    { value: 4, label: "4 Days" },
    { value: 5, label: "5 Days" },
    { value: 6, label: "1 Week" },
    { value: 7, label: "10 Days" },
    { value: 8, label: "2 Weeks" },
    { value: 9, label: "15 Days" },
    { value: 10, label: "1 Month" },
    { value: 11, label: "2 Months" },
    { value: 12, label: "3 Months" },
    { value: 13, label: "Till Required" },
];

