import React, { Component } from "react";
import SectionHead from "components/sectionHead";
import VendorUserAppointments from "./appointments";
import VendorUserPrescriptions from "./prescriptions";
import VendorUserNotes from "./notes";
import PageTabsSimple from "components/PageTabsSimple";

export default class VendorUserDetails extends Component {
	constructor(props) {
		super(props);
		this.menu = [];
		this.menu.push({
			path: "/app/customers/list/details/appointments",
			title: "Appointments",
			render: () => <VendorUserAppointments {...this.props} />,
		});
		this.menu.push({
			path: "/app/customers/list/details/prescriptions",
			title: "Prescriptions",
			render: () => <VendorUserPrescriptions {...this.props} />,
		});
		this.menu.push({
			path: "/app/customers/list/details/notes",
			title: "Notes",
			render: () => <VendorUserNotes {...this.props} />,
		});

		this.state = {
			user: this.props.user,
		};
	}

	render() {
		const { user } = this.state;

		return (
			<div className="">
				
				<div>
					<span className="pr-3 cursor-pointer" onClick={this.props.onGoBack}>
						<i className="fa fa-long-arrow-alt-left text-secondary"></i>
					</span>
					<span className="h4 text-muted">{`${user.first_name} ${user.last_name}`}</span>
				</div>

				<PageTabsSimple menu={this.menu}  />
			</div>
		);
	}
}
