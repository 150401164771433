import "page/layout/scss/theme.scss";
import React, { Component } from "react"; 
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { setupAuth, updateSubDomainInStore } from "utils/LoginChecker";
import config from "data/config";
import { getDomainName } from "utils";
import Header from "page/layout/header";
import Footer from "page/layout/footer";
import PubRoute from "page/route";
import RTCRoute from "page/rtc";
import ScrollToTop from "components/ScrollToTop";
import { getVendorSite } from "page/network";
import { initGA } from "utils/GAUtil";

// check for login
setupAuth();

// enable disabled console.log
if (config.ENV === "production")
    console.log = console.warn = console.error = () => {};

initGA();

class App extends Component {
    constructor(props) {
        super(props);

        // detect subdomain and customdomain
        let customDomain = window.location.host;
        let subDomain = null;

        if (customDomain.includes(getDomainName(config.SITE_URL))) {
            const parts = customDomain.split(".");
            const [sub] = parts;
            // subdomain and length is greater than 3
            subDomain = parts.length === 3 && sub.length > 3 ? sub : null;
            customDomain = null;
        }

        this.state = {
            subDomain,
            customDomain,
            loadingSubdomain: Boolean(customDomain || subDomain),
            siteNotFound: false,
        };

        if (this.state.loadingSubdomain) {
            getVendorSite({ subDomain, customDomain })
                .then((r) => {
                    const [sd] = r.data.websites;
                    if (sd) {
                        updateSubDomainInStore(sd);
                        this.setState({ loadingSubdomain: false });
                    } else
                        this.setState({
                            loadingSubdomain: false,
                            siteNotFound: true,
                        });
                })
                .catch((err) => console.log(err));
        }
    }

    render() {
        const { loadingSubdomain, siteNotFound } = this.state;

        return (
            <div>
                {loadingSubdomain ? (
                    <h6 className="p-2 text-muted">
                        <i className="fa fa-spinner fa-spin mr-1"></i>{" "}
                        loading...
                    </h6>
                ) : siteNotFound ? (
                    <h6 className="p-2 text-muted">Site not found</h6>
                ) : (
                    <Router>
                        <ScrollToTop />
                        <Switch>
                            {/* WebRTC Route */}
                            <Route path="/appointments" component={RTCRoute} />

                            {/* Public Routes */}
                            <Route path="/">
                                <Header />
                                <PubRoute />
                                <Footer />
                            </Route>
                        </Switch>
                    </Router>
                )}
            </div>
        );
    }
}

export default App;
