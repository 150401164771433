import React, { Component } from "react";
import SectionHead from "components/sectionHead";
import PageTabs from "components/PageTabs";
import VendorCustomersList from "./list";
import VendorCustomersImport from "./import";

class VendorCustomers extends Component {
	render() {		
		return (
			<div className="container py-5">
				<VendorCustomersList />
			</div>
		);
	}
}

export default VendorCustomers;
