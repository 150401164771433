// APP Config
export const DEFAULT_COUNTRY_CODE = "+91";
export const APP_IS_PETS_ENABLED = false;
export const APP_ENABLE_OLD_PHR = false;
export const APP_NAME = "Zdoc";
export const APP_SUPPORT_EMAIL = "hello@zdoc.in";
export const APP_SUPPORT_PHONE = "9686972769";
export const APP_TIMEZONE = "Asia/Calcutta";
export const APP_CURRENCY = "INR";
export const APP_CURRENCY_SYMBOL = "₹";
export const APP_EXOTEL_ENABLED = true;
export const APP_EXOTEL_NUMBER = "+91-8047191805";
export const APP_BOOKING_NUMBER = "+91-8047178885";
export const APP_CLOUD_TELE_CONSULT = true;
export const APP_CONVINIENCE_FEE = 0;

// Constultation Types
export const APP_CONSULTATION_TYPE_NOW_PHONE = 2000;
export const APP_CONSULTATION_TYPE_CLINIC = 6000;
export const APP_CONSULTATION_TYPE_VIDEO = 8000;
export const APP_CONSULTATION_TYPE_TELEPHONE = 9000;
export const APP_CONSULTATION_TYPE_CHAT = 11000;

// Consultation Names
export const APP_CONSULTATION_NAMES = [
    {
        value: APP_CONSULTATION_TYPE_NOW_PHONE,
        label: "Quick Consultation",
        icon: "fad fa-rocket-launch",
        color: "#ff7675",
        descr: "Telephonic consultation but without an appointment. Suitable mostly for short or follow up consultations. The doctor is expected to callback the patient at the earliest possible.",
    },
    {
        value: APP_CONSULTATION_TYPE_CLINIC,
        label: "Clinic Consultation",
        icon: "fad fa-clinic-medical",
        color: "#81ecec",
        descr: "For scheduled In-Person Clinic Appointments."
    },
    {
        value: APP_CONSULTATION_TYPE_VIDEO,
        label: "Video Consultation",
        icon: "fad fa-video",
        color: "#a29bfe",
        descr: "For scheduled Video Consultations. The user will receive a consultation URL via SMS clicking which will start the video chat."
    },
    {
        value: APP_CONSULTATION_TYPE_TELEPHONE,
        label: "Telephonic Consultation",
        icon: "fad fa-phone-volume",
        color: "#74b9ff",
        descr: "For scheduled Telephonic Consultation. You will be provided with a temporary disposable number for the telephonic call."
    },
];

// A4P APP Roles
export const A4P_ROLE_USER = "user";
export const A4P_ROLE_VENDOR = "vendor";
export const A4P_ROLE_ADMIN = "admin";

// vendor types
export const A4P_VENDOR_TYPE_VET_DOCTOR = 1000;

// vendor services
export const A4P_SERVICE_CLINIC_APPOINTMENTS = 2000;
export const A4P_SERVICE_HOME_VISITS = 3000;
export const A4P_SERVICE_ECONSULTATION = 4000;
export const A4P_SERVICE_TALKTOAVET = 5000;
export const A4P_SERVICE_SMO = 6000;

// Payment Types
export const A4P_PAYMENT_TYPES = {
    SERVICES: 100,
    PROMO: 200,
    APPOINTMENTS: 300, // for v2 vendor appointments
};

// vendor service to name
export const A4P_SERVICE_NAMES = [
    { value: A4P_SERVICE_CLINIC_APPOINTMENTS, label: "Vet Appointments" },
    { value: A4P_SERVICE_HOME_VISITS, label: "House Visits" },
    { value: A4P_SERVICE_ECONSULTATION, label: "E-Consultations" },
    { value: A4P_SERVICE_TALKTOAVET, label: "Talk To A Vet" },
    { value: A4P_SERVICE_SMO, label: "Second Medical Opinion" },
];

// vendor consultation Types
export const A4P_CONSULTATION_NAMES = [
    {
        value: A4P_SERVICE_CLINIC_APPOINTMENTS,
        label: "Clinic Consultation",
        icon: "fa-map-marker",
    },
    {
        value: A4P_SERVICE_ECONSULTATION,
        label: "Video Consultation",
        icon: "fa-webcam",
    },
    {
        value: A4P_SERVICE_TALKTOAVET,
        label: "Telephonic Consultation",
        icon: "fa-phone-volume",
    },
    { value: A4P_SERVICE_SMO, label: "Chat Consultation", icon: "fa-comments" },
];

// Appointment Types
export const A4P_APPOINTMENT_STATUSES = {
    IN_PROGRESS: 0,
    COMPLETE: 1,
    RESCHEDULED: 2,
    CANCELED: 3,
};

export const A4P_APPOINTMENT_STATUS_SELECT = [
    {
        value: A4P_APPOINTMENT_STATUSES.IN_PROGRESS,
        label: "Upcoming Appointments",
    },
    {
        value: A4P_APPOINTMENT_STATUSES.COMPLETE,
        label: "Completed Appointments",
    },
    {
        value: A4P_APPOINTMENT_STATUSES.RESCHEDULED,
        label: "Rescheduled Appointments",
    },
    {
        value: A4P_APPOINTMENT_STATUSES.CANCELED,
        label: "Cancelled Appointments",
    },
];

// A4p Vendor ID for talk to vet , smo, house visit services
export const A4P_VENDOR_ID = 1;

// vet doc appoint slot duration in minutes
export const A4P_VET_DOC_APPOINTMENT_SLOT_DURATION = 20;

// A4P services fee
export const A4P_SERVICE_FEES = {
    HOUSE_VISIT: 360,
    E_CONSULTATION: 0,
    TALK_TO_VET: 0,
    SMO: 95,
};

// A4P vendor type
export const A4P_VENDOR_TYPE = {
    ROOT_ADMIN: 1,
    ADMIN: 2,
    USER: 3,
};

// Record status
export const A4P_STATUS = {
    ACTIVE: 1,
};

// PHR URL
export const A4P_PHR_PREFIX =
    "https://login.activ4pets.com/account/petdetails/";

// vendor specializations
export const vet_doc_specialities = [
    { value: 1000, label: "Anaesthesiology" },
    { value: 1001, label: "Animal behavior" },
    { value: 1002, label: "Animal welfare" },
    { value: 1003, label: "Birds " },
    { value: 1004, label: "Bovine" },
    { value: 1005, label: "Canine" },
    { value: 1006, label: "Cardiology" },
    { value: 1007, label: "Clinical pathology" },
    { value: 1008, label: "Clinical pharmacology" },
    { value: 1009, label: "Dentistry" },
    { value: 1010, label: "Dermatology " },
    { value: 1011, label: "Diagnostic imaging " },
    { value: 1012, label: "Equine" },
    { value: 1013, label: "Emergency and critical care " },
    { value: 1014, label: "Honey bee" },
    { value: 1015, label: "Fish" },
    { value: 1016, label: "Food Agro diagnostics" },
    { value: 1017, label: "Forensic veterinary" },
    { value: 1018, label: "Feline" },
    { value: 1019, label: "Veterinary immunology" },
    { value: 1020, label: "Internal medicine" },
    { value: 1021, label: "Laboratory animal medicine" },
    { value: 1022, label: "Microbiology " },
    { value: 1023, label: "Neurology and neurosurgery " },
    { value: 1024, label: "Nutrition" },
    { value: 1025, label: "Oncology" },
    { value: 1026, label: "Ophthalmology " },
    { value: 1027, label: "Orthopaedics " },
    { value: 1028, label: "Parasitology" },
    { value: 1029, label: "Pathology " },
    { value: 1030, label: "Porcine" },
    { value: 1031, label: "Poultry" },
    { value: 1032, label: "Preventive medicine" },
    { value: 1033, label: "Radiology" },
    { value: 1034, label: "Reptile and amphibian" },
    { value: 1035, label: "Shelter medicine" },
    { value: 1036, label: "Sports medicine" },
    { value: 1037, label: "Surgery" },
    { value: 1038, label: "Theriogenology" },
    { value: 1039, label: "Toxicology" },
    { value: 1040, label: "Zoological medicine " },
];

// vendor services
export const vet_doc_services = [
    { value: 1000, label: "General Care" },
    { value: 1001, label: "Preventive Care" },
    { value: 1002, label: "Vaccination and Deworming" },
    { value: 1003, label: "Health Check-ups and Treatment" },
    { value: 1004, label: "Grooming" },
    { value: 1005, label: "Diagnostic Tests" },
    { value: 1006, label: "Surgery" },
    { value: 1007, label: "Pet Training" },
];

// differnt type of pets
export const vet_pets = [
    { value: 1000, label: "Cats" },
    { value: 1001, label: "Dogs" },
    { value: 1002, label: "Fish" },
    { value: 1003, label: "Small Mammals" },
    { value: 1004, label: "Birds" },
    { value: 1005, label: "Reptiles & Amphibians" },
    { value: 1006, label: "Equine" },
    { value: 1007, label: "Exotic Mammals" },
];

// differnt type of pets (pet owners)
export const pet_types = [
    { value: 1, label: "Dog" },
    { value: 2, label: "Cat" },
    { value: 3, label: "Duck" },
    { value: 4, label: "Horse" },
    { value: 5, label: "Parrot" },
    { value: 6, label: "Rabbit" },
    { value: 7, label: "Guinea Pig" },
    { value: 8, label: "Hamster" },
    { value: 9, label: "Frog" },
    { value: 10, label: "Turtle" },
    { value: 11, label: "Other" },
    { value: 12, label: "Fish" },
    { value: 13, label: "Bird" },
    { value: 14, label: "Fowl" },
    { value: 15, label: "Kitten" },
    { value: 16, label: "Puppy" },
    { value: 18, label: "Cattle" },
    { value: 19, label: "Sheep" },
    { value: 20, label: "Pig" },
    { value: 21, label: "Goat" },
    { value: 45, label: "Zoo" },
    { value: 50, label: "Farm" },
    { value: 52, label: "Mammal" },
    { value: 53, label: "Marsupial" },
    { value: 54, label: "Reptile" },
    { value: 55, label: "InsectArachnid" },
    { value: 56, label: "Birdwildlife" },
    { value: 57, label: "Amphibianwildlife" },
    { value: 60, label: "Crabfish" },
    { value: 61, label: "Amphibian" },
    { value: 64, label: "Rodent" },
    { value: 66, label: "NonNativeAmphibians" },
    { value: 67, label: "Gerbil" },
    { value: 69, label: "NonNativeReptiles" },
    { value: 70, label: "PlacentalMammals" },
    { value: 71, label: "Canine" },
    { value: 72, label: "Feline" },
];

// profile pic types
export const profile_pic_types = {
    userAvatar: "user_avatar",
    brandLogo: "brand_logo",
    vendorServicesAvatar: "vendor_services_avatar",
    vendorServicesListings: "vendor_services_listings",
    phr: "phr",
    serviceSMO: "smo_service",
};
