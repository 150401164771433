// ennivronment specific config variables
const env_config = {
  development: {
    ENABLE_CONSOLE_LOG: true,
    ENABLE_REDUX_CHROME_EXT: false,
    TOKBOX_EMBED_ID: process.env.REACT_APP_LOCALHOST_TOKBOX_ID || '32da0ab3-3c9b-454e-8a21-ca623361e1d5',
    RAZORPAY_ID: "rzp_test_DaSQkUyTB4AVtx",
    SITE_URL: process.env.REACT_APP_DEV_SITE_URL || "https://randm.in",
    GQL_ENDPOINT: process.env.REACT_APP_DEV_GQL_EP
  },
  production: {
    ENABLE_CONSOLE_LOG: false,
    ENABLE_REDUX_CHROME_EXT: false,
    TOKBOX_EMBED_ID: '4e2edc9b-9eb9-4971-b8cb-f1c36960d0d8',
    RAZORPAY_ID: "rzp_live_f7eCEHdoXcIJXe",
    SITE_URL: "https://zdoc.in",
    GQL_ENDPOINT: process.env.REACT_APP_LIVE_GQL_EP
  }
};

// common variables across environments
const config = {
  ...env_config[process.env.REACT_APP_ENV || 'development'],  // export NODE_ENV=development
  ENV: process.env.REACT_APP_ENV || 'development',
  API_ENDPOINT: '', // accessed via proxy 
  RECAPTCHA_KEY : process.env.REACT_APP_RECAPTCHA_KEY,
  GOOGLE_ANALYTICS_ID: "UA-166853849-1",
  GOOGLE_PLACES_KEY : process.env.REACT_APP_GOOGLE_PLACES_KEY,
  GOOGLE_CLIENT_ID:"110229857857-v738v15bl1rfir3vb3rc0886l96nlr7t.apps.googleusercontent.com",
  FACEBOOK_CLEINT_ID: "2640981172844664",
};

export default config;