import { gqlSend, apiSend } from "utils/AxiosUtil";
import { A4P_ROLE_USER } from "data/config/variables";

export const getUserInfo = (id, mobile) => {
    id = id ? id : null;
    return apiSend({ id, mobile, role: A4P_ROLE_USER }, "/api/account/find_user_id");
};

export const searchVendors = (params) => {
    const { offset = 0, limit = 5, searchStr = "" } = params;

    const q = {
        query: `{
            vendor_listings(where: {_or:[{title: {_ilike: "%${searchStr}%"}}, {line1: {_ilike: "%${searchStr}%"}}]}, limit: ${limit}, offset: ${offset}) {
                id, vendor_id, line1, title, avatar, vendor { websites { name, logo } }
            }
        }`,
    };

    return gqlSend(q);
};

export const fetchSavedVendors = (list) => {
    const q = {
        query: `{
            vendors(where: {id: {_in: [${list.join(",")}]}}) {
                websites {
                  name, logo, vendor_id
                }
            }
        }`,
    };

    return gqlSend(q);
};

export const addToSavedVendorList = (userId, vendorId) => {
    const q = {
        query: `mutation {
            update_users(where: {id: {_eq: ${userId}}}, _append: {saved_vendors: ${vendorId}}) {
              affected_rows
            }
          }`,
    };

    return gqlSend(q);
};
