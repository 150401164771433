import React, { Component } from "react";
import SectionHead from "components/sectionHead";
import UsersIndex from "./users";
import SiteIndex from "./site";
import { getSubRole } from "utils";
import { A4P_VENDOR_TYPE } from "data/config/variables";
import SettingsDomainsIndex from "./domains";
import SettingsIntegrationsIndex from "./integrations";
import PageTabs from "components/PageTabs";
import VendorListings from "../listing";
import SettingsAccountIndex from "./account";

class SettingsIndex extends Component {
	constructor(props) {
		super(props);
		this.settingsMenu = [];
	}

	render() {
		let menu = [];
		
		menu.push({
			path: "/app/settings/practice",
			title: "My Practices",
			render: () => <VendorListings {...this.props}  />,
		});
		if (getSubRole() === A4P_VENDOR_TYPE.ROOT_ADMIN) {
			menu.push({
				path: "/app/settings/site",
				title: "Website",
				render: () => <SiteIndex />,
			});
			menu.push({
				path: "/app/settings/domains",
				title: "Domains",
				render: () => <SettingsDomainsIndex />,
			});
			menu.push({
				path: "/app/settings/users",
				title: "Team",
				render: () => <UsersIndex />,
			});
			menu.push({
				path: "/app/settings/account",
				title: "Account",
				render: () => <SettingsAccountIndex />,
			});
		}

		menu.push({
			path: "/app/settings/integrations",
			title: "Integrations",
			render: () => <SettingsIntegrationsIndex />,
		});

		return (
			<div className="container py-5">
				<SectionHead title="Settings" />
				<PageTabs menu={menu} />
			</div>
		);
	}
}

export default SettingsIndex;
