import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

class PageTabs extends Component {
	constructor(props) {
		super(props);
		this.settingsMenu = [];
	}

	render() {
		const { menu } = this.props;
		const pt = this.props.location.pathname;
		let sel = menu.find((m) => m.path === pt);
		if (!sel) sel = menu[0];

		return (
			<div>
				<div className="card">
					<div className="card-header">
						<ul className="nav nav-tabs card-header-tabs">
							{menu.map((item, i) => (
								<li key={i} className="nav-item">
									<Link
										to={item.path}
										className={`nav-link px-4 ${
											sel.path === item.path
												? "active"
												: ""
										}`}
									>
										{sel.path === item.path ? (
											<strong>{item.title}</strong>
										) : (
											<span>{item.title}</span>
										)}
									</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="card-body">
						<div className="py-2">{sel.render()}</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(PageTabs);
