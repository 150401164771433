import { getVendorId, getUserId } from "utils";
import { gqlSend } from "utils/AxiosUtil";

export const getUserOnboardingStatus = () => {
    const vendor_id = getVendorId();
    const q = {
        query: `{
            vendors(where: {id: {_eq: ${vendor_id}}}) {
                status
                }
          }`,
    };
    return gqlSend(q);
};

export const setWebsiteDetails = (name, header, sub_domain) => {
    const vendor_id = getVendorId();
    const q = {
        query: ` 
            mutation ($vendor_id: Int, $sub_domain: String, $name: String, $header: String ) {
                insert_websites(objects: {vendor_id: $vendor_id, name: $name, header_section: $header, sub_domain: $sub_domain}) {
                    returning {id}
                }
        }`,
        variables: {
            vendor_id,
            header,
            sub_domain,
            name,
        },
    };

    return gqlSend(q);
};

export const createPractice = (params) => {
    const {
        name: title,
        drName: line1,
        timezone,
        consultations,
        vendor_login_id = getUserId(),
        managers = [getUserId()],
        vendor_id = getVendorId(),
    } = params;

    const _s =
        "title: $title, line1: $line1, timezone: $timezone, managers: $managers, " +
        "consultations: $consultations, vendor_id: $vendor_id, vendor_login_id: $vendor_login_id ";

    const q = {
        query: ` 
            mutation ($title: String, $line1: String, $timezone: String, $managers: jsonb, $consultations: jsonb, $vendor_id: Int, $vendor_login_id: Int ) {
                insert_vendor_listings (objects: {${_s}}) {
                    returning {id}
            }
        }`,
        variables: {
            title,
            line1,
            timezone,
            managers,
            consultations,
            vendor_id,
            vendor_login_id,
        },
    };

    return gqlSend(q);
};
