import React, { Component } from "react";
import SectionHead from "components/sectionHead";
import { getPrescriptionTemplates, deleteTemplate } from "./network";
import NoRecords from "components/noRecords";
import ManagePT from "./details";
import PTListingItem from "./listItem";
import isEmpty from "utils/isEmpty";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

export default class PrescriptionSelectTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            allItems: [],
            filteredItems: [],
            error: false,
            showDetails: false,
        };
        this.onSearch = this.onSearch.bind(this);
    }

    componentDidMount() {
        getPrescriptionTemplates()
            .then((r) => {
                const li = r.data.prescription_templates;
                if (!isEmpty(li))
                    this.setState({
                        allItems: [...li],
                        filteredItems: [...li],
                    });
            })
            .catch((err) => {
                this.setState({ error: true });
                console.log(err);
            });
    }

    onSearch(e) {
        const { allItems } = this.state;
        const srchVal = e.target.value;
        if (srchVal) {
            const filtered = allItems.filter(
                (i) =>
                    i.title.includes(srchVal) ||
                    (i.descr && i.descr.includes(srchVal))
            );
            this.setState({ filteredItems: [...filtered] });
        } else this.setState({ filteredItems: [...allItems] });
    }

    render() {
        const { filteredItems, error } = this.state;

        return (
            <div className="">
                <div className="">
                    <div className="d-flex justify-content-between border-bottom mb-3 pb-1">
                        <div className="text-muted h5">Templates</div>
                        <Link to="/app/prescription-templates">
                            <i
                                className="fa fa-cog "
                                title="Manage Templates"
                            ></i>
                        </Link>
                    </div>

                    <div>
                        {error ? (
                            <div className="container text-center py-5">
                                Sorry something went wrong. Please try again
                                later
                            </div>
                        ) : (
                            <div>
                                <div className="mb-3">
                                    <input
                                        type="search"
                                        className="form-control border-light"
                                        placeholder="Search here..."
                                        onChange={this.onSearch}
                                    />
                                </div>
                                {isEmpty(filteredItems) ? (
                                    <NoRecords />
                                ) : (
                                    <div className="row">
                                        {filteredItems &&
                                            filteredItems.map((item, k) => (
                                                <div key={k} className="col-12">
                                                    <PTListingItem
                                                        item={item}
                                                        onSelect={
                                                            this.props.onSelect
                                                        }
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
